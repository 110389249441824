import React from "react";
import FilterCheckbox from "./FilterCheckbox";
import ArrowIcon from "../../icons/ArrowIcon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ruleCategoryService from "../../../Services/ruleCategory.service";
import { useNavigate } from "react-router-dom";
import userGroupService from "../../../Services/userGroup.service";
import popupMessageService from "../../../Services/popupMessage.service";
import teamsMessageService from "../../../Services/teamsMessage.service";

function FilterModal(props) {
  const { t } = useTranslation();
  const [showRuleCategorySection, setshowRuleCategorySection] = useState(false);
  const [showUserGroupSection, setshowUserGroupSection] = useState(false);
  const [showPopupSection, setshowPopupSection] = useState(false);
  const [showTeamsSection, setshowTeamsSection] = useState(false);
  /*   const [showEducationSection, setshowEducationSection] = useState(false);
  const [showAdvisorSection, setshowAdvisorSection] = useState(false);
  const [showPropertiesSection, setshowPropertiesSection] = useState(false);*/
  const [showActivitySection, setshowActivitySection] = useState(false);
  const [arrayOne, setArrayOne] = useState([]);
  const [checkboxContentCategory, setCheckboxContentCategory] = useState([]);
  const [checkboxContentPopup, setCheckboxContentPopup] = useState([]);
  const [checkboxContentTeams, setCheckboxContentTeams] = useState([]);
  const [checkboxContentUserGroup, setCheckboxContentUserGroup] = useState([]);
  const [checkboxContentConsultant, setCheckboxContentConsultant] = useState(
    []
  );
  const [checkboxContentActive, setCheckboxContentActive] = useState([
    { id: 1, active: false },
    { id: 2, active: true },
  ]);

  const history = useNavigate();

  const handleCheck = (state, setState, event, name) => {
    const id = event.target.id;
    const updatedCategories = state.map((category) => {
      if (category.id === parseInt(id)) {
        return { ...category, checked: event.target.checked };
      }
      return category;
    });
    const query = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(query.entries());
    name === "active"
      ? (params[name] = updatedCategories
          .filter((filter) => filter.checked === true)
          .map((c) => c.active))
      : (params[name] = updatedCategories
          .filter((filter) => filter.checked === true)
          .map((c) => c.id));
    const newUrl = `${window.location.pathname}?${new URLSearchParams(params)}`;
    history(newUrl);
    setState(updatedCategories);
  };

  const resetClick = () => {
    const newQuery = new URLSearchParams();
    history(`${window.location.pathname}?${newQuery.toString()}`);
    for (const item of checkboxContentCategory) {
      item.checked = false;
    }
    setCheckboxContentCategory([...checkboxContentCategory]);

    for (const item of checkboxContentPopup) {
      item.checked = false;
    }
    setCheckboxContentPopup([...checkboxContentPopup]);

    for (const item of checkboxContentTeams) {
      item.checked = false;
    }
    setCheckboxContentTeams([...checkboxContentTeams]);

    for (const item of checkboxContentUserGroup) {
      item.checked = false;
    }
    setCheckboxContentUserGroup([...checkboxContentUserGroup]);

    for (const item of checkboxContentConsultant) {
      item.checked = false;
    }
    setCheckboxContentConsultant([...checkboxContentConsultant]);

    for (const item of checkboxContentActive) {
      item.checked = false;
    }
    setCheckboxContentActive([...checkboxContentActive]);
  };

  useEffect(() => {
    if (props.array.length > 0 && arrayOne.length === 0) {
      setArrayOne(props.array);
    }
  }, [props.array, arrayOne]);

  useEffect(() => {
    if (arrayOne.length > 0) {
      const queryParams = new URLSearchParams(window.location.search);
      const categoryValues = queryParams.get("category")?.split(",") || [];
      const categories = [...categoryValues];
      const groupsValues = queryParams.get("group")?.split(",") || [];
      const groups = [...groupsValues];
      const popupValues = queryParams.get("popup")?.split(",") || [];
      const popups = [...popupValues];
      const teamsValues = queryParams.get("teams")?.split(",") || [];
      const teams = [...teamsValues];
      const activeValues = queryParams.get("active")?.split(",") || [];
      const actives = [...activeValues];

      ruleCategoryService.RuleCategoryGetAll().then((response) => {
        if (response.status) {
          const updateArray = response.data.map((obj) => ({
            ...obj,
            checked: categories.includes(obj.id.toString()),
          }));
          setCheckboxContentCategory(updateArray);
        } else {
          console.log(response);
        }
      });

      userGroupService.UserGroupGetAll().then((response) => {
        if (response.status) {
          const updateArray = response.data.map((obj) => ({
            ...obj,
            checked: groups.includes(obj.id.toString()),
          }));
          setCheckboxContentUserGroup(updateArray);
        } else {
          console.log(response);
        }
      });

      popupMessageService.PopupMessageGetAll().then((response) => {
        if (response.status) {
          const updateArray = response.data.map((obj) => ({
            ...obj,
            checked: popups.includes(obj.id.toString()),
          }));
          setCheckboxContentPopup(updateArray);
        } else {
          console.log(response);
        }
      });

      teamsMessageService.TeamsMessageGetAll().then((response) => {
        if (response.status) {
          const updateArray = response.data.map((obj) => ({
            ...obj,
            checked: teams.includes(obj.id.toString()),
          }));
          setCheckboxContentTeams(updateArray);
        } else {
          console.log(response);
        }
      });

      const updat = checkboxContentActive.map((obj) => ({
        ...obj,
        checked: actives.includes(obj.active.toString()),
      }));
      setCheckboxContentActive(updat);

      if (
        (categories.length === 0 || categories[0] === "") &&
        (groups.length === 0 || groups[0] === "") &&
        (popups.length === 0 || popups[0] === "") &&
        (teams.length === 0 || teams[0] === "") &&
        (actives.length === 0 || actives[0] === "")
      ) {
        props.setArray(arrayOne);
      } else {
        const newArr = categories
          .map((id) => {
            return arrayOne.filter(
              (item) => parseInt(item.categoryId) === parseInt(id)
            );
          })
          .flat();
        props.setArray(newArr);
        const newArrGr = groups
          .map((id) => {
            return arrayOne.filter(
              props.group
                ? (item) => parseInt(item.id) === parseInt(id)
                : (item) =>
                    item.userGroup.some(
                      (obj) => parseInt(obj.id) === parseInt(id)
                    )
            );
          })
          .flat();

        props.setArray([...newArr, ...newArrGr]);
        const newArPp = popups
          .map((id) => {
            return arrayOne.filter(
              props.popup
                ? (item) => parseInt(item.id) === parseInt(id)
                : (item) =>
                    item.popupMessage.some(
                      (obj) => parseInt(obj.id) === parseInt(id)
                    )
            );
          })
          .flat();
        props.setArray([...newArr, ...newArrGr, ...newArPp]);
        const newArTm = teams
          .map((id) => {
            return arrayOne.filter(
              props.teams
                ? (item) => parseInt(item.id) === parseInt(id)
                : (item) =>
                    item.teamsMessage.some(
                      (obj) => parseInt(obj.id) === parseInt(id)
                    )
            );
          })
          .flat();

        props.setArray([...newArr, ...newArrGr, ...newArPp, ...newArTm]);

        const newArrAc = actives
          .map((active) => {
            return arrayOne.filter(
              (item) =>
                item.isActive.toString().toLowerCase() ===
                active.toString().toLowerCase()
            );
          })
          .flat();

        const mergedA = [
          ...newArr,
          ...newArrGr,
          ...newArPp,
          ...newArTm,
          ...newArrAc,
        ];

        const uniqueArray = mergedA.reduce((accumulator, currentItem) => {
          const existingItem = accumulator.find(
            (item) => item.id === currentItem.id
          );
          if (!existingItem) {
            return [...accumulator, currentItem];
          }
          return accumulator;
        }, []);

        props.setArray(uniqueArray);
      }
    }
  }, [arrayOne]);

  useEffect(() => {
    if (arrayOne.length > 0) {
      const queryParams = new URLSearchParams(window.location.search);
      const categoryValues = queryParams.get("category")?.split(",") || [];
      const categories = [...categoryValues];
      const groupsValues = queryParams.get("group")?.split(",") || [];
      const groups = [...groupsValues];
      const popupValues = queryParams.get("popup")?.split(",") || [];
      const popups = [...popupValues];
      const teamsValues = queryParams.get("teams")?.split(",") || [];
      const teams = [...teamsValues];
      const activeValues = queryParams.get("active")?.split(",") || [];
      const actives = [...activeValues];
      if (
        (categories.length === 0 || categories[0] === "") &&
        (groups.length === 0 || groups[0] === "") &&
        (popups.length === 0 || popups[0] === "") &&
        (teams.length === 0 || teams[0] === "") &&
        (actives.length === 0 || actives[0] === "")
      ) {
        props.setArray(arrayOne);
      } else {
        const newArr = categories
          .map((id) => {
            return arrayOne.filter(
              (item) => parseInt(item.categoryId) === parseInt(id)
            );
          })
          .flat();

        props.setArray(newArr);
        const newArrGr = groups
          .map((id) => {
            return arrayOne.filter(
              props.group
                ? (item) => parseInt(item.id) === parseInt(id)
                : (item) =>
                    item.userGroup.some(
                      (obj) => parseInt(obj.id) === parseInt(id)
                    )
            );
          })
          .flat();

        props.setArray([...newArr, ...newArrGr]);
        const newArPp = popups
          .map((id) => {
            return arrayOne.filter(
              props.popup
                ? (item) => parseInt(item.id) === parseInt(id)
                : (item) =>
                    item.popupMessage.some(
                      (obj) => parseInt(obj.id) === parseInt(id)
                    )
            );
          })
          .flat();

        props.setArray([...newArr, ...newArrGr, ...newArPp]);
        const newArTm = teams
          .map((id) => {
            return arrayOne.filter(
              props.teams
                ? (item) => parseInt(item.id) === parseInt(id)
                : (item) =>
                    item.teamsMessage.some(
                      (obj) => parseInt(obj.id) === parseInt(id)
                    )
            );
          })
          .flat();

        props.setArray([...newArr, ...newArrGr, ...newArPp, ...newArTm]);

        const newArrAc = actives
          .map((active) => {
            return arrayOne.filter(
              (item) =>
                item.isActive.toString().toLowerCase() ===
                active.toString().toLowerCase()
            );
          })
          .flat();

        const mergedA = [
          ...newArr,
          ...newArrGr,
          ...newArPp,
          ...newArTm,
          ...newArrAc,
        ];

        const uniqueArray = mergedA.reduce((accumulator, currentItem) => {
          const existingItem = accumulator.find(
            (item) => item.id === currentItem.id
          );
          if (!existingItem) {
            return [...accumulator, currentItem];
          }
          return accumulator;
        }, []);

        props.setArray(uniqueArray);
      }
    }
  }, [
    checkboxContentCategory,
    checkboxContentPopup,
    checkboxContentTeams,
    checkboxContentUserGroup,
    checkboxContentConsultant,
    checkboxContentActive,
    arrayOne,
  ]);

  if (props.visible === false) return null;

  return (
    <div className="absolute left-[85px] top-14 z-10 w-[327px] h-[717px] overflow-scroll no-scrollbar bg-secondaryGray flex flex-col rounded-[10px] ">
      <div className="flex flex-row justify-between items-center px-[20px] py-[30px]">
        <p className=" text-[22px] leading-[22px] text-textGray font-bold">
          {t("ruleList.filter")}
        </p>
        <div onClick={resetClick} style={{ cursor: "pointer" }}>
          <p className="underline text-green text-[16px] leading-4 font-semibold">
            {t("ruleList.clear")}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-[10px] text-base font-bold leading-4 text-textGray pb-[12px] justify-start">
        {props.group ? null : (
          <div className="flex flex-col gap-[14px]">
            <div
              className="flex flex-row justify-between px-[35px] pt-[12px] pb-[9px]"
              onClick={() =>
                setshowRuleCategorySection(!showRuleCategorySection)
              }
              style={{ cursor: "pointer" }}
            >
              <p>{t("sidebar.ruleCategory")}</p>
              <ArrowIcon active={showRuleCategorySection} />
            </div>
            <div className="flex flex-col px-[55px] gap-[8px] ">
              {checkboxContentCategory.map((item, key) => (
                <FilterCheckbox
                  onChange={(event) => {
                    handleCheck(
                      checkboxContentCategory,
                      setCheckboxContentCategory,
                      event,
                      "category"
                    );
                  }}
                  key={key}
                  id={item.id}
                  name={item.name}
                  checked={item.checked}
                  visible={showRuleCategorySection}
                ></FilterCheckbox>
              ))}
            </div>
          </div>
        )}
        {props.rule || props.group ? (
          <div className="flex flex-col gap-[14px]">
            <div
              className="flex flex-row justify-between px-[35px] pt-[12px] pb-[9px]"
              onClick={() => setshowUserGroupSection(!showUserGroupSection)}
              style={{ cursor: "pointer" }}
            >
              <p>{t("ruleList.userGroup")}</p>
              <ArrowIcon active={showUserGroupSection} />
            </div>
            <div className="flex flex-col px-[55px] gap-[8px] ">
              {checkboxContentUserGroup.map((item, key) => (
                <FilterCheckbox
                  onChange={(event) =>
                    handleCheck(
                      checkboxContentUserGroup,
                      setCheckboxContentUserGroup,
                      event,
                      "group"
                    )
                  }
                  id={item.id}
                  key={key}
                  name={item.name}
                  checked={item.checked}
                  visible={showUserGroupSection}
                ></FilterCheckbox>
              ))}
            </div>
          </div>
        ) : null}
        {props.popup || props.rule ? (
          <div className="flex flex-col gap-[14px]">
            <div
              className="flex flex-row justify-between px-[35px] pt-[12px] pb-[9px]"
              onClick={() => setshowPopupSection(!showPopupSection)}
              style={{ cursor: "pointer" }}
            >
              <p>{t("ruleList.popupFor")}</p>
              <ArrowIcon active={showPopupSection} />
            </div>
            <div className="flex flex-col px-[55px] gap-[8px] ">
              {checkboxContentPopup.map((item, key) => (
                <FilterCheckbox
                  onChange={(event) =>
                    handleCheck(
                      checkboxContentPopup,
                      setCheckboxContentPopup,
                      event,
                      "popup"
                    )
                  }
                  id={item.id}
                  key={key}
                  name={item.name}
                  checked={item.checked}
                  visible={showPopupSection}
                ></FilterCheckbox>
              ))}
            </div>
          </div>
        ) : null}
        {props.rule || props.teams ? (
          <div className="flex flex-col gap-[14px]">
            <div
              className="flex flex-row justify-between px-[35px] pt-[12px] pb-[9px]"
              onClick={() => setshowTeamsSection(!showTeamsSection)}
              style={{ cursor: "pointer" }}
            >
              <p>{t("ruleList.teamsFor")}</p>
              <ArrowIcon active={showTeamsSection} />
            </div>
            <div className="flex flex-col px-[55px] gap-[8px] ">
              {checkboxContentTeams.map((item, key) => (
                <FilterCheckbox
                  onChange={(event) =>
                    handleCheck(
                      checkboxContentTeams,
                      setCheckboxContentTeams,
                      event,
                      "teams"
                    )
                  }
                  key={key}
                  id={item.id}
                  name={item.name}
                  checked={item.checked}
                  visible={showTeamsSection}
                ></FilterCheckbox>
              ))}
            </div>
          </div>
        ) : null}
        {/*         <div className="flex flex-col gap-[14px]">
          <div
            className="flex flex-row justify-between px-[35px] pt-[12px] pb-[9px]"
            onClick={() => setshowEducationSection(!showEducationSection)}
          >
            <p>{t("ruleList.educationAddress")}</p>
            <ArrowIcon active={showEducationSection} />
          </div>
          <div className="flex flex-col px-[55px] gap-[8px] ">
                    {checkboxContentConsultant.map((item) => (
              <FilterCheckbox
                onChange={(event) =>
                  handleCheck(checkboxContentConsultant, setCheckboxContentConsultant, event)
                }
                id={item.id}
                name={item.name}
                checked={item.checked}
                visible={showEducationSection}
              ></FilterCheckbox>
            ))} 
          </div>
        </div> */}
        {/*         <div className="flex flex-col gap-[14px]">
          <div
            className="flex flex-row justify-between px-[35px] pt-[12px] pb-[9px]"
            onClick={() => setshowAdvisorSection(!showAdvisorSection)}
          >
            <p>{t("ruleList.advisor")}</p>
            <ArrowIcon active={showAdvisorSection} />
          </div>
          <div className="flex flex-col px-[55px] gap-[8px] ">
            {checkboxContentConsultant.map((item) => (
              <FilterCheckbox
                onChange={(event) => {
                  handleCheck(
                    checkboxContentConsultant,
                    setCheckboxContentConsultant,
                    event
                  );
                }}
                id={item.id}
                name={item.name}
                checked={item.checked}
                visible={showAdvisorSection}
              ></FilterCheckbox>
            ))}
          </div>
        </div> */}
        {/*       <div className="flex flex-col gap-[14px]">
          <div
            className="flex flex-row justify-between px-[35px] pt-[12px] pb-[9px]"
            onClick={() => setshowPropertiesSection(!showPropertiesSection)}
          >
            <p>{t("ruleList.features")}</p>
            <ArrowIcon active={showPropertiesSection} />
          </div>
          <div className="flex flex-col px-[55px] gap-[8px] ">
                   {checkboxContentConsultant.slice(9, 10).map((item) => (
              <FilterCheckbox
                onChange={(event) =>
                  handleCheck(checkboxContentConsultant, setCheckboxContentConsultant, event)
                }
                id={item.id}
                name={item.name}
                checked={item.checked}
                visible={showPropertiesSection}
              ></FilterCheckbox>
            ))} 
          </div>
        </div> */}
        {props.group ? null : (
          <div className="flex flex-col gap-[14px]">
            <div
              className="flex flex-row justify-between px-[35px] pt-[12px] pb-[9px]"
              onClick={() => setshowActivitySection(!showActivitySection)}
              style={{ cursor: "pointer" }}
            >
              <p>{t("ruleList.activityState")}</p>
              <ArrowIcon active={showActivitySection} />
            </div>
            <div className="flex flex-col px-[55px] gap-[8px] ">
              {checkboxContentActive.map((item, key) => (
                <FilterCheckbox
                  onChange={(event) =>
                    handleCheck(
                      checkboxContentActive,
                      setCheckboxContentActive,
                      event,
                      "active"
                    )
                  }
                  key={key}
                  id={item.id}
                  name={item.active.toString()}
                  checked={item.checked}
                  visible={showActivitySection}
                ></FilterCheckbox>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FilterModal;
