import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../Global/Input";
import Button from "../Global/Button";
import { useState, useEffect } from "react";
import MultipleDropdown from "./MultipleDropdown";
import FilterCheckbox from "./Modal/FilterCheckbox";
import InfoPopup from "../Global/InfoPopup";
import { ColorPicker } from "./ColorPicker/ColorPicker";
import { useTranslation } from "react-i18next";
import AddIcon from "../icons/AddIcon";
import Popup from "../Global/Popup";
import ruleService from "../../Services/rule.service";
import ruleCategoryService from "../../Services/ruleCategory.service";
import popupMessageService from "../../Services/popupMessage.service";
import teamsMessageService from "../../Services/teamsMessage.service";
import NewPopupMessageCreate from "../PopupMessagesList/NewPopupMessageCreate";
import NewTeamsMessageCreate from "../TeamsMessageList/NewTeamsMessageCreate";
import userGroupService from "../../Services/userGroup.service";
import keywordService from "../../Services/keyword.service";
import { toast } from "react-toastify";
import CustomToast from "../Global/CustomToast";
import UserGroupMultipleDropdown from "../UserGroup/UserGroupMultipleDropdown";
import LoadingIndicator from "../Global/LoadingIndicator";
import * as XLSX from "xlsx";
function NewRuleCreate() {
  const [array, setarray] = useState([]);
  const [selected, setSelected] = useState({});
  const { t } = useTranslation();
  const [excludedEmail, setExcludedEmail] = useState("");

  const [excludedEmails, setExcludedEmails] = useState([]);

  const addToExcludedEmails = () => {
    if (
      excludedEmail &&
      !excludedEmails.some((emailObj) => emailObj.name === excludedEmail)
    ) {
      let excludedObject = { name: excludedEmail };
      excludedEmails.push(excludedObject);

      setExcludedEmail(""); // Clear input after adding
    }
  };
  const [arrayKeys, setArrayKeys] = useState([]);
  const [arrayPopup, setArrayPopup] = useState([]);
  const [arrayTeams, setArrayTeams] = useState([]);
  const [arrayUserGroup, setArrayUserGroup] = useState([]);
  const [ruleName, setRuleName] = useState("");
  const [ruleKeyName, setRuleKeyName] = useState("");
  const [ruleCategory, setRuleCategory] = useState("");
  const [userGroups, setUserGroups] = useState([]);
  const [userGroupName, setUserGroupName] = useState("");
  const [ruleCategoryName, setRuleCategoryName] = useState("");
  const [ruleCategoryDescription, setRuleCategoryDescription] = useState("");
  const [ruleKeys, setRuleKeys] = useState([]);
  const [ruleRegex, setRuleRegex] = useState("");
  const [rulePopup, setRulePopup] = useState([]);
  const [ruleTeams, setRuleTeams] = useState([]);
  const [advisorEmail, setAdvisorEmail] = useState("");
  const [educationEmail, setEducationEmail] = useState("");
  const [waitingTime, setWaitingTime] = useState("" || 0);
  const [reminderAmount, setReminderAmount] = useState();
  const [waitingInterval, setWaitingInterval] = useState();
  const [addRuleCategory, setAddRuleCategory] = useState(false);
  const [addRuleKey, setAddRuleKey] = useState(false);
  const [addPopup, setAddPopup] = useState(false);
  const [addTeams, setAddTeams] = useState(false);
  const [adduserGroup, setAddUserGroup] = useState(false);
  const [educationCheck, setEducationCheck] = useState(false);
  const [sendIt, setSendIt] = useState(false);
  const [advisorCheck, setAdvisorCheck] = useState(false);
  const [createdInfo, setCreatedInfo] = useState(false);
  const [colorHighlight, setColorHighlight] = useState("#DE8244");

  const [colorText, setColorText] = useState("#000000");
  const [categories, setCategories] = useState([]);
  const [userGroupOptions, setUserGroupOptions] = useState([]);
  const [severityScore, setSeverityScore] = useState(3);

  const [isLogging, setIsLogging] = useState(false);
  const [isAnonym, setIsAnonym] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isLoggingCategory, setIsLoggingCategory] = useState(false);
  const [severityScoreCategory, setSeverityScoreCategory] = useState(3);
  function toastErrorWord(label) {
    toast.error(label, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  function toastError(label) {
    toast.error(label + t("toast"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (arrayKeys.length === 0) {
      toastError(t("ruleList.keys"));
    } else if (arrayPopup.length === 0) {
      toastError(t("ruleList.popupFor"));
    } else if (arrayUserGroup.length === 0) {
      toastError(t("sidebar.userGroups"));
    } else {
      addRule();
    }
  };

  async function addRule() {
    const userGroupsArray = arrayUserGroup.map((item) => parseInt(item.id));
    const keywordsArray = arrayKeys.map((item) => parseInt(item.id));
    const popupMessagesArray = arrayPopup.map((item) => parseInt(item.id));
    const teamMessagesArray = arrayTeams.map((item) => parseInt(item.id));

    setIsLoading(true);
    ruleService
      .AddRule(
        ruleName,
        ruleCategory,
        waitingInterval,
        reminderAmount,
        sendIt ? waitingTime : 0,
        advisorCheck,
        educationCheck,
        isAnonym,
        ruleRegex,
        colorText,
        severityScore,
        advisorEmail,
        educationEmail,
        keywordsArray,
        popupMessagesArray,
        teamMessagesArray,
        userGroupsArray
      )
      .then((res) => {
        setIsLoading(false);
        if (res.status === false) toastErrorFalse();
        else setCreatedInfo(true);
      })
      .catch((err) => {
        setIsLoading(false);
        toastErrorFalse();
      });
  }

  const handleSubmitUserGroup = (event) => {
    event.preventDefault();
    if (array.length === 0) toastError(t("sidebar.userGroups"));
    else addUserGroup();
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];

    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const wordsColumnIndex = dataParse[0].indexOf("Words");
      const separatedWordsArray = dataParse
        .slice(1)
        .map((row) => row[wordsColumnIndex]);
      arrayKeys.splice(0, arrayKeys.length);
      separatedWordsArray.map(async (index) => {
        try {
          if (
            arrayKeys.filter((a) => a?.name === index).length <= 0 &&
            index !== "" &&
            index !== undefined
          ) {
            await addKeywordName(index);
          } else {
            toastErrorWord(t("exist"));
          }
        } catch (error) {
          console.error("An error occurred while processing data:", error);
        }
      });
      fileInputRef.current.value = "";
    };

    reader.readAsBinaryString(f);
  };

  async function addUserGroup() {
    setIsLoading(true);
    const newArray = array.map((item) => parseInt(item.id));
    const excludedEmailsArray = excludedEmails.map((item) => item.name);
    const res = await userGroupService.AddUserGroup(
      userGroupName,
      newArray,
      excludedEmailsArray
    );
    if (res.status === false) {
      setIsLoading(false);
      toastErrorFalse();
    } else {
      let newUserGroup = res.data;
      if (arrayUserGroup.includes(newUserGroup) === false) {
        arrayUserGroup.push(newUserGroup);
      }
      for (let index = 0; index < newUserGroup.group.length; index++) {
        newUserGroup.group[index] = userGroupOptions.filter(
          (op) => op.id === newUserGroup.group[index]
        )[0];
      }
      setIsLoading(false);
      setAddUserGroup(false);
      userGroups.push(newUserGroup);
      setUserGroupName("");
      setExcludedEmails([]);
      setarray([]);
      setExcludedEmail("");
    }
  }

  const handleSubmitCategory = (event) => {
    event.preventDefault();
    addCategory();
  };

  async function addCategory() {
    setIsLoading(true);
    const res = await ruleCategoryService.AddRuleCategory(
      ruleCategoryName,
      ruleCategoryDescription,
      isLoggingCategory,
      severityScoreCategory
    );
    if (!res.status) {
      setIsLoading(false);
      toastErrorFalse();
    } else {
      setIsLoading(false);
      categories.push(res.data);
      setAddRuleCategory(false);
      setRuleCategoryName("");
      setRuleCategoryDescription("");
    }
  }

  async function addKeywordName(ruleKeyName) {
    if (ruleKeyName !== "" && ruleKeyName !== undefined) {
      setIsLoading(true);

      try {
        const res = await keywordService.AddKeyword(ruleKeyName);
        if (!res.status) {
          toastErrorFalse();
        } else {
          const value = res.data;
          if (
            arrayKeys.filter((index) => index?.name === ruleKeyName)?.length <=
            0
          ) {
            setArrayKeys((prevArray) => [...prevArray, value]);
          } else {
            toastErrorWord(t("exist"));
          }
          setRuleKeyName("");
        }
      } catch (error) {
        console.error("Error:", error);
        toastErrorFalse();
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function addKeyword() {
    if (ruleKeyName !== "" && ruleKeyName !== undefined) {
      //  setAddRuleKey(false);
      setIsLoading(true);
      const res = await keywordService.AddKeyword(ruleKeyName);
      if (!res.status) {
        setIsLoading(false);
        toastErrorFalse();
      } else {
        setIsLoading(false);
        const value = res.data;
        if (
          arrayKeys.filter((index) => index?.name === ruleKeyName)?.length <= 0
        ) {
          arrayKeys.push(value);
        }
        setRuleKeyName("");
      }
    } else toastError(t("ruleList.keys"));
  }

  const navigate = useNavigate();
  const createdRule = () => {
    setCreatedInfo(false);
    navigate("/rule-list");
  };

  useEffect(() => {
    ruleCategoryService.RuleCategoryGetAll().then((response) => {
      if (response.status) {
        setCategories(response.data.filter((item) => item.isActive === true));
      } else toastErrorFalse();
    });
    userGroupService.UserGroupGetAll().then((response) => {
      if (response.status) setUserGroups(response.data);
      else toastErrorFalse();
    });
    userGroupService.GetDomainDetailByTenant().then((response) => {
      if (response.status) setUserGroupOptions(response.data[0].group);
      else toastErrorFalse();
    });
  }, []);

  useEffect(() => {
    var selectedCategory = categories.filter(
      (x) => x.id === parseInt(ruleCategory)
    );
    setIsAnonym(false);
    if (selectedCategory.length > 0) {
      setIsLogging(selectedCategory[0].isLogActive);
    }

    /*  console.log(ruleCategory);
    setIsLogging(ruleCategory.isLogActive);*/

    popupMessageService.GetByCategoryId(ruleCategory).then((response) => {
      if (response.status) {
        setRulePopup(response.data.filter((item) => item.isActive === true));
      } else {
        setRulePopup([]);
      }
    });
    teamsMessageService.GetByCategoryId(ruleCategory).then((response) => {
      if (response.status) {
        setRuleTeams(response.data.filter((item) => item.isActive === true));
      } else {
        setRuleTeams([]);
      }
    });
  }, [ruleCategory]);

  const exportToExcel = (data) => {
    const excelData = data.map((item, index) => ({
      Index: index + 1,
      Words: item?.name,
    }));
    const templateHeaders = ["Index", "Words"];
    const templateData = [templateHeaders, ...excelData.map(Object.values)];

    const ws = XLSX.utils.aoa_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "exported_rule_keywords.xlsx");
  };

  const handleExportClick = () => {
    exportToExcel(arrayKeys);
  };

  const fileInputRef = useRef();
  return (
    <div className="px-10 pt-10 w-full min-w-min h-full">
      <CustomToast />
      {isLoading && <LoadingIndicator />}
      <div className="bg-white  w-full h-full rounded-t-[10px]">
        <div className=" h-[120px] w-full p-10">
          <p className="text-dark text-[32px] font-bold leading-8">
            {t("ruleList.newRuleCreate")}
          </p>
        </div>
        <form
          className="flex flex-col px-[40px] pb-[40px] gap-[40px] no-scrollbar overflow-y-auto"
          onSubmit={handleSubmit}
        >
          <Input
            input={t("ruleList.ruleName")}
            value={ruleName}
            onChange={(e) => {
              setRuleName(e.target.value);
            }}
            for="ruleName"
          />
          <div className="flex flex-col gap-[10px]">
            <div className=" flex flex-row justify-between">
              <label
                htmlFor="ruleCategory"
                className="text-base font-bold leading-4 text-dark"
              >
                {t("sidebar.ruleCategory")}
              </label>
              <div
                onClick={() => {
                  setAddRuleCategory(true);
                }}
              >
                <AddIcon></AddIcon>
              </div>
            </div>
            <select
              required
              id="ruleCategory"
              className=" border rounded-[5px] h-[50px] border-darkGray text-dark text-sm leading-[18px] block w-full px-[15px]"
              value={ruleCategory}
              onChange={(e) => {
                setRuleCategory(e.target.value);
                setArrayPopup([]);
                setArrayTeams([]);
              }}
            >
              <option value="" disabled>
                {t("pleaseChoose")}.
              </option>
              {categories.map((value, key) => (
                <option key={key} value={value.id}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>
          {/*           <form className="flex flex-col gap-[10px] w-full">
            <label
              htmlFor="ruleKeys"
              className=" text-base font-bold leading-4 text-dark"
            >
              {t("ruleList.keys")}
            </label>
            <label className="relative block">
              <input
                type="text"
                id="ruleKeys"
                className=" border min-h-[50px] border-darkGray text-dark rounded-[5px] text-sm leading-[18px] font-semibold height-[50px] w-full px-4 py-[15px] "
                autoComplete="on"
                value={ruleKeyName}
                onChange={(e) => setRuleKeyName(e.target.value)}
                required
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                <button onClick={addKeyword}>
                  <AddIcon />
                </button>
              </span>
            </label>
          </form> */}
          <MultipleDropdown
            ruleKeyName={ruleKeyName}
            setRuleKeyName={setRuleKeyName}
            addKeyword={addKeyword}
            title={t("ruleList.keys")}
            moreTitle={t("ruleList.keys")}
            keyword={true}
            ruleKey={true}
            handleUpload={handleUpload}
            fileInputRef={fileInputRef}
            handleExportClick={handleExportClick}
            for="ruleKeys"
            options={ruleKeys}
            array={arrayKeys}
            setarray={setArrayKeys}
            add={true}
            addMethod={() => setAddRuleKey(true)}
          />
          {/*    <Input
            input={t("ruleList.regex")}
            value={ruleRegex}
            onChange={(e) => setRuleRegex(e.target.value)}
            for="ruleRegex"
            required={false}
          ></Input> */}
          <MultipleDropdown
            key={ruleCategoryDescription}
            title={t("ruleList.popupFor")}
            moreTitle={t("ruleList.popupFor")}
            for="rulePopup"
            secondaryGreen={true}
            options={rulePopup}
            array={arrayPopup}
            setarray={setArrayPopup}
            add={true}
            addMethod={() => setAddPopup(true)}
          />
          <MultipleDropdown
            title={t("ruleList.teamsFor")}
            moreTitle={t("ruleList.teamsFor")}
            for="ruleTeams"
            teams={true}
            secondaryGreen={true}
            options={ruleTeams}
            array={arrayTeams}
            setarray={setArrayTeams}
            add={true}
            addMethod={() => setAddTeams(true)}
          />
          <MultipleDropdown
            title={t("sidebar.userGroups")}
            moreTitle={t("sidebar.userGroups")}
            for="ruleEmployeeGroup"
            options={userGroups}
            array={arrayUserGroup}
            setarray={setArrayUserGroup}
            add={true}
            addMethod={() => setAddUserGroup(true)}
          />
          <FilterCheckbox
            checked={advisorCheck}
            onChange={() => setAdvisorCheck(!advisorCheck)}
            name={t("ruleList.askAdvisor")}
            visible="true"
          />
          <Input
            visible={advisorCheck}
            input={t("ruleList.advisorEmail")}
            value={advisorEmail}
            onChange={(e) => setAdvisorEmail(e.target.value)}
            for="advisorEmail"
          />
          {/*     {advisorCheck === true ? (
            <div className="flex flex-col gap-[10px]">
              <label className="text-base font-bold leading-4 text-dark">
                {t("ruleList.buttonName")}
              </label>
              <input
                placeholder="Advisor Name"
                className="bg-green text-white w-[300px] rounded-[5px] text-center font-bold text-xl px-2 py-3 leading-5"
                maxlength="18"
              ></input>
            </div>
          ) : null} */}
          <FilterCheckbox
            checked={educationCheck}
            onChange={() => setEducationCheck(!educationCheck)}
            name={t("ruleList.goEducation")}
          />
          <Input
            visible={educationCheck}
            input={t("ruleList.educationEmail")}
            value={educationEmail}
            onChange={(e) => setEducationEmail(e.target.value)}
            for="educationAddress"
          />
          <FilterCheckbox
            checked={sendIt}
            name={t("ruleList.send")}
            onChange={() => setSendIt(!sendIt)}
          />
          {sendIt ? (
            <div className="w-1/2 pr-[10px]">
              <Input
                input={t("ruleList.waitTime")}
                value={waitingTime}
                placeholder="30 sec"
                onChange={(e) => setWaitingTime(e.target.value)}
                for="waitingTime"
              />
            </div>
          ) : null}
          <FilterCheckbox
            checked={isAnonym}
            name={t("ruleList.isAnonym")}
            onChange={() => setIsAnonym(!isAnonym)}
            disabled={isLogging ? false : true}
          />
          <div className="flex flex-col gap-[10px] w-full ">
            <div className="flex flex-row gap-3">
              {" "}
              <label className=" text-base font-bold leading-4 text-dark">
                {t("ruleList.ruleSeverityScore")}
              </label>
              <div className="group relative">
                <svg
                  className="fill-current h-5 w-5 text-dark mr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>

                <span className="absolute w-[250px] bottom-0 left-6 scale-0 transition-all rounded bg-gray-800 bg-opacity-90 p-2 text-xs text-white group-hover:scale-100">
                  {t("ruleList.ruleScoreDesc")}
                </span>
              </div>
            </div>
            <div className="flex flex-col space-y-2 p-2 w-96">
              <input
                className="accent-green"
                type="range"
                value={severityScore}
                min="1"
                max="5"
                step="1"
                onChange={(e) => {
                  setSeverityScore(e.target.value);
                }}
              />
              <div className="flex justify-between w-full px-[10px]">
                <li className="flex justify-center ">1</li>
                <li className="flex justify-center ">2</li>
                <li className="flex justify-center ">3</li>
                <li className="flex justify-center ">4</li>
                <li className="flex justify-center ">5</li>
              </div>
              <div className="flex justify-between w-full text-xs font-semibold text-darkGray ">
                <div className="w-1/5 flex justify-start">
                  {t("ruleList.tooLow")}
                </div>
                <div className="w-1/5 flex justify-center">
                  {t("ruleList.low")}
                </div>
                <div className="w-1/5 flex justify-center">
                  {t("ruleList.medium")}
                </div>
                <div className="w-1/5 flex justify-center">
                  {t("ruleList.high")}
                </div>
                <div className="w-1/5 flex justify-end">
                  {t("ruleList.tooHigh")}
                </div>
              </div>
            </div>
          </div>
          {/*   <div className="flex flex-row gap-5 w-full ">
            <div className="w-full">
              <div className="flex flex-col gap-[10px]">
                <label
                  htmlFor="reminderAmount"
                  className="text-base font-bold leading-4 text-dark"
                >
                  {t("ruleList.reminderAmount")}
                </label>
                <select
                  id="reminderAmount"
                  className=" border rounded-[5px] h-[50px] border-darkGray text-dark text-sm leading-[18px] block w-full px-[15px] "
                  value={reminderAmount}
                  onChange={(e) => {
                    setReminderAmount(e.target.value);
                  }}
                >
                  {" "}
                  <option value="" disabled selected hidden>
                    {t("pleaseChoose")}
                  </option>
                  <option value={1}>1 kere</option>
                  <option value={2}>2 kere</option>
                  <option value={3}>3 kere</option>
                  <option value={4}>4 kere</option>
                  <option value={5}>5 kere</option>
                </select>
              </div>
            </div>
            <div className="w-full">
              <div className="flex flex-col gap-[10px]">
                <label
                  htmlFor="waitingInterval"
                  className="text-base font-bold leading-4 text-dark"
                >
                  {t("ruleList.reminderRange")}
                </label>
                <select
                  id="waitingInterval"
                  className=" border rounded-[5px] h-[50px] border-darkGray text-dark text-sm leading-[18px] block w-full px-[15px] "
                  value={waitingInterval}
                  onChange={(e) => {
                    setWaitingInterval(e.target.value);
                  }}
                >
                  {" "}
                  <option value="" disabled selected hidden>
                    {t("pleaseChoose")}
                  </option>
                  <option value={4}>4 gün arayla</option>
                  <option value={5}>5 gün arayla</option>
                  <option value={7}>7 gün arayla</option>
                  <option value={10}>10 gün arayla</option>
                  <option value={30}>ayda bir</option>
                </select>
              </div>
            </div>
          </div> */}
          {/* <div className="flex flex-row gap-5 w-full ">
            <div className="w-1/2 pr-[10px] flex flex-col gap-[10px]">
              <label className="text-base font-bold leading-4 text-dark">
                {t("ruleList.unsuitableText")}
              </label>
              <ColorPicker color={colorText} onChange={setColorText} />
            </div>
                  <div className="w-full flex flex-col gap-[10px]">
              <label className="text-base font-bold leading-4 text-dark">
                {t("ruleList.unsuitableHighlight")}
              </label>
              <ColorPicker
                color={colorHighlight}
                onChange={setColorHighlight}
              />
            </div>
          </div> */}
          <div className="flex flex-row items-center justify-center gap-[15px]">
            <Button
              method={() => {
                navigate("/rule-list");
              }}
              outline={true}
              name={t("cancel")}
            />
            {/*   <Button secondaryGreen={true} name={t("draft")} /> */}
            <Button
              submit={true}
              // method={addRule}
              green={true}
              name={t("save")}
            />
          </div>
        </form>
      </div>
      <InfoPopup
        visible={createdInfo}
        close={createdRule}
        content={t("ruleList.newRulePopupInfo")}
      />
      {addRuleCategory && (
        <Popup
          visible={addRuleCategory}
          method={() => {
            setAddRuleCategory(false);
            setRuleCategoryName("");
            setRuleCategoryDescription("");
            setIsLoggingCategory(false);
            setSeverityScoreCategory(3);
          }}
          methodOK={handleSubmitCategory}
          buttonName={t("add")}
        >
          <Input
            input={t("sidebar.ruleCategory")}
            value={ruleCategoryName}
            onChange={(e) => setRuleCategoryName(e.target.value)}
            for="ruleCategory"
          />
          <div className="flex flex-col gap-[10px] w-full">
            <label
              htmlFor="ruleDescription"
              className=" text-base font-bold leading-4 text-dark"
            >
              {t("ruleCategory.description")}
            </label>
            <label className="relative block">
              <textarea
                type="text"
                id="ruleDescription"
                value={ruleCategoryDescription}
                onChange={(e) => setRuleCategoryDescription(e.target.value)}
                className="border min-h-[50px] border-darkGray text-dark rounded-[5px] text-sm leading-[18px] font-semibold height-[50px] w-full px-4 py-[15px] "
                required
              />
            </label>
          </div>
          <div className="w-full">
            <FilterCheckbox
              checked={isLoggingCategory}
              name={t("ruleList.logging")}
              onChange={() => setIsLoggingCategory(!isLoggingCategory)}
            />
          </div>
          <div className="flex flex-col gap-[10px] w-full ">
            <div className="flex flex-row gap-3">
              {" "}
              <label className=" text-base font-bold leading-4 text-dark">
                {t("ruleList.categorySeverityScore")}
              </label>
              <div className="group relative">
                <svg
                  className="fill-current h-5 w-5 text-dark mr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>

                <span className="absolute w-[250px] bottom-0 left-6 scale-0 transition-all rounded bg-gray-800 bg-opacity-90 p-2 text-xs text-white group-hover:scale-100">
                  {t("ruleList.categoryScoreDesc")}
                </span>
              </div>
            </div>
            <div className="flex flex-col space-y-2 p-2 w-80">
              <input
                className="accent-green"
                type="range"
                value={severityScoreCategory}
                min="1"
                max="5"
                step="1"
                onChange={(e) => {
                  setSeverityScoreCategory(e.target.value);
                }}
              />
              <div className="flex justify-between w-full px-[10px]">
                <li className="flex justify-center ">1</li>
                <li className="flex justify-center ">2</li>
                <li className="flex justify-center ">3</li>
                <li className="flex justify-center ">4</li>
                <li className="flex justify-center ">5</li>
              </div>
              <div className="flex justify-between w-full text-xs font-semibold text-darkGray">
                <div className="w-1/5 flex justify-start">
                  {t("ruleList.tooLow")}
                </div>
                <div className="w-1/5 flex justify-center">
                  {t("ruleList.low")}
                </div>
                <div className="w-1/5 flex justify-center">
                  {t("ruleList.medium")}
                </div>
                <div className="w-1/5 flex justify-center">
                  {t("ruleList.high")}
                </div>
                <div className="w-1/5 flex justify-end">
                  {t("ruleList.tooHigh")}
                </div>
              </div>
            </div>
          </div>
        </Popup>
      )}
      <Popup
        visible={addRuleKey}
        method={() => setAddRuleKey(false)}
        methodOK={addKeyword}
        buttonName={t("add")}
      >
        <Input
          input={t("ruleList.keys")}
          value={ruleKeyName}
          onChange={(e) => setRuleKeyName(e.target.value)}
          for="ruleKey"
        ></Input>
      </Popup>
      {addPopup && (
        <NewPopupMessageCreate
          rule={true}
          ruleCategory={ruleCategory}
          visible={addPopup}
          setState={setAddPopup}
          close={() => setAddPopup(false)}
          array={arrayPopup}
        />
      )}
      {addTeams && (
        <NewTeamsMessageCreate
          rule={true}
          ruleCategory={ruleCategory}
          visible={addTeams}
          setState={setAddTeams}
          array={arrayTeams}
          close={() => setAddTeams(false)}
        />
      )}
      {adduserGroup && (
        <Popup
          visible={adduserGroup}
          method={() => {
            setAddUserGroup(false);
            setUserGroupName("");
            setarray([]);
          }}
          methodOK={handleSubmitUserGroup}
          buttonName={t("add")}
        >
          <div className="flex flex-col gap-7 w-full">
            <Input
              input={t("userGroups.groupName")}
              value={userGroupName}
              onChange={(e) => setUserGroupName(e.target.value)}
              for="userGroupName"
            ></Input>
            <UserGroupMultipleDropdown
              title={t("userGroups.memberAdd")}
              moreTitle={t("sidebar.userGroups")}
              for="groupMembers"
              options={userGroupOptions}
              array={array}
              userGroup={true}
              setarray={setarray}
            />
            <MultipleDropdown
              ruleKeyName={excludedEmail}
              setRuleKeyName={setExcludedEmail}
              addKeyword={addToExcludedEmails}
              placeholder={t("userGroups.excludePlaceholder")}
              title={t("userGroups.exclude")}
              moreTitle={t("userGroups.exclude")}
              keyword={true}
              for="excludeEmails"
              array={excludedEmails}
              setarray={setExcludedEmails}
              add={true}
              exclude={true}
            />
          </div>
        </Popup>
      )}
    </div>
  );
}

export default NewRuleCreate;
