import axios from "axios";
import Cookies from 'js-cookie';
const baseDir = process.env.REACT_APP_API_BASE_DIR + "/Category";

class RuleCategoryService {
  async RuleCategoryGetAll(signal) {
    const userToken = Cookies.get("userToken");
    /*  const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.post(
        baseDir + "/getall",
        {},
        {
          signal: signal,
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (!res.data.succeeded) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (err) {
      if (err.name === "AbortError") return;
      else {
        throw err;
      }
    }
  }

  async AddRuleCategory(categoryName, categoryDescription, isLogActive, severityScore) {
    const userToken = Cookies.get("userToken");
    /*      const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.post(
        baseDir + "/add",
        {
          name: String(categoryName),
          description: String(categoryDescription),
          isLogActive: isLogActive,
          severityScore: parseInt(severityScore),
        },
        {
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (!res.data.succeeded) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }

  async UpdateRuleCategory(
    categoryName,
    categoryDescription,
    isActive,
    isLogActive,
    severityScore,
    id,
    operationType
  ) {
    const userToken = Cookies.get("userToken");
    /*  const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/update",
        {
          name: String(categoryName),
          description: String(categoryDescription),
          isActive: isActive,
          isLogActive: isLogActive,
          severityScore: parseInt(severityScore),
          id: parseInt(id),
          operationType: parseInt(operationType),
        },
        {
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (!res.data.succeeded) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export default new RuleCategoryService();
