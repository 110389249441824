import React from "react";
import Popup from "../Global/Popup";
import Input from "../Global/Input";
import { useTranslation } from "react-i18next";

function NewUserInfo(props) {
  const { t } = useTranslation();
  return (
    <Popup
      visible={props.visible}
      buttonName={t("save")}
      method={props.method}
      methodOK={props.methodOK}
    >
      <div className="flex flex-col w-full">
        <div className="w-full flex pb-[48px]  ">
          <p className="text-dark text-[32px] font-bold leading-8">
            {t("account.addUser")}
          </p>
        </div>
        <div className="flex flex-col gap-[40px] w-full">
          <Input
            for="username"
            input={t("username")}
            placeholder="Beril Kıpırtı"
          ></Input>
          <Input
            for="password"
            type="password"
            input={t("password")}
            icon="true"
          ></Input>
          <Input
            for="email"
            input={t("email")}
            placeholder="adminhesabi@gmail.com"
          ></Input>
          <Input
            for="phoneNumber"
            input={t("phone")}
            placeholder="+905555555555"
          ></Input>
        </div>
      </div>
    </Popup>
  );
}

export default NewUserInfo;
