import React from "react";

function CompanyCard(props) {
  return (
    <div className="px-[35px] w-full py-5 border-b border-b-secondaryGray">
      <div className="flex flex-row justify-between  text-dark text-sm leading-[18px] font-semibold">
        <p className="w-1/6 text-start">{props.name}</p>
        <p className="w-1/6 text-center">{props.companyInfo}</p>
        <p className="w-1/6 text-center">{props.taxNumber}</p>
        <p className="w-1/6 text-center">{props.applicationId}</p>
        <p className="w-1/6 text-center">{props.directoryId}</p>
        <p className="w-1/6 text-center">{props.clientValue}</p>
      </div>
    </div>
  );
}

export default CompanyCard;
