import React, { useState } from "react";
import "./pagination.css";

const Pagination = ({ logPerPage, totalLog, paginate }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalLog / logPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleClick = (number) => {
    setCurrentPage(number);
    paginate(number);
  };
  const handleFirstPage = () => {
    setCurrentPage(1);
    paginate(1);
  };

  const handleLastPage = () => {
    setCurrentPage(pageNumbers.length);
    paginate(pageNumbers.length);
  };
  const renderPageNumbers = () => {
    const maxPageNumbers = 5; // Sayfa numaralarının görünümündeki değişiklik
    const currentPageIndex = currentPage - 1;
    const firstPageIndex = Math.max(
      0,
      currentPageIndex - Math.floor(maxPageNumbers / 2)
    );
    const lastPageIndex = Math.min(
      pageNumbers.length - 1,
      firstPageIndex + maxPageNumbers - 1
    );

    const visiblePageNumbers = pageNumbers.slice(
      firstPageIndex,
      lastPageIndex + 1
    );

    return visiblePageNumbers.map((number) => (
      <li
        key={number}
        className={number === currentPage ? "page-item active" : "page-item"}
      >
        <button
          onClick={() => handleClick(number)}
          className="page-link text-dark"
        >
          {number}
        </button>
      </li>
    ));
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      paginate(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
      paginate(currentPage + 1);
    }
  };

  return (
    <nav className="text-right pr-4">
      <ul className="pagination">
        <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
          <button onClick={handleFirstPage} className="page-link text-dark">
            First
          </button>
        </li>
        <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
          <button onClick={handlePreviousPage} className="page-link text-dark">
            Previous
          </button>
        </li>
        {renderPageNumbers()}
        <li
          className={
            currentPage === pageNumbers.length
              ? "page-item disabled"
              : "page-item"
          }
        >
          <button onClick={handleNextPage} className="page-link text-dark">
            Next
          </button>
        </li>
        <li
          className={
            currentPage === pageNumbers.length
              ? "page-item disabled"
              : "page-item"
          }
        >
          <button onClick={handleLastPage} className="page-link text-dark">
            Last
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
