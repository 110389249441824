import Header from "../components/Global/Header";
import Popup from "../components/Global/Popup";
import UserGroupCard from "../components/UserGroup/UserGroupCard";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Input from "../components/Global/Input";
import userGroupService from "../Services/userGroup.service";
import { toast } from "react-toastify";
import CustomToast from "../components/Global/CustomToast";
import InfoPopup from "../components/Global/InfoPopup";
import UserGroupMultipleDropdown from "../components/UserGroup/UserGroupMultipleDropdown";
import MultipleDropdown from "../components/RuleList/MultipleDropdown";
import LoadingIndicator from "../components/Global/LoadingIndicator";

function UserGroup() {
  const { t } = useTranslation();
  const [newUserGroup, setnewUserGroup] = useState(false);
  const [array, setarray] = useState([]);
  const [selected, setSelected] = useState({});
  const [userGroups, setUserGroups] = useState([]);
  const [options, setOptions] = useState([]);
  const [userGroupName, setUserGroupName] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [syncstatus, setSyncstatus] = useState("");
  const [domainId, setDomainId] = useState(0);
  const [infoAddedPopup, setInfoAddedPopup] = useState(false);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [isLoading, setIsLoading] = useState(false);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = userGroups.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .toString()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(userGroups);
    }
  };

  const toastError = () => {
    toast.error("The field is required and cannot be empty.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleSubmitAddUserGroup = (event) => {
    event.preventDefault();
    if (
      userGroupName !== "" &&
      userGroupName !== null &&
      array.length !== 0 &&
      array !== null
    ) {
      addUserGroup();
    } else {
      toastError();
    }
  };
  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  const [excludedEmail, setExcludedEmail] = useState("");

  const [excludedEmails, setExcludedEmails] = useState([]);

  const addToExcludedEmails = () => {
    if (
      excludedEmail &&
      !excludedEmails.some((emailObj) => emailObj.name === excludedEmail)
    ) {
      let excludedObject = { name: excludedEmail };
      excludedEmails.push(excludedObject);

      setExcludedEmail("");
    }
  };
  async function addUserGroup() {
    const newArray = array.map((item) => parseInt(item.id));
    const excludedEmailsArray = excludedEmails.map((item) => item.name);
    setIsLoading(true);
    userGroupService
      .AddUserGroup(userGroupName, newArray, excludedEmailsArray)
      .then((res) => {
        setIsLoading(false);
        if (!res.status) toastErrorFalse();
        else {
          let newUserGroup = res.data;
          for (let index = 0; index < newUserGroup.group.length; index++) {
            newUserGroup.group[index] = options.filter(
              (op) => op.id === newUserGroup.group[index]
            )[0];
          }
          setInfoAddedPopup(true);
          setnewUserGroup(false);
          userGroups.push(newUserGroup);
          setUserGroupName("");
          setExcludedEmails([]);
          setExcludedEmail("");
          setarray([]);
          /*           sessionStorage.setItem("UserGroups", JSON.stringify(userGroups)); */
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toastErrorFalse();
      });
  }

  function onEntry() {
    let userGroupList = [];
    let groups = [];

    userGroupService
      .UserGroupGetAll()
      .then((response) => {
        setIsLoading(false);
        if (response.status) userGroupList = response.data;
        else toastErrorFalse();
      })
      .then(() => {
        setIsLoading(false);
        userGroupService
          .GetDomainDetailByTenant()
          .then((response) => {
            setIsLoading(false);
            if (response.status) {
              setOptions(response.data[0].group);
              groups = response.data[0].group;
              setDomainId(response.data[0].id);
              /* sessionStorage.setItem(
                "UserGroupsOptions",
                JSON.stringify(response.data[0].group)
              );
              sessionStorage.setItem(
                "UserGroupsDomainId",
                JSON.stringify(response.data[0].id)
              ); */
            } else toastErrorFalse();
          })
          .then(() => {
            setIsLoading(false);
            userGroupList.forEach((ug) => {
              for (let index = 0; index < ug.group.length; index++) {
                ug.group[index] = groups.filter(
                  (op) => op.id === ug.group[index]
                )[0];
              }
            });
            setIsLoading(false);
            setUserGroups(userGroupList);

            /*   sessionStorage.setItem("UserGroups", JSON.stringify(userGroupList)); */
          })
          .catch((err) => {
            setIsLoading(false);
            toastErrorFalse();
          });
      });
  }

  useEffect(() => {
    setIsLoading(true);
    /* if (sessionStorage.getItem("UserGroups")) {
      setUserGroups(JSON.parse(sessionStorage.getItem("UserGroups")));
      setOptions(JSON.parse(sessionStorage.getItem("UserGroupsOptions")));
      setDomainId(JSON.parse(sessionStorage.getItem("UserGroupsDomainId")));
      setIsLoading(false);
    } */
    Promise.all([onEntry()]).then(() => {});
  }, []);

  return (
    <div className="px-10 pt-10 w-full min-w-min h-full">
      <CustomToast />
      <div className="bg-white w-full h-full rounded-t-[10px]">
        <Header
          force={forceUpdate}
          group={true}
          array={userGroups}
          setArray={setUserGroups}
          searchOnChange={(e) => searchItems(e.target.value)}
          title={t("userGroups.title")}
          navigate={() => {
            setnewUserGroup(true);
          }}
          buttonName={t("userGroups.newCreate")}
        />
        <div className="py-2 px-10 h-screen overflow-y-scroll no-scrollbar flex flex-col gap-5">
          {searchInput.length > 0
            ? filteredResults.map((value, key) => (
                <UserGroupCard
                  key={key}
                  title={value.name}
                  userGroup={value}
                  userGroups={userGroups}
                  setUserGroups={setUserGroups}
                  members={value.group}
                />
              ))
            : userGroups.map((value, key) => (
                <UserGroupCard
                  key={key}
                  title={value.name}
                  userGroup={value}
                  userGroups={userGroups}
                  setUserGroups={setUserGroups}
                  members={value.group}
                />
              ))}
          {isLoading && <LoadingIndicator isPage={false} />}
        </div>
      </div>
      {newUserGroup && (
        <Popup
          visible={newUserGroup}
          method={() => {
            setnewUserGroup(false);
            setUserGroupName("");
            setarray([]);
          }}
          methodOK={handleSubmitAddUserGroup}
          buttonName={t("add")}
        >
          {" "}
          <div className="flex flex-col gap-7 w-full">
            <Input
              input={t("userGroups.groupName")}
              value={userGroupName}
              onChange={(e) => setUserGroupName(e.target.value)}
              for="userGroupName"
            />
            <UserGroupMultipleDropdown
              title={t("userGroups.memberAdd")}
              for="groupMembers"
              options={options}
              array={array}
              userGroup={true}
              setarray={setarray}
            />{" "}
            <MultipleDropdown
              ruleKeyName={excludedEmail}
              setRuleKeyName={setExcludedEmail}
              addKeyword={addToExcludedEmails}
              title={t("userGroups.exclude")}
              placeholder={t("userGroups.excludePlaceholder")}
              moreTitle={t("userGroups.exclude")}
              keyword={true}
              for="excludeEmail"
              array={excludedEmails}
              setarray={setExcludedEmails}
              add={true}
              exclude={true}
            />
          </div>
        </Popup>
      )}
      {infoAddedPopup && (
        <InfoPopup
          visible={infoAddedPopup}
          close={() => setInfoAddedPopup(false)}
          content={t("userGroups.addInfo")}
        />
      )}
    </div>
  );
}

export default UserGroup;
