import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
function SearchSection(props) {
  const { t } = useTranslation();

  if (props.visible === false) {
    return null;
  }
  return (
    <div className="px-[40px]  pb-[40px]">
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-[24px] pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              d="M7.09777 14.9677C8.81563 15.1646 10.5441 14.7283 11.9608 13.7444L16.0463 17.8298C16.0463 17.8298 16.0463 17.8298 16.0463 17.8299C16.2831 18.0667 16.6042 18.1999 16.9391 18.2C17.274 18.2001 17.5953 18.0672 17.8322 17.8304C18.0691 17.5937 18.2023 17.2725 18.2024 16.9376C18.2025 16.6027 18.0696 16.2814 17.8328 16.0445L17.8328 16.0445L13.7463 11.958C14.7299 10.5403 15.1653 8.81099 14.9671 7.09272C14.7619 5.31342 13.8925 3.67721 12.5327 2.51144C11.1729 1.34567 9.42309 0.736312 7.63333 0.805274C5.84356 0.874237 4.14583 1.61644 2.87979 2.88338C1.61375 4.15033 0.872762 5.84859 0.80508 7.63841C0.737398 9.42822 1.34801 11.1776 2.51475 12.5365C3.68149 13.8955 5.31833 14.7638 7.09777 14.9677ZM13.5523 7.90499C13.5523 8.64622 13.4063 9.38019 13.1226 10.065C12.839 10.7498 12.4232 11.372 11.8991 11.8962C11.3749 12.4203 10.7527 12.836 10.0679 13.1197C9.38311 13.4034 8.64914 13.5493 7.90792 13.5493C7.16669 13.5493 6.43272 13.4034 5.74792 13.1197C5.06311 12.836 4.44088 12.4203 3.91676 11.8962C3.39263 11.372 2.97687 10.7498 2.69321 10.065C2.40956 9.38019 2.26356 8.64622 2.26356 7.90499C2.26356 6.40802 2.85823 4.97236 3.91676 3.91383C4.97528 2.85531 6.41094 2.26064 7.90792 2.26064C9.40489 2.26064 10.8406 2.85531 11.8991 3.91383C12.9576 4.97236 13.5523 6.40802 13.5523 7.90499Z"
              fill="#95979B"
              stroke="#95979B"
              strokeWidth="0.4"
            />
          </svg>
        </div>
        <input
          onChange={props.onChange}
          type="search"
          id="default-search"
          className="block w-full p-[15px] pl-[52px] text-sm leading-[18px] text-dark border border-secondaryGray rounded-[5px] bg-white shadow-[0px_2px_12px_rgba(149,_151,_155,_0.2)]"
          placeholder={t("ruleList.searchPlaceholder")}
        />
      </div>
    </div>
  );
}

export default SearchSection;
