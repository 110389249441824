import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

function ColumnChart({ data }) {
  const { t } = useTranslation();
  /*  const series = [
    {
      name: "Net Profit",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: "Revenue",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
    {
      name: "Free Cash Flow",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
  ]; */
  const [series, setSeries] = useState([
    {
      name: "sales",

      data: [
        {
          x: "Kural 1",
          y: 400,
          fillColor: "#F8A085",
        },
        {
          x: "K2",
          y: 430,
          fillColor: "#F8A085",
        },
        {
          x: "K3",
          y: 448,
          fillColor: "#F8A085",
        },
        {
          x: "Kural 1",
          y: 400,
          fillColor: "#F8A0ff",
        },
        {
          x: "K2",
          y: 430,
          fillColor: "#F8A0ff",
        },
        {
          x: "K3",
          y: 448,
          fillColor: "#F8A0ff",
        },
        {
          x: "Kural 4",
          y: 400,
          fillColor: "#F8A0ff",
        },
        {
          x: "K1",
          y: 430,
          fillColor: "#F8A585",
        },
        {
          x: "K2",
          y: 448,
          fillColor: "#F8A585",
        },
      ],
    },
  ]);

  const [groups, setGroups] = useState([
    { title: "C1", cols: 3 },
    { title: "C2", cols: 4 },
    { title: "C3", cols: 2 },
  ]);

  const options = {
    chart: {
      type: "bar",
      height: 300,

      zoom: {
        enabled: true,
        type: "x",
        resetIcon: {
          offsetX: -10,
          offsetY: 0,
          fillColor: "#fff",
          strokeColor: "#37474F",
        },
        selection: {
          background: "#90CAF9",
          border: "#0D47A1",
        },
      },
      /* zoom: {
        enabled: true,
         type: "x",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        }, 
      },*/
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    xaxis: {
      type: "category",
      labels: {
        formatter: function (val) {
          return val.length > 20 ? val.slice(0, 19) + "..." : val;
        },
        tickPlacement: "on",
      },
      group: {
        style: {
          fontSize: "10px",
          fontWeight: 700,
        },
        groups: groups,
      },
    },

    tooltip: {
      x: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  useEffect(() => {
    if (!data === false) {
      setSeries(data.data);
      setGroups(data.groups);
    }
  });

  return (
    <div id="chart" className="h-auto w-full ">
      {data == null ? (
        <h1 className="text-md text-center font-semibold text-red">
          {t("error.noData")}
        </h1>
      ) : (
        <ReactApexChart
          height={300}
          options={options}
          series={series}
          type="bar"
        />
      )}
    </div>
  );
}

export default ColumnChart;
