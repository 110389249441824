import React from "react";

function LogIcon({ active }) {
  const data = active ? filled : outline;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      {data}
    </svg>
  );
}

const outline = (
    <svg width="32" height="32" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H24C24.5304 20 25.0391 19.7893 25.4142 19.4142C25.7893 19.0391 26 18.5304 26 18V1C26 0.734784 25.8946 0.48043 25.7071 0.292893C25.5196 0.105357 25.2652 0 25 0ZM2 8H7V12H2V8ZM9 8H24V12H9V8ZM24 2V6H2V2H24ZM2 14H7V18H2V14ZM24 18H9V14H24V18Z" fill="#2A3036"/>
    </svg>    
);

const filled = (
    <svg width="32" height="32" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H24C24.5304 20 25.0391 19.7893 25.4142 19.4142C25.7893 19.0391 26 18.5304 26 18V1C26 0.734784 25.8946 0.48043 25.7071 0.292893C25.5196 0.105357 25.2652 0 25 0ZM2 8H7V12H2V8ZM9 8H24V12H9V8ZM2 14H7V18H2V14ZM24 18H9V14H24V18Z" fill="#2A3036"/>
    </svg>    
);

export default LogIcon;