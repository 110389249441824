import React from "react";
import IconButton from "../Global/IconButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Popup from "../Global/Popup";
import InfoPopup from "../Global/InfoPopup";
import { useTranslation } from "react-i18next";
import PopupDelete from "../icons/PopupDelete";

function AccountInfoCard(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deletedPopup, setDeletedPopup] = useState(false);
  const [infoDeletedPopup, setInfoDeletedPopup] = useState(false);
  const deleteCard = () => {
    setDeletedPopup(false);
    setInfoDeletedPopup(true);
  };
  return (
    <div className="pl-[34px] pr-[36px] w-full py-[10px]  border-b border-b-secondaryGray">
      <div className="flex flex-row justify-between  text-dark text-sm leading-[18px] font-semibold">
        <div className="flex flex-row w-full justify-between items-center ">
          <p className="w-1/4 text-start">{props.name}</p>
          <p className="w-1/4 text-center">{props.password}</p>
          <p className="w-1/4 text-center">{props.email}</p>
          <p className="w-1/4 text-center">{props.phoneNumber}</p>
        </div>
        <div className="flex flex-row gap-[15px] min-w-[180px] max-w-[180px] items-center justify-center">
          <IconButton
            className="bg-black"
            method={() => {
              setDeletedPopup(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M9.33398 7.33301V10.6663M2.66732 3.99967H13.334L12.2807 13.4797C12.2445 13.8059 12.0894 14.1074 11.8448 14.3263C11.6003 14.5453 11.2836 14.6664 10.9553 14.6663H5.04598C4.71774 14.6664 4.40103 14.5453 4.15648 14.3263C3.91194 14.1074 3.75676 13.8059 3.72065 13.4797L2.66732 3.99967ZM4.89732 2.09767C5.00515 1.86899 5.17579 1.67567 5.38932 1.54028C5.60285 1.40489 5.85048 1.333 6.10332 1.33301H9.89798C10.1509 1.33288 10.3987 1.40471 10.6124 1.5401C10.826 1.6755 10.9968 1.86888 11.1047 2.09767L12.0007 3.99967H4.00065L4.89732 2.09767V2.09767ZM1.33398 3.99967H14.6673H1.33398ZM6.66732 7.33301V10.6663V7.33301Z"
                stroke="#2A3036"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
          <IconButton
            edit={true}
            method={() => {
              navigate("/account-info/user-infos");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="4"
              viewBox="0 0 14 4"
              fill="none"
            >
              <path
                d="M6.8125 2.5C7.22671 2.5 7.5625 2.16421 7.5625 1.75C7.5625 1.33579 7.22671 1 6.8125 1C6.39829 1 6.0625 1.33579 6.0625 1.75C6.0625 2.16421 6.39829 2.5 6.8125 2.5Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.875 2.5C12.2892 2.5 12.625 2.16421 12.625 1.75C12.625 1.33579 12.2892 1 11.875 1C11.4608 1 11.125 1.33579 11.125 1.75C11.125 2.16421 11.4608 2.5 11.875 2.5Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.75 2.5C2.16421 2.5 2.5 2.16421 2.5 1.75C2.5 1.33579 2.16421 1 1.75 1C1.33579 1 1 1.33579 1 1.75C1 2.16421 1.33579 2.5 1.75 2.5Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
      </div>
      <Popup
        buttonName={t("delete")}
        method={() => setDeletedPopup(false)}
        methodOK={deleteCard} // add user deleted method
        visible={deletedPopup}
        content={t("account.deletePopup")} //api method get deleted user name
      >
        <PopupDelete></PopupDelete>
      </Popup>
      <InfoPopup
        visible={infoDeletedPopup}
        close={() => setInfoDeletedPopup(false)}
        content={t("account.deletePopupInfo")} //api method get deleted user name
      ></InfoPopup>
    </div>
  );
}

export default AccountInfoCard;
