import React from "react";

function SortModalRadiobox(props) {
  if (props.visible === false) return null;

  return (
    <div className="flex flex-row gap-[8px]">
      <input
        className="form-check-input appearance-none rounded-full h-[20px] w-[20px] border border-darkGray bg-white  checked:border-green checked:bg-green  transition duration-200"
        type="radio"
        name="flexRadioDefault"
        checked={props.optionCode == props.selected}
        id={props.optionCode}
        onChange={(e) => {
          props.handleChange(props.optionCode);
        }}
      />
      <label
        className="text-sm leading-[18px] font-semibold text-textGray"
        htmlFor={props.optionCode}
      >
        {props.name}
      </label>
    </div>
  );
}

export default SortModalRadiobox;
