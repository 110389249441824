import React from "react";

function CalendarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M17.6391 2.57397H1.89837C1.4637 2.57397 1.11133 2.92634 1.11133 3.36101V19.1018C1.11133 19.5364 1.4637 19.8888 1.89837 19.8888H17.6391C18.0738 19.8888 18.4261 19.5364 18.4261 19.1018V3.36101C18.4261 2.92634 18.0738 2.57397 17.6391 2.57397Z"
        stroke="#2A3036"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4902 0.999878V4.14803"
        stroke="#2A3036"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.04688 0.999878V4.14803"
        stroke="#2A3036"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.11133 7.29614H18.4261"
        stroke="#2A3036"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CalendarIcon;
