import React from "react";

function DatePicker() {
  return (
    <div>
      <input
        className="flex items-center h-[40px] px-2 border-secondaryGray border-2 rounded-[3px]  shadow-[0px_2px_12px_rgba(149,_151,_155,_0.2)]"
        type="date"
        id="start"
        min="2022-01-01"
      />
    </div>
  );
}

export default DatePicker;
