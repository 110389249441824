import React, { useEffect, useState, useRef } from "react";
import IconButton from "../../components/Global/IconButton";
import OutlineButton from "../../components/Global/OutlineButton";
import FilterModal from "../../components/RuleList/Modal/FilterModal";
import SortModal from "../../components/RuleList/Modal/SortModal";
import SearchSection from "../../components/Global/SearchSection";
import FilterIcon from "../../components/icons/FilterIcon";
import SortIcon from "../../components/icons/SortIcon";
import SearchIcon from "../../components/icons/SearchIcon";

function Header(props) {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showSortModal, setShowSortModal] = useState(false);
  const [showSearchSection, setShowSearchSection] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowFilterModal(false);
        setShowSortModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className=" h-[120px] w-full flex flex-row justify-between items-center p-10">
        <p className="text-dark text-[32px] font-bold leading-8">
          {props.title}
        </p>
        <div className="relative flex flex-row gap-5" ref={modalRef}>
          <OutlineButton method={props.navigate} content={props.buttonName} />
          <div className={props.filterVisible === false ? "hidden" : null}>
            <IconButton
              changeColor={showFilterModal}
              method={() => {
                setShowFilterModal((prevState) => !prevState);
                setShowSortModal(false);
              }}
            >
              <FilterIcon active={showFilterModal} />
            </IconButton>
          </div>
          <IconButton
            changeColor={showSortModal}
            method={() => {
              setShowSortModal((prevState) => !prevState);
              setShowFilterModal(false);
            }}
          >
            <SortIcon active={showSortModal} />
          </IconButton>
          <IconButton
            changeColor={showSearchSection}
            method={() => {
              setShowSearchSection(!showSearchSection);
            }}
          >
            <SearchIcon active={showSearchSection} />
          </IconButton>
          <div ref={modalRef}>
            {
              <FilterModal
                group={props.group}
                popup={props.popup}
                teams={props.teams}
                rule={props.rule}
                array={props.array}
                setArray={props.setArray}
                consultant={props.consultant}
                visible={showFilterModal}
              ></FilterModal>
            }
            {showSortModal && (
              <SortModal
                visible={showSortModal}
                array={props.array}
                setArray={props.setArray}
                force={props.force}
              ></SortModal>
            )}
          </div>
        </div>
      </div>
      {showSearchSection && (
        <SearchSection
          onChange={props.searchOnChange}
          visible={showSearchSection}
        />
      )}
    </>
  );
}

export default Header;
