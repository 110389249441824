import { useState } from "react";
import AddIcon from "../icons/AddIcon";
function Input(props) {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  if (props.visible === false) return null;
  return (
    <div className="flex flex-col gap-[10px] w-full">
      <div className="flex flex-row justify-between">
        <label
          htmlFor={props.for}
          className=" text-base font-bold leading-4 text-dark"
        >
          {props.input}
        </label>
        {props.add === true ? (
          <div>
            <AddIcon></AddIcon>
          </div>
        ) : null}
      </div>
      <label className="relative block">
        <input
          type={passwordShown ? "text" : props.type}
          id={props.for}
          value={props.value}
          name={props.name}
          onChange={props.onChange}
          className=" border min-h-[50px] border-darkGray text-dark rounded-[5px] text-sm leading-[18px] font-semibold height-[50px] w-full px-4 py-[15px] "
          placeholder={props.placeholder}
          autoComplete="on"
          required={props.required === false ? false : true}
        />
        {props.icon === "true" ? (
          <span className="absolute inset-y-0 right-0 flex items-center pr-3">
            <button onClick={togglePassword} type="button">
              <svg
                width="22"
                height="9"
                viewBox="0 0 22 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.83398 1.33398C1.83398 1.33398 5.04232 5.00065 11.0007 5.00065C16.959 5.00065 20.1673 1.33398 20.1673 1.33398M3.66732 2.8419L1.83398 5.00065M20.1673 5.00065L18.3377 2.84465M8.17182 4.70732L7.33398 7.29232M13.8084 4.71465L14.6673 7.29232"
                  stroke="#95979B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </span>
        ) : null}
      </label>
    </div>
  );
}

export default Input;
