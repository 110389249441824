import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import Backend from "i18next-http-backend";

const resources = {
  tr: {
    translation: {
      exist: "Kelime zaten bulunuyor.",
      template: "Import edilmeye uygun Index ve Words sütunlarına sahip boş bir Excel dosyası indirir.",
      export: "Kurala ait olan bütün kelimeler bir Excel dosyasına kaydeder.",
      import: "Excel dosyası yükleyerek kurala ait kelimelere ekler.",
      toast: " alanı gereklidir. Boş bırakmayınız.",
      errorInfo: "Bir hata ile karşılaştık tekrar deneyin.",
      errorIsActive: "Bir veya birden çok kurala bağlı olmamalıdır.",
      emailInvalid: "Geçersiz e-posta adresi",
      passwordLongError:
        "Şifre uzunluğu en az 8 karakter en fazla 15 karakter olmalıdır.",
      sameError: "Şifreler aynı değil.",
      loading: "Yükleniyor...",
      not: "Seçenek bulunamadı",
      pleaseChoose: "Lütfen Seçiniz...",
      emptyError: "Boş alan bırakmayınız.",
      showMore: "Tümünü Göster",
      showLess: "Daha Az Göster",
      save: "Kaydet",
      draft: "Taslak Olarak Kaydet",
      cancel: "Vazgeç",
      logoutPopup:
        "Çıkış yapıyorsunuz. Devam etmek istediğinizden emin misiniz?",
      delete: "Sil",
      add: "Ekle",
      fullname: "Ad Soyad",
      message: "Mesaj",
      send: "Gönder",
      username: "Kullanıcı Adı",
      password: "Şifre",
      date: "Tarih",
      email: "E-Posta Adresi",
      phone: "Telefon Numarası",
      saveChanges: "Değişiklikleri Kaydet",
      allCategories: "Tüm Kategoriler",
      usernamePlaceholder: "Kullanıcı adını giriniz.",
      sidebar: {
        ruleList: "Kural Listesi",
        popupMessages: "Popup Mesajları",
        teamsContents: "Teams İçerikleri",
        ruleCategory: "Kural Kategorisi",
        userGroups: "Kullanıcı Grupları",
        dashboard: "Dashboard",
        profileInfo: "Profil Bilgileri",
        accountInfo: "Hesap Bilgileri",
        support: "Destek",
        logout: "Çıkış Yap",
        termsConditions: "Şartlar ve Koşullar",
        privacyPolicy: "Gizlilik Sözleşmesi",
        cookiePolicy: "Çerez Politikası",
        kvkk: "Kişisel Verilerin Korunması Politikası",
      },
      login: {
        management: "Yönetim Paneli'ne Hoşgeldiniz!",
        forget: "Şifremi Unuttum",
        sec: "sn",
        login: "Giriş Yap",
        forgetTitle: "Şifrenizi mi Unuttunuz?",
        reset: "Şifreyi Yenile",
        emailPlaceholder: "Kullanıcı adınızla eşleşen e-mailinizi giriniz.",
        verifyPopup: "Doğrulama kodu e-mail adresinize gönderildi.",
        smsTitle: "Lütfen sms olarak gönderilen doğrulama kodunu giriniz.",
        sendAgain: "Tekrar Gönder",
        verify: "Doğrula",
        verifyCode: "Doğrulama Kodu",
        newPasswordTitle: "Lütfen yeni bir şifre belirleyiniz.",
        passwordSet: "Şifre Belirle",
        successPassword: "Tebrikler. Şifreniz başarıyla oluşturuldu!",
        newPasswordPlaceholder: "Yeni şifre giriniz.",
        newPasswordConfirmPlaceholder: "Yeni şifreyi tekrar giriniz.",
        emailVerifyTitle:
          "Yeni bir şifre belirlemek için e-mail adresinize gönderilen doğrulama kodunu giriniz.",
        reCaptchaError: "Lütfen reCaptcha'yı doğrulayınız.",
      },
      home: {
        ruleCreate: "Kural Oluştur",
        popupCreate: "Popup Mesajı Oluştur",
        teamsCreate: "Teams İçeriği Oluştur",
        ruleInfo:
          "Yeni kural oluşturarak kurum politikalarınıza farkındalık yaratın, uyumluluğunuzu yükseltin.",
        popupInfo:
          "Oluşturduğunuz kuralların ihlal edilmesi durumunda kullanıcılara gösterilecek Popup mesaj içeriklerini düzenleyin.",
        teamsInfo:
          "Farkındalığı iyileştirmek ve sürekli kılmak için kural ihlallerinde kullanıcılara gönderilecek Teams içeriklerini düzenleyin.",
      },
      ruleList: {
        editInfo: "Kural güncellendi.",
        buttonName: " Özelleştirilmiş Buton",
        title: "Kural Listesi",
        newRuleCreate: "Yeni Kural Oluştur",
        newRuleAdd: "Yeni Kural Ekle",
        popupFor: "Kurala Ait Popup Mesajları",
        teamsFor: "Kurala Ait Teams İçerikleri",
        keys: "Kurala Ait Anahtar Kelimeler",
        filter: "Filtrele",
        clear: "Tümünü Temizle",
        userGroup: "Etkilenen Kullanıcı Grupları",
        educationAddress: "Eğitim Adresi",
        advisor: "Danışman Adresi",
        features: "Özellikler",
        activityState: "Aktiflik Durumu",
        sort: "Sırala",
        sortCriteria: "Sıralama Kriteri",
        new: "En Yeni Eklenen",
        old: "En Eski Eklenen",
        az: "A'dan Z'ye",
        za: "Z'den A'ya",
        searchPlaceholder: "Aramak istediğiniz kelimeyi yazın...",
        ruleEdit: "Kuralı Güncelle",
        ruleName: "Kural Adı",
        regex: "Kurala Ait Regex Kelimeler",
        workers: "Kuralın Uygulanacağı Çalışan Grupları",
        askAdvisor: "Danışmana sor özelliği aktive edilsin",
        advisorEmail: "Danışman E-Posta Addresi",
        goEducation: "Eğitime git butonu aktive edilsin",
        educationEmail: "Eğitim Adresi",
        send: "Yinede gönder butonu aktive edilsin",
        waitTime: "Bekletileceği Süre",
        reminderAmount: "Hatırlatma Miktarı",
        reminderRange: "Hatırlatma Aralığı",
        unsuitableText: "Uygunsuz Kelimelerin Text Rengi",
        unsuitableHighlight: "Uygunsuz Kelimelerin Highlight Rengi",
        deletePopup:
          "kuralı silinecek. Devam etmek istediğinizden emin misiniz?",
        deletePopupInfo: "Kural silindi.",
        newRulePopupInfo: "Yeni Kural Oluşturuldu.",
        isAnonym: "Log Anonim Olsun",
        logging: "Loglama",
        tooLow: "çok düşük",
        low: "düşük",
        medium: "orta",
        high: "yüksek",
        tooHigh: "çok yüksek",
        ruleSeverityScore: "Kural Ağırlık Değeri",
        categorySeverityScore: "Kategori Ağırlık Değeri",
        ruleScoreDesc: "Aynı kategorideki her bir kuralın farkındalık skoruna etkisini dengeleyen değerdir. Yüksek değer daha fazla etkiler.",
        categoryScoreDesc: "Her bir kategorinin farkındalık skoruna etkisini belirleyen değerdir. Yüksek değer daha fazla etkiler."
      },
      popup: {
        title: "Popup Mesajları Listesi",
        newCreate: "Yeni Popup Mesajı Ekle",
        deletePopup:
          "popup mesajı silenecek. Silmek istediğinize emin misiniz ?",
        deletePopupInfo: "Popup mesaj silindi.",
        edit: "Popup Mesajını Güncelle",
        name: "Popup Mesaj Adı",
        content: "İçerik",
        newCreateInfo: "Yeni popup mesajı oluşturuldu.",
        editInfo: "Popup Mesajı değiştirildi.",
      },
      teams: {
        title: "Teams İçerikleri Listesi",
        newCreate: "Yeni Teams İçeriği Oluştur",
        deletePopup:
          "teams içeriği silinecek. Silmek istediğinizden emin misiniz?",
        deletePopupInfo: "Teams içeriği silindi",
        name: "Teams İçeriği Adı",
        edit: "Teams İçeriğini Güncelle",
        editInfo: "teams içeriği güncellendi.",
        newCreateInfo: "Yeni teams içeriği oluşturuldu.",
      },
      ruleCategory: {
        title: "Kural Kategori Listesi",
        newCreate: "Yeni Kural Kategorisi Ekle",
        description: "Kural Açıklaması",
        deletePopup:
          "kural kategorisi silinecek. Silmek istediğinize emin misiniz?",
        deletePopup1: "Silmek istediğiniz kural kategorisine ait",
        deletePopup2: "var. Silmek istediğinizden emin misiniz?",
        deletePopupInfo: "Kural Kategorisi başarıyla silindi.",
        goTeams: "Teams İçeriklerine Git",
        goPopup: "Popup Mesajlarına Git",
        goRule: "Kurallara Git",
        belong: "Kural Kategorisine Ait,",
        deleteError: "Kategorinin kullanıldığı bir veya daha fazla Kural, Teams Mesajı veya Popup mevcut.",
      },
      profile: {
        title: "Profil Bilgileri",
        usernameInfo: "Kullanıcı adı güncellendi",
        oldPassword: "Eski Şifre",
        newPassword: "Yeni Şifre",
        newPasswordConfirm: "Yeni Şifre Tekrar",
        passwordInfo: "Şifre güncellendi.",
        emailInfo: "E-mail güncellendi.",
        phoneInfo: "Telefon numarası güncellendi.",
      },
      account: {
        title: "Hesap Bilgileri",
        date: "Hesap Geçerlilik Tarihi:",
        userNumbers: "Kullanıcı Sayısı:",
        allUsers: " Hesaba Bağlı Tüm Kullanıcılar",
        addUser: "Yeni Kullanıcı Ekle",
        customerName: "Müşteri Adı",
        deletePopup:
          "isimli kullanıcı silinecek. Silmek istediğinizden emin misiniz?",
        deletePopupInfo: "Kullanıcı silindi.",
        userInfo: "Kullanıcı Bilgileri",
      },
      dashboard: {
        notificationTitle: "Dashboard Hakkında Bilgilendirme",
        notificationDesc: "Dashboard verileri ve grafiklerinin anlamlı olması için en az bir ay geçmesi önerilir.",
        checked: "Kontrol edilen mail",
        capture: "Yakalanan Kelimeler",
        previous: "Bir önceki döneme kıyasla ",
        impressions: "Gösterim Sayısı",
        popupImp: "Popup Gösterim",
        teamsImp: "Teams İçeriği Gösterim",
        defineRule: "Tanımlanan Kurallar",
        totalScore: "Toplam Skor",
        totalScoreDesc: "Seçili dönemde şirketin almış olduğu total skor gösterilmektedir.",
        scoreByCategory: "Kategori Bazlı Skor",
        sliderDesc: "Seçili dönemde şirketin 6 farklı kategori bazında almış olduğu skorlar gösterilmektedir.",
        summary: "Özet",
        summaryDesc: "Seçili dönemde şirket faaliyetlerinin özeti gösterilmektedir.",
        different: "farklı",
        aTotalof: "Toplamda",
        rules: "kurala",
        mail: "mail",
        wereStuck: "Kurala takılınan mail",
        differentUser: "farklı kullanıcı",
        stuckToTheRule: "Kurala takılan farklı kullanıcı",
        differentWords: "farklı kelime",
        wereCaptured: "Yakalanan farklı kelime",
        differentRule: "Yakalanan farklı kural",
        differentPopup: "farklı popup",
        wereShown: "Gösterilen popup",
        lineChartTitle: "Kategori Bazlı Farkındalık Skoru Değişimi",
        lineChartDesc: "Seçili dönemde en aktif 5 kategori bazında haftalara göre Farkındalık Skoru'nun değişmi gösterilmektedir.",
        columChartTitle1: "Kategori Bazlı Takılınan Kurallar",
        columChartDescr: "Seçili dönemde en aktif 5 kategori bazında hangi kurallara ne kadar takılındığı gösterilmektedir.",
        columnChartTitle2: "Kategori Bazlı Takılınan Kelimeler",
        columnChartDesc2: "Seçili dönemde en aktif 5 kategori bazında hangi kelimelere ne kadar takılındığı gösterilmektedir.",
        horizontalTitle: "Kategoriye Göre En Fazla Yakalanan Kelimler"

      },
      error: {
        sory: "Üzgünüz",
        notFound: "Aradığınız sayfa bulunamadı.",
        noData: "Veri bulunamadı.",
      },
      userGroups: {
        title: "Kullanıcı Grupları",
        newCreate: "Yeni Kullanıcı Grubu Oluştur",
        member: "Grup Üyeleri",
        deletePopup:
          "isimli kullanıcı grubu silinecek. Silmek istediğinizden emin misiniz?",
        deletePopupInfo: "Kullanıcı grubu silindi.",
        editInfo: "kullanıcı grubu değiştirildi.",
        addInfo: "Kullanıcı grubu eklendi.",
        memberAdd: "Üye Ekle",
        groupName: "Grup Adı",
        memberName: "Üye Adı",
        exclude: "Kullanıcı Hariç Tut",
        excludePlaceholder: "Kullanıcının e-posta adresini yazın"
      },
      log: {
        log: "Sistem Logları",
        violatedRule: "İhlal Edilen Kural",
        violatedWords: "İhlal Edilen Kelimeler"
      }
    },
  },
  en: {
    translation: {
      template: "It downloads a blank Excel file with Index and Words columns suitable for import.",
      export: "All words related to the rule are saved in an Excel file.",
      import: "It uploads an Excel file and adds it to the words of the rule.",
      exist: "The word already exists.",
      errorIsActive: "It should not be bound by one or more rules.",
      not: "Option not found.",
      errorInfo: "We encountered an error, try again.",
      toast: " field is required and cannot be empty.",
      emailInvalid: "Email is invalid.",
      passwordLongError:
        "Password length must be at least 8 characters and at most 15 characters.",
      sameError: "Passwords are not the same.",
      loading: "Loading...",
      pleaseChoose: "Please Choose...",
      emptyError: "The field is required and cannot be empty.",
      showMore: "Show More",
      showLess: "Show Less",
      save: "Save",
      draft: "Save as Draft",
      date: "Date",
      cancel: "Cancel",
      delete: "Delete",
      add: "Add",
      fullname: "Full Name",
      message: "Message",
      send: "Send",
      username: "User Name",
      password: "Password",
      email: "E-mail Address",
      phone: "Phone Number",
      saveChanges: "Save Changes",
      allCategories: "Tüm Kategoriler",
      logoutPopup: "You are exiting. Are you sure you want to continue?",
      usernamePlaceholder: "Please enter user name.",
      sidebar: {
        ruleList: "Rule List",
        popupMessages: "Popup Messages",
        teamsContents: "Teams Contents",
        ruleCategory: "Rule Category",
        userGroups: "User Groups",
        dashboard: "Dashboard",
        profileInfo: "Profile Info",
        accountInfo: "Account Info",
        support: "Support",
        logout: "Logout",
        termsConditions: "Terms and Conditions",
        privacyPolicy: "Confidentiality Agreement",
        cookiePolicy: "Cookie Policy",
        kvkk: "Protection of Personal Data Policy",
      },
      login: {
        management: "Welcome to Admin Panel!",
        forget: "I Forgot My Password",
        login: "Login",
        sec: "sec",
        forgetTitle: "Forgot Your Password?",
        reset: "Reset Password",
        emailPlaceholder: "Enter your e-mail matching your username.",
        verifyPopup: "Verification code has been sent to your e-mail address.",
        smsTitle: "Please enter the verification code sent as sms.",
        sendAgain: "Send Again",
        verify: "Verify",
        verifyCode: "Verification Code",
        newPasswordTitle: "Please set a new password.",
        passwordSet: "Set Password",
        successPassword:
          "Congratulations. Your password has been successfully created!",
        newPasswordPlaceholder: "Enter new password.",
        newPasswordConfirmPlaceholder: "Re-enter the new password.",
        emailVerifyTitle:
          "To set a new password, enter the verification code sent to your e-mail address.",
        reCaptchaError: "Please verify that you are not a robot.",
      },
      home: {
        ruleCreate: "Rule Create",
        popupCreate: "Popup Message Create",
        teamsCreate: "Teams Content Create",
        ruleInfo:
          "Create awareness of your corporate policies by creating a new rule, and increase your compliance.",
        popupInfo:
          "Edit the Popup message contents that will be shown to users in case of violation of the rules you have created.",
        teamsInfo:
          "Edit Teams content to be sent to users for rule violations to improve and maintain awareness.",
      },
      ruleList: {
        editInfo: "Rule edited.",
        buttonName: "Customized Button",
        title: "Rule List",
        newRuleCreate: "New Rule Create",
        newRuleAdd: "Add New Rule",
        popupFor: "Popup Messages of the Rule",
        teamsFor: "Teams Contents of the Rule",
        keys: "Keywords of the Rule",
        filter: "Filter",
        clear: "Clear All",
        userGroup: "Affected User Groups",
        educationAddress: "Education Address",
        advisor: "Advisor Address",
        features: "Features",
        activityState: "Activity State",
        sort: "Sort by",
        sortCriteria: "Sort Criteria",
        new: "Newest Arrivals",
        old: "Oldest Arrivals",
        az: "A to Z",
        za: "Z to A",
        searchPlaceholder: "Type the word you want to search...",
        ruleEdit: "Rule Edit",
        ruleName: "Rule Name",
        regex: "Regex Words of the Rule",
        workers: "Employee Groups to which the Rule Will Apply",
        askAdvisor: "Activate the ask a advisor feature",
        advisorEmail: "Advisor E-mail Address",
        goEducation: "Activate the go to education",
        educationEmail: "Education Address",
        send: "Activate the send it anyway button",
        waitTime: "Waiting Time",
        reminderAmount: "Reminder Amount",
        reminderRange: "Reminder Range",
        unsuitableText: "Text Color of Inappropriate Words",
        unsuitableHighlight: "Highlight Color of Inappropriate Words",
        deletePopup: "rule will be deleted. Are you sure you want to continue?",
        deletePopupInfo: "Rule deleted.",
        newRulePopupInfo: "Created a new rule.",
        isAnonym: "Make Log Anonymous",
        logging: "Logging",
        tooLow: "too low",
        low: "low",
        medium: "medium",
        high: "high",
        tooHigh: "too high",
        ruleSeverityScore: "Rule Severity Score",
        categorySeverityScore: "Category Severity Score",
        ruleScoreDesc: "The value used to balance each rule score in the same category for the awareness scoring algorithm. Higher values have a higher impact.",
        categoryScoreDesc: "It is the value that determines the effect of each category on the awareness scoring algorithm. Higher values have a higher impact."
      },
      popup: {
        title: "Popup Messages List",
        newCreate: "Add New Popup Message",
        deletePopup:
          "popup message will be deleted. Are you sure you want to delete?",
        deletePopupInfo: "Popup message deleted.",
        edit: "Edit Popup Message",
        name: "Popup Message Name",
        content: "Content",
        newCreateInfo: "A new popup message has been created.",
        editInfo: "Popup Message edited.",
      },
      teams: {
        title: "Teams Content List",
        newCreate: "Add New Teams Content",
        deletePopup:
          "teams content will be deleted. Are you sure you want to delete?",
        deletePopupInfo: "Teams content deleted.",
        name: "Teams Content Name",
        edit: "Edit Teams Content",
        editInfo: "teams content edited.",
        newCreateInfo: "A new teams content has been created.",
      },
      ruleCategory: {
        title: "Rule Category List",
        newCreate: "Add New Rule Category",
        description: "Rule Description",
        deletePopup:
          "rule category will be deleted. Are you sure you want to delete?",
        deletePopup1: "The rule category you want to delete has",
        deletePopup2: "Are you sure you want to delete?",
        deletePopupInfo: "The Rule Category has been successfully deleted.",
        goTeams: "Go to Teams Contents",
        goPopup: "Go to Popup Messages",
        goRule: "Go to Rules",
        belong: "Belonging to the Rule Category,",
        deleteError: "There are one or more Rules, Teams Messages, or Popups that uses the category."
      },
      profile: {
        title: "Profile Info",
        usernameInfo: "User name edited.",
        oldPassword: "Old Password",
        newPassword: "New Password",
        newPasswordConfirm: "Confirm New Password",
        passwordInfo: "Password edited.",
        emailInfo: "E-mail edited.",
        phoneInfo: "Phone number edited.",
      },
      account: {
        title: "Account Info",
        date: "Account Effective Date:",
        userNumbers: "Number of Users:",
        allUsers: "All Users Connected to the Account",
        addUser: "Add New User",
        customerName: "Customer Name",
        deletePopup: "user will be deleted. Are you sure you want to delete?",
        deletePopupInfo: "User deleted.",
        userInfo: "User Info",
      },
      dashboard: {
        notificationTitle: "Dashboard Information",
        notificationDesc: "It is recommended that at least one month pass before the dashboard data and graphs are meaningful.",
        checked: "Num. of checked mail",
        capture: "Catched Words",
        previous: "Compared to the previous period ",
        impressions: "Impressions",
        popupImp: "Popup Impression",
        teamsImp: "Teams Content Impression",
        defineRule: "Defined Rules",
        totalScore: "Total Score",
        totalScoreDesc: "The total score obtained by the company in the selected period is shown.",
        scoreByCategory: "Category Based Score",
        sliderDesc: "The scores that the company has taken on the basis of 6 different categories in the selected period are shown.",
        summary: "Summary",
        summaryDesc: "A summary of the company's activities in the selected period is shown.",
        different: "different",
        aTotalof: "A total of",
        rules: "rules",
        mail: "mail",
        wereStuck: "Num. of caught mail",
        differentUser: "different user",
        stuckToTheRule: "Num. of different user",
        differentWords: "different words",
        wereCaptured: "Num. of captured unique words",
        differentRule: "Caught unique rules",
        differentPopup: "different popup",
        wereShown: "Num. of shown popup",
        lineChartTitle: "Category Based Awareness Score",
        lineChartDesc: "It shows the change of Awareness Score on the basis of the 5 most active categories in the selected period.",
        columChartTitle1: "Category Based Catched Rules",
        columChartDescr: "The most active 5 categories in the selected period are displayed on the basis of which rules and how much.",
        columnChartTitle2: "Category Based Catched Words",
        columnChartDesc2: "In the selected period, the most active 5 categories are displayed on which words and how much.",
        horizontalTitle: "Most Catched Words By Category"
      },
      error: {
        sory: "Sorry",
        notFound: "Page not found.",
        noData: "No data found.",
      },
      userGroups: {
        title: "User Groups",
        newCreate: "Add New User Group",
        member: "Group Members",
        deletePopup:
          "user group will be deleted. Are you sure you want to delete?",
        deletePopupInfo: "User group deleted.",
        editInfo: "user group edited.",
        addInfo: "User group added.",
        memberAdd: "Add Member",
        groupName: "Group Name",
        memberName: "Member Name",
        exclude: "Exclude User",
        excludePlaceholder: "Add user e-mail address"
      },
      log: {
        log: "System Logs",
        violatedRule: "Violated Rule",
        violatedWords: "Violated Words"
      }
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({ fallbackLng: "en", debug: true, resources });
