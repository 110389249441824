import React from "react";
import EditableInput from "./EditableInput";
import { useState } from "react";
import Popup from "./Popup";
import Input from "./Input";
import InfoPopup from "./InfoPopup";
import { useTranslation } from "react-i18next";
import userService from "../../Services/user.service";
import UserInfo from "../AccountInfo/UserInfo";
import UserInfoIcon from "../icons/UserInfoIcon";

function EditableUserInfo(props) {
  const { t } = useTranslation();
  const [editUsername, setEditUsername] = useState(false);
  const [infoUsername, setInfoUsername] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [infoPassword, setInfoPassword] = useState(false);
  const [editPhoneNumber, setEditPhoneNumber] = useState(false);
  const [infoPhoneNumber, setInfoPhoneNumber] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [infoEmail, setInfoEmail] = useState(false);

  const [username, setUsername] = useState(props.user.name || "");
  const [email, setEmail] = useState(props.user.email || "");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [errorUsername, setErrorUsername] = useState(null);
  const [error, setError] = useState(null);
  /*   const [addinType, setAddinType] = useState(0);
  const [addinTypeName, setAddinTypeName] = useState("");

  async function downloadAddin() {
    const res = await userService.downloadAddin(addinType);
    if (res.status === false) console.log("OK");
    else {
      const element = document.createElement("a");
      const file = new Blob([res.data], { type: "application/xml" });
      element.href = URL.createObjectURL(file);
      element.download = addinTypeName + ".xml";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  } */
  const handleSubmitupdateUserName = (event) => {
    event.preventDefault();
    updateUserName();
  };
  async function updateUserName() {
    if (username !== "") {
      const res = await userService.userUpdate(username, "", "", "", 0);
      if (res.status === false) setErrorUsername(res.message);
      else {
        setEditUsername(false);
        setInfoUsername(true);
        props.user.name = username;
        props.setUser(res.data);
        setUsername("");
      }
    } else {
      setErrorUsername(t("emptyError"));
    }
  }
  const handleSubmitUpdateEmail = (event) => {
    event.preventDefault();
    updateEmail();
  };

  async function updateEmail() {
    if (error === null || error === "") {
      const res = await userService.userUpdate("", email, "", "", 2);
      if (res.status === false) setError(res.message);
      else {
        setEditEmail(false);
        setInfoEmail(true);
        props.user.email = email;
        props.setUser(res.data);
        setEmail("");
      }
    } else {
      setError(t("emptyError"));
    }
  }

  const [errorPassword, setErrorPassword] = useState(null);
  const handleSubmitUpdatePassword = (event) => {
    event.preventDefault();
    updatePassword();
  };

  async function updatePassword() {
    if (newPassword === confirmedPassword) {
      if (newPassword.length >= 8 && newPassword.length <= 15) {
        const res = await userService.userUpdate(
          "",
          "",
          password,
          newPassword,
          1
        );
        if (res.status === false) setErrorPassword(res.message);
        else {
          setEditPassword(false);
          setInfoPassword(true);
          props.setUser(res.data);
          setConfirmedPassword("");
          setPassword("");
          setNewPassword("");
        }
      } else {
        setErrorPassword(t("passwordLongError"));
      }
    } else {
      setErrorPassword(t("sameError"));
    }
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError(t("emailInvalid"));
    } else {
      setError(null);
    }
    setEmail(event.target.value);
  };

  return (
    <div className="px-10 pt-10 w-full min-w-min h-full">
      <div className="relative bg-white w-full h-full flex flex-col rounded-t-[10px]">
        <div className=" h-[120px] w-full px-10 pt-10  ">
          <p className=" text-start text-[32px] leading-8 font-bold text-dark">
            {props.title}
          </p>
        </div>
        <div className="flex flex-col px-10 gap-[50px] max-w-[600px] ">
          <div className="w-full">
            <EditableInput
              labelName={t("username")}
              value={props.user.name}
              for="name"
              type="text"
              onClick={() => setEditUsername(true)}
            ></EditableInput>
          </div>
          <EditableInput
            labelName={t("password")}
            value={props.password}
            for="oldpassword"
            type="password"
            onClick={() => setEditPassword(true)}
          ></EditableInput>
          <EditableInput
            labelName={t("email")}
            value={props.user.email}
            for="email"
            type="email"
            onClick={() => setEditEmail(true)}
          ></EditableInput>
          {/*     <EditableInput
            onClick={() => setEditPhoneNumber(true)}
            labelName={t("phone")}
            value={props.phone}
            for="phoneNumber"
            type="text"
          ></EditableInput> */}
          {/*           <div>
            <label className="text-dark text-[16px] font-bold leading-4 ">
              Addin Type
            </label>
            <div>
            <select className="border rounded-[5px] border-darkGray py-2 w-[340px] mr-2" value={addinType} onChange={
              (e) => {
                setAddinType(e.target.value);
                setAddinTypeName(e.target.options[e.target.selectedIndex].text);
              }
            }>
              <option value="1">Test</option>
              <option value="2">PreProd</option>
              <option value="3">Prod</option>
            </select>
            <button className="text-green leading-5 font-bold border-green border-2 rounded-[5px] h-[46px] w-[170px]" onClick={downloadAddin}>Download</button>
            </div>
          </div> */}
        </div>
        <UserInfoIcon />
      </div>
      {editUsername && (
        <Popup
          visible={editUsername}
          methodOK={handleSubmitupdateUserName}
          method={() => {
            setEditUsername(false);
            setErrorUsername(null);
            setUsername("");
          }}
          buttonName={t("saveChanges")}
        >
          <div className="w-[615px]">
            <Input
              value={username}
              input={t("username")}
              for="editname"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            {errorUsername && <h2 className="text-red">{errorUsername}</h2>}
          </div>
        </Popup>
      )}
      <InfoPopup
        visible={infoUsername}
        close={() => {
          setInfoUsername(false);
        }}
        content={t("profile.usernameInfo")}
      />
      {editPassword && (
        <Popup
          visible={editPassword}
          methodOK={handleSubmitUpdatePassword}
          method={() => {
            setEditPassword(false);
            setErrorPassword(null);
            setPassword("");
            setNewPassword("");
            setConfirmedPassword("");
          }}
          buttonName={t("saveChanges")}
        >
          <div className="w-[615px] flex flex-col gap-[20px]">
            <Input
              type="password"
              icon="true"
              value={password}
              input={t("profile.oldPassword")}
              for="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              type="password"
              icon="true"
              value={newPassword}
              input={t("profile.newPassword")}
              for="new-password"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
            <Input
              type="password"
              icon="true"
              value={confirmedPassword}
              input={t("profile.newPasswordConfirm")}
              for="confirmed-password"
              onChange={(e) => {
                setConfirmedPassword(e.target.value);
              }}
            />
            {errorPassword && <h2 className="text-red">{errorPassword}</h2>}
          </div>
        </Popup>
      )}
      <InfoPopup
        visible={infoPassword}
        close={() => {
          setInfoPassword(false);
        }}
        content={t("profile.passwordInfo")}
      />
      {editEmail && (
        <Popup
          visible={editEmail}
          methodOK={handleSubmitUpdateEmail}
          method={() => {
            setEditEmail(false);
            setError(null);
            setEmail("");
          }}
          buttonName={t("saveChanges")}
        >
          <div className="w-[615px]">
            <Input
              type="email"
              value={email}
              input={t("email")}
              for="updateEmail"
              onChange={handleChange}
            />
            {error && <h2 className="text-red">{error}</h2>}
          </div>
        </Popup>
      )}
      <InfoPopup
        visible={infoEmail}
        close={() => {
          setInfoEmail(false);
        }}
        content={t("profile.emailInfo")}
      />
      {/*     <Popup
        visible={editPhoneNumber}
        methodOK={() => {
          setEditPhoneNumber(false);
          setInfoPhoneNumber(true);
        }}
        method={() => {
          setEditPhoneNumber(false);
        }}
        buttonName={t("saveChanges")}
      >
        <div className="w-[615px]">
          <Input
            value="get phone data"
            input={t("phone")}
            for="phoneNumber"
          ></Input>
        </div>
      </Popup>
      <InfoPopup
        visible={infoPhoneNumber}
        close={() => {
          setInfoPhoneNumber(false);
        }}
        content={t("profile.phoneInfo")}
      ></InfoPopup> */}
    </div>
  );
}

export default EditableUserInfo;
