import React from "react";
function IconButton(props) {
  return (
    <button
      onClick={props.method}
      className={
        "w-10 h-10 border-secondaryGray border-2 flex justify-center  items-center rounded-[3px]  shadow-[0px_2px_12px_rgba(149,_151,_155,_0.2)] " +
        (props.edit === true ? "bg-primaryOrange" : "") +
        (props.changeColor === true ? "bg-dark" : "")
      }
    >
      {props.children}
    </button>
  );
}

export default IconButton;
