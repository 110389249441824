import axios from "axios";
import Cookies from 'js-cookie';
const baseDir = process.env.REACT_APP_API_BASE_DIR + "/TeamsMessage";

class TeamsMessageService {
  async TeamsMessageGetAll(signal) {
    const userToken = Cookies.get("userToken");
    /*  const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/getall",
        {},
        {
          signal: signal,
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (err) {
      if (err.name === "AbortError") return;
      else {
        throw err;
      }
    }
  }
  async AddTeamsMessage(teamsName, categoryId, message) {
    const userToken = Cookies.get("userToken");
    /*    const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.post(
        baseDir + "/add",
        {
          name: String(teamsName),
          categoryId: categoryId,
          message: String(message),
          contentType: 0, //Look at
        },
        {
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }
  async UpdateTeamsMessage(
    teamsName,
    categoryId,
    message,
    teamsId,
    isActive,
    operationType
  ) {
    const userToken = Cookies.get("userToken");
    /*   const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.post(
        baseDir + "/update",
        {
          name: String(teamsName),
          categoryId: categoryId,
          message: String(message),
          contentType: 0,
          id: teamsId,
          isActive: isActive,
          operationType: operationType,
        },
        {
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }
  async GetByCategoryId(categoryId) {
    const userToken = Cookies.get("userToken");
    /*  const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/getbycategoryid",
        {},
        {
          headers: {
            usertoken: userToken,
          },
          params: {
            categoryId: categoryId,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export default new TeamsMessageService();
