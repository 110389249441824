import logo from "../../../images/logo.svg";
import logoWhite from "../../../images/logoWhite.svg";
import Dt_logo from "../../../images/DT_logo.svg";
import { NavLink } from "react-router-dom";
import UserService from "../../../Services/user.service";
import SidebarOption from "./SidebarOption";
import { useState } from "react";
import RuleListIcon from "../../icons/RuleListIcon";
import UserGroupIcon from "../../icons/UserGroupIcon";
import TeamsIcon from "../../icons/TeamsIcon";
import PopupIcon from "../../icons/PopupIcon";
import RuleCategoryIcon from "../../icons/RuleCategoryIcon";
import SupportIcon from "../../icons/SupportIcon";
import ProfileIcon from "../../icons/ProfileIcon";
import AccountIcon from "../../icons/AccountIcon";
import DashboardIcon from "../../icons/DashboardIcon";
import { useTranslation } from "react-i18next";
import Popup from "../Popup";
import Logout from "../../icons/Logout";
import LogIcon from "../../icons/LogIcon";

function Sidebar({ token }) {
  const { t, i18n } = useTranslation();

  const items = [
    {
      text: t("sidebar.ruleList"),
      path: `/rule-list`,
      active: false,
      badge: false,
      Icon: RuleListIcon,
    },
    {
      text: t("sidebar.popupMessages"),
      path: `/popup-message-list`,
      active: false,
      badge: false,
      Icon: PopupIcon,
    },
    {
      text: t("sidebar.teamsContents"),
      path: `/teams-contents`,
      active: false,
      badge: false,
      Icon: TeamsIcon,
    },
    {
      text: t("sidebar.ruleCategory"),
      path: `/rule-category`,
      active: false,
      badge: false,
      Icon: RuleCategoryIcon,
    },
    {
      text: t("sidebar.userGroups"),
      path: `/user-groups`,
      active: false,
      badge: false,
      Icon: UserGroupIcon,
    },
    {
      text: t("sidebar.dashboard"),
      path: `/dashboard`,
      active: false,
      badge: false,
      Icon: DashboardIcon,
    },
    {
      text: t("sidebar.profileInfo"),
      path: `/profile-info`,
      active: false,
      badge: false,
      Icon: ProfileIcon,
    },
    // {
    //   text: t("sidebar.accountInfo"),
    //   path: `/account-info`,
    //   active: false,
    //   badge: false,
    //   Icon: AccountIcon,
    // },
    {
      text: t("log.log"),
      path: `/log`,
      active: false,
      badge: false,
      Icon: LogIcon,
    },
    // {
    //   text: t("sidebar.support"),
    //   path: `/support`,
    //   active: false,
    //   badge: false,
    //   Icon: SupportIcon,
    // },
  ];
  const clickHandle = async (lang) => {
    await i18n.changeLanguage(lang);
  };
  const [popupVisible, setPopupVisible] = useState(false);

  function generateSidebarOption({ path, Icon, text, badge }, index) {
    return (
      <NavLink key={index} to={path} draggable="false">
        {({ isActive }) => (
          <SidebarOption
            Icon={Icon}
            text={text}
            active={isActive}
            badge={badge}
          />
        )}
      </NavLink>
    );
  }

  async function logout() {
    await UserService.logout();
    window.location.replace("/login");
  }

  return (
    <>
      <div className="bg-[linear-gradient(32.69deg,_rgba(248,_160,_133,_0.3)_54.93%,_rgba(229,_169,_151,_0.3)_64.65%,_rgba(188,_190,_192,_0.3)_97.8%)] min-h-screen min-w-[365px] max-w-[365px] w-full flex flex-col justify-between ">
        <div className="pl-10">
          <NavLink to="/home">
            <img
              src={logo}
              className="my-[60px] w-[200px] h-[34px]"
              alt="NotiSecureLogo"
            />
          </NavLink>
          <div className="flex flex-col items-start gap-[15px] text-base leading-4 font-semibold text-dark">
            <div className="flex flex-col gap-5 items-start flex-none  ">
              {items
                .slice(0, 5)
                .map((sidebarOption, index) =>
                  generateSidebarOption(sidebarOption, index)
                )}
            </div>
            <hr className=" w-[265px] bg-dark h-[2px] opacity-5 " />
            <div className="flex flex-col gap-5 items-start flex-none ">
              {items
                .slice(5, 9)
                .map((sidebarOption, index) =>
                  generateSidebarOption(sidebarOption, index)
                )}
              <NavLink
                onClick={() => {
                  setPopupVisible(true);
                }}
                className="flex flex-row gap-[15px] justify-start items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M15.35 29.3504C11.8094 29.3504 8.41373 27.9439 5.91012 25.4403C3.40651 22.9367 2 19.541 2 16.0004C2 12.4598 3.40651 9.06413 5.91012 6.56051C8.41373 4.0569 11.8094 2.65039 15.35 2.65039C15.59 2.65039 15.81 2.65039 16.05 2.65039C16.1734 2.65367 16.295 2.68124 16.4078 2.73151C16.5206 2.78178 16.6224 2.85378 16.7074 2.94339C16.7923 3.033 16.8588 3.13846 16.903 3.25377C16.9472 3.36907 16.9683 3.49195 16.965 3.61539C16.9617 3.73883 16.9342 3.86042 16.8839 3.97321C16.8336 4.086 16.7616 4.18778 16.672 4.27275C16.5824 4.35771 16.4769 4.4242 16.3616 4.4684C16.2463 4.51261 16.1234 4.53367 16 4.53039H15.4C13.1269 4.52049 10.9021 5.18561 9.00745 6.44146C7.11279 7.69731 5.63359 9.48735 4.75732 11.5847C3.88105 13.6821 3.64714 15.9924 4.08525 18.2229C4.52336 20.4534 5.61375 22.5036 7.21825 24.1137C8.82275 25.7238 10.8691 26.8213 13.098 27.2672C15.327 27.7131 17.6381 27.4873 19.7385 26.6184C21.839 25.7494 23.6342 24.2765 24.8966 22.3862C26.1591 20.496 26.832 18.2735 26.83 16.0004C26.83 15.8004 26.83 15.6004 26.83 15.4004C26.8135 15.1526 26.8957 14.9083 27.0587 14.7209C27.2218 14.5336 27.4523 14.4183 27.7 14.4004C27.9457 14.3986 28.1834 14.4873 28.3679 14.6497C28.5523 14.812 28.6705 15.0365 28.7 15.2804C28.7 15.5204 28.7 15.7504 28.7 15.9904C28.7 19.532 27.2938 22.9286 24.7905 25.4338C22.2872 27.939 18.8916 29.3477 15.35 29.3504Z"
                    fill="#2A3036"
                  />
                  <path
                    d="M29.0709 12.3602H10.7709C10.5396 12.3363 10.3254 12.2274 10.1697 12.0548C10.0139 11.8821 9.92773 11.6578 9.92773 11.4252C9.92773 11.1927 10.0139 10.9684 10.1697 10.7957C10.3254 10.623 10.5396 10.5142 10.7709 10.4902H29.0709C29.3022 10.5142 29.5164 10.623 29.6722 10.7957C29.8279 10.9684 29.9141 11.1927 29.9141 11.4252C29.9141 11.6578 29.8279 11.8821 29.6722 12.0548C29.5164 12.2274 29.3022 12.3363 29.0709 12.3602Z"
                    fill="#2A3036"
                  />
                </svg>
                <p>{t("sidebar.logout")}</p>
              </NavLink>
              <div className="flex flex-row gap-[15px] w-full pl-1">
                <button onClick={() => clickHandle("tr")}>TR</button>
                <button onClick={() => clickHandle("en")}>EN</button>
              </div>
            </div>
            <hr className=" w-[265px] bg-dark h-[2px] opacity-5 " />
          </div>
          <div className="flex flex-col py-[15px] gap-[15px] items-start text-darkGray font-medium underline leading-[18px] text-sm ">
            <a href="https://notisecure.com/Home/TermsAndConditions">{t("sidebar.termsConditions")}</a>
            <a href="https://notisecure.com/Home/Privacy">{t("sidebar.privacyPolicy")}</a>
            <a href="https://notisecure.com/Home/Cookie">{t("sidebar.cookiePolicy")}</a>
            <a href="https://notisecure.com/Home/GDPR">{t("sidebar.kvkk")}</a>
          </div>
        </div>
        <div className="flex justify-between items-center bg-dark opacity-20  h-[66px] px-[42px] ">
          <img
            src={Dt_logo}
            className="w-[89px] h-[25px] "
            alt="DogusTeknolojiLogo"
          />
          <div className="border-l-2 border-white opacity-10 h-[21px]" />
          <img
            src={logoWhite}
            className="w-[80px] h-[13px] "
            alt="NotiSecureLogo"
          />
        </div>
        <Popup
          method={() => {
            setPopupVisible(false);
          }}
          methodOK={logout}
          visible={popupVisible}
          content={t("logoutPopup")}
          buttonName={t("sidebar.logout")}
        >
          <Logout />
        </Popup>
      </div>
      {/* <div className="min-h-screen min-w-[365px] max-w-[365px]"></div> */}
    </>
  );
}

export default Sidebar;
