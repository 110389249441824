import React from "react";

function Button(props) {
  return (
    <button
      type={props.submit ? "submit" : "button"}
      className={
        "  h-[50px] rounded-[5px] order-1 text-center font-bold text-xl leading-5 " +
        (props.outline === true
          ? "bg-white border-green border-2 text-green"
          : "") +
        (props.green === true ? " bg-green text-white " : "") +
        (props.secondaryGreen === true
          ? " bg-secondaryGreen border-none text-green "
          : "") +
        (props.full === true ? " w-full" : " w-[300px]")
      }
      onClick={props.method}
    >
      {props.name}
    </button>
  );
}

export default Button;
