import axios from "axios";
import Cookies from 'js-cookie';
const baseDir = process.env.REACT_APP_API_BASE_DIR + "/User";

class UserService {
  async login(email, password) {
    try {
      const res = await axios.post(
        baseDir + "/login",
        {
          email: email,
          password: password,
        },
        { headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS', }, }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        Cookies.set("userToken", res.data.data.userToken, { expires: 3, sameSite: "None", secure: true });
        Cookies.set("user", res.data.data.user.id, { expires: 3, sameSite: "None", secure: true });
        /*   localStorage.setItem("userToken", res.data.data.userToken);
          localStorage.setItem("user", JSON.stringify(res.data.data.user)); */
        return { status: true, message: res.data.message };
      }
    } catch (e) {

      console.log(e);
    }
  }

  async signup(name, email, password, phoneNumber, tenantId, isActive) {
    try {
      const res = await axios.post(
        baseDir + "/signup",
        {
          name: name,
          email: email,
          password: password,
          phoneNumber: phoneNumber,
          tenantId: tenantId,
          isActive: isActive,
        },
        {}
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }

  async userUpdate(name, email, password, newPassword, operationType) {
    try {
      const userToken = Cookies.get("userToken");
      /*    const userToken = localStorage.getItem("userToken");*/
      const res = await axios.post(
        baseDir + "/update",
        {
          name: name,
          email: email,
          password: password,
          newPassword: newPassword,
          operationType: operationType,
        },
        {
          headers: {
            usertoken: userToken,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        Cookies.set("userToken", res.data.data.userToken, { expires: 3, sameSite: "None", secure: true });
        /*     localStorage.setItem("userToken", res.data.data.userToken);*/
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }

  async logout() {
    const userToken = Cookies.get("userToken");
    try {
      const res = await axios.post(
        baseDir + "/logout",
        {},
        {
          headers: {
            usertoken: userToken,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          },
        }
      );
      Cookies.remove("userToken");
      Cookies.remove("user");
      /*   localStorage.removeItem("userToken");
        localStorage.removeItem("user");*/
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getbyid(id) {

    const userToken = Cookies.get("userToken");
    /*     const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.post(
        baseDir + "/getbyid",
        {},
        {
          params: {
            id: id,
          }, headers: {
            usertoken: userToken,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
          }
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }

  async check(email, password) {
    try {
      const res = await axios.post(
        baseDir + "/check",
        {
          email: email,
          userToken: password,
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          },
        }
      );
      if (res === false) {
        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async checkReCaptcha(token) {
    try {
      const res = await axios.post(
        baseDir + "/recaptcha",
        {
          captchaValue: token
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          },
        }
      );
      if (res.success === false) {
        return false;
      } else {
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async checkUserToken() {
    const userToken = Cookies.get("userToken");
    const user = Cookies.get("user");

    const res = await this.getbyid(user);
    var email = null;
    if (res.status) {
      email = res.data.email;
    }
    else {

    }
    if (userToken === null || user === null || email === null) {
      return null;
    } else {
      try {
        const res = await axios.post(
          baseDir + "/check",
          {
            email: email,
            userToken: userToken,
          },
          { headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS', }, }
        );
        if (res.data === false) {
          Cookies.remove("userToken");
          Cookies.remove("user");
          return null;
        } else {
          return userToken;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  /*  async checkUserToken() {
     const userToken = localStorage.getItem("userToken");
     const user = JSON.parse(localStorage.getItem("user"));
 
     if (userToken === null || user === null) {
       return null;
     } else {
       try {
         const res = await axios.post(
           baseDir + "/check",
           {
             email: user.email,
             userToken: userToken,
           },
           {}
         );
         if (res.data == false) {
           localStorage.removeItem("userToken");
           localStorage.removeItem("user");
           return null;
         } else {
           return userToken;
         }
       } catch (e) {
         console.log(e);
       }
     }
   }
  */
  getCurrentUser() {
    return Cookies.get("userToken");
    /*     return localStorage.getItem("userToken");*/
  }

  async downloadAddin(addinType) {
    const userToken = Cookies.get("userToken");
    /* const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        process.env.REACT_APP_API_BASE_DIR + "/addin/getXml?env=" + addinType,
        {},
        {
          headers: {
            usertoken: userToken,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export default new UserService();
