import React from "react";

function EditableInput(props) {
  return (
    <div className="flex flex-col gap-[10px] w-full">
      <label
        htmlFor={props.for}
        className=" text-base font-bold leading-4 text-dark"
      >
        {props.labelName}
      </label>
      <label className="relative block">
        <input
          id={props.for}
          defaultValue={props.value}
          className="h-[50px] bg-white w-full rounded-[5px] border border-darkGray px-[15px] py-4"
          type={props.type}
          disabled
        />
        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
          <button onClick={props.onClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M15.0999 4.95717L17.0414 6.89776L15.0999 4.95717ZM16.3484 3.24759L11.0986 8.49734C10.8274 8.76822 10.6424 9.11333 10.5669 9.48918L10.082 11.9165L12.5094 11.4307C12.8852 11.3555 13.2299 11.1713 13.5012 10.8999L18.7509 5.65017C18.9087 5.49242 19.0338 5.30514 19.1192 5.09902C19.2046 4.8929 19.2485 4.67198 19.2485 4.44888C19.2485 4.22578 19.2046 4.00487 19.1192 3.79875C19.0338 3.59263 18.9087 3.40535 18.7509 3.24759C18.5932 3.08984 18.4059 2.9647 18.1998 2.87932C17.9937 2.79394 17.7728 2.75 17.5497 2.75C17.3266 2.75 17.1056 2.79394 16.8995 2.87932C16.6934 2.9647 16.5061 3.08984 16.3484 3.24759V3.24759Z"
                stroke="#95979B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.416 13.7497V16.4997C17.416 16.9859 17.2229 17.4522 16.879 17.796C16.5352 18.1398 16.0689 18.333 15.5827 18.333H5.49935C5.01312 18.333 4.5468 18.1398 4.20299 17.796C3.85917 17.4522 3.66602 16.9859 3.66602 16.4997V6.41634C3.66602 5.93011 3.85917 5.4638 4.20299 5.11998C4.5468 4.77616 5.01312 4.58301 5.49935 4.58301H8.24935"
                stroke="#95979B"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </span>
      </label>
    </div>
  );
}

export default EditableInput;
