import React from "react";

function PopupIcon({ active }) {
  const data = active ? filled : outline;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      {data}
    </svg>
  );
}

const outline = (
  <g>
    <path
      d="M27.2 27.8996L24.4 22.2996C25.1354 22.2996 25.8636 22.1548 26.543 21.8733C27.2225 21.5919 27.8398 21.1794 28.3598 20.6594C28.8798 20.1394 29.2923 19.5221 29.5737 18.8426C29.8552 18.1632 30 17.435 30 16.6996V9.69961C30 8.96421 29.8552 8.23601 29.5737 7.55658C29.2923 6.87716 28.8798 6.25982 28.3598 5.73981C27.8398 5.2198 27.2225 4.80731 26.543 4.52588C25.8636 4.24446 25.1354 4.09961 24.4 4.09961H7.6C6.11479 4.09961 4.69041 4.68961 3.6402 5.73981C2.59 6.79002 2 8.2144 2 9.69961V16.6996C2 18.1848 2.59 19.6092 3.6402 20.6594C4.69041 21.7096 6.11479 22.2996 7.6 22.2996H16L27.2 27.8996Z"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99961 14.5998C9.77281 14.5998 10.3996 13.973 10.3996 13.1998C10.3996 12.4266 9.77281 11.7998 8.99961 11.7998C8.22641 11.7998 7.59961 12.4266 7.59961 13.1998C7.59961 13.973 8.22641 14.5998 8.99961 14.5998Z"
      fill="#2A3036"
    />
    <path
      d="M15.9996 14.5998C16.7728 14.5998 17.3996 13.973 17.3996 13.1998C17.3996 12.4266 16.7728 11.7998 15.9996 11.7998C15.2264 11.7998 14.5996 12.4266 14.5996 13.1998C14.5996 13.973 15.2264 14.5998 15.9996 14.5998Z"
      fill="#2A3036"
    />
    <path
      d="M22.9996 14.5998C23.7728 14.5998 24.3996 13.973 24.3996 13.1998C24.3996 12.4266 23.7728 11.7998 22.9996 11.7998C22.2264 11.7998 21.5996 12.4266 21.5996 13.1998C21.5996 13.973 22.2264 14.5998 22.9996 14.5998Z"
      fill="#2A3036"
    />
  </g>
);

const filled = (
  <g>
    <path
      d="M27.2 27.8996L24.4 22.2996C25.1354 22.2996 25.8636 22.1548 26.543 21.8733C27.2225 21.5919 27.8398 21.1794 28.3598 20.6594C28.8798 20.1394 29.2923 19.5221 29.5737 18.8426C29.8552 18.1632 30 17.435 30 16.6996V9.69961C30 8.96421 29.8552 8.23601 29.5737 7.55658C29.2923 6.87716 28.8798 6.25982 28.3598 5.73981C27.8398 5.2198 27.2225 4.80731 26.543 4.52588C25.8636 4.24446 25.1354 4.09961 24.4 4.09961H7.6C6.11479 4.09961 4.69041 4.68961 3.6402 5.73981C2.59 6.79002 2 8.2144 2 9.69961V16.6996C2 18.1848 2.59 19.6092 3.6402 20.6594C4.69041 21.7096 6.11479 22.2996 7.6 22.2996H16L27.2 27.8996Z"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5C4.79086 5 3 6.79086 3 9V18C3 20.2091 4.79086 22 7 22H17L25.5 26.5L24.15 22H26C28.2091 22 30 20.2091 30 18V9C30 6.79086 28.2091 5 26 5H7ZM9.00001 14.6C9.7732 14.6 10.4 13.9732 10.4 13.2C10.4 12.4268 9.7732 11.8 9.00001 11.8C8.22681 11.8 7.60001 12.4268 7.60001 13.2C7.60001 13.9732 8.22681 14.6 9.00001 14.6ZM16 14.6C16.7732 14.6 17.4 13.9732 17.4 13.2C17.4 12.4268 16.7732 11.8 16 11.8C15.2268 11.8 14.6 12.4268 14.6 13.2C14.6 13.9732 15.2268 14.6 16 14.6ZM24.4 13.2C24.4 13.9732 23.7732 14.6 23 14.6C22.2268 14.6 21.6 13.9732 21.6 13.2C21.6 12.4268 22.2268 11.8 23 11.8C23.7732 11.8 24.4 12.4268 24.4 13.2Z"
      fill="#2A3036"
    />
  </g>
);

export default PopupIcon;
