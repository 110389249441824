import React from "react";

function ArrowIcon({ active }) {
  const data = active ? down : up;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
    >
      {data}
    </svg>
  );
}

const down = (
  <g>
    <path
      d="M0.534253 0.961987C0.71006 0.786233 0.948473 0.6875 1.19706 0.6875C1.44566 0.6875 1.68407 0.786233 1.85988 0.961987L6.5005 5.60261L11.1411 0.961987C11.3179 0.791214 11.5547 0.696719 11.8006 0.698855C12.0464 0.700991 12.2815 0.799587 12.4553 0.973407C12.6291 1.14723 12.7277 1.38236 12.7299 1.62817C12.732 1.87398 12.6375 2.1108 12.4667 2.28761L7.16331 7.59105C6.9875 7.7668 6.74909 7.86554 6.5005 7.86554C6.25191 7.86554 6.01349 7.7668 5.83769 7.59105L0.534253 2.28761C0.358499 2.1118 0.259766 1.87339 0.259766 1.6248C0.259766 1.37621 0.358499 1.13779 0.534253 0.961987Z"
      fill="#2A3036"
    />
  </g>
);

const up = (
  <g>
    <path
      d="M12.4657 7.03801C12.2899 7.21377 12.0515 7.3125 11.8029 7.3125C11.5543 7.3125 11.3159 7.21377 11.1401 7.03801L6.4995 2.39739L1.85888 7.03801C1.68207 7.20879 1.44525 7.30328 1.19944 7.30114C0.953635 7.29901 0.718498 7.20041 0.544679 7.02659C0.370859 6.85277 0.272264 6.61764 0.270128 6.37183C0.267992 6.12602 0.362487 5.8892 0.533259 5.71239L5.83669 0.40895C6.0125 0.233196 6.25091 0.134462 6.4995 0.134462C6.74809 0.134462 6.98651 0.233196 7.16231 0.40895L12.4657 5.71239C12.6415 5.8882 12.7402 6.12661 12.7402 6.3752C12.7402 6.62379 12.6415 6.86221 12.4657 7.03801Z"
      fill="#2A3036"
    />
  </g>
);

export default ArrowIcon;
