import React from "react";

function SearchIcon({ active }) {
  const data = active ? "white" : "#2A3036";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M11.8892 20.6089C13.7987 20.8278 15.7201 20.3405 17.2927 19.2428C17.2944 19.2445 17.296 19.2462 17.2977 19.2479L17.2977 19.2479L17.2996 19.2499L21.8452 23.7954C21.8452 23.7954 21.8452 23.7954 21.8452 23.7955C22.1041 24.0545 22.4552 24.2001 22.8215 24.2002C23.1877 24.2003 23.539 24.055 23.7981 23.7961C24.0571 23.5372 24.2027 23.186 24.2029 22.8198C24.203 22.4535 24.0576 22.1023 23.7988 21.8432L23.7987 21.8431L19.254 17.2984C19.2538 17.2983 19.2537 17.2981 19.2536 17.298C19.2507 17.2951 19.2479 17.2923 19.245 17.2895C20.3423 15.7159 20.8286 13.7935 20.6083 11.8836C20.3809 9.91213 19.4176 8.09923 17.911 6.80758C16.4043 5.51592 14.4656 4.84076 12.4825 4.91717C10.4995 4.99358 8.61845 5.81593 7.2157 7.21968C5.81295 8.62344 4.99195 10.5051 4.91696 12.4882C4.84197 14.4713 5.51851 16.4095 6.81125 17.9152C8.10398 19.4209 9.91756 20.383 11.8892 20.6089ZM19.0805 12.7835C19.0805 13.61 18.9177 14.4285 18.6014 15.192C18.2851 15.9556 17.8216 16.6495 17.2371 17.2339C16.6527 17.8183 15.9589 18.2819 15.1953 18.5982C14.4317 18.9145 13.6133 19.0773 12.7868 19.0773C11.9603 19.0773 11.1419 18.9145 10.3783 18.5982C9.6147 18.2819 8.92088 17.8183 8.33645 17.2339C7.75203 16.6495 7.28844 15.9556 6.97215 15.192C6.65586 14.4285 6.49307 13.61 6.49307 12.7835C6.49307 11.1143 7.15615 9.51351 8.33646 8.33321C9.51676 7.15291 11.1176 6.48982 12.7868 6.48982C14.456 6.48982 16.0568 7.15291 17.2371 8.33321C18.4174 9.51351 19.0805 11.1143 19.0805 12.7835Z"
        fill={data}
        stroke={data}
        strokeWidth="0.4"
      />
    </svg>
  );
}

export default SearchIcon;
