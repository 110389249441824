import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import ruleCategoryService from "../../Services/ruleCategory.service";
import LoadingIndicator from "../Global/LoadingIndicator";
export const series = [
  {
    data: [16, 18, 22],
  },
];

function HorizontalBarCard({ data, handleSelectCategory, isLoading }) {
  const { t } = useTranslation();
  const options = {
    chart: {
      type: "bar",
      height: "auto",
    },
    plotOptions: {
      bar: {
        barHeight: "40",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors: ["#7F7EFF", "#FAB338", "#549BF2", "#F94144", "#35CE8D", "#BFC1C3"],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      offsetX: 0,
    },
    xaxis: {
      categories: data == null ? [""] : data.keywordNames,
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex];
          },
        },
      },
    },
  };
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [categories, setCategories] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const [selectedCategoryForHorizontal, setSelectedCategoryForHorizontal] =
    useState("");
  const [ruleCategory, setRuleCategory] = useState();

  useEffect(() => {
    setIsLoadingCategories(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const fetchData = async () => {
      try {
        const response = await ruleCategoryService.RuleCategoryGetAll(signal);

        setIsLoadingCategories(false);
        if (response.status) {
          setCategories(response.data);
          params.set("category", response.data[0].id);
          const newUrl = `${window.location.pathname}?${params.toString()}`;
          window.history.pushState({}, "", newUrl);
          handleSelectCategory();
          setSelectedCategoryForHorizontal(response.data[0].name || "");
        } else {
          console.log(response.message);
        }
      } catch (err) {
        if (err.name === "AbortError" || err.code === "ERR_CANCELED") {
        } else {
          setIsLoadingCategories(false);
        }
      }
    };
    fetchData();
    return () => controller.abort();
  }, []);

  return (
    <div className=" overflow-hidden p-[30px] shadow-[0px_2px_12px_rgba(149,_151,_155,_0.5)] h-full rounded-[10px] flex flex-col gap-5">
      <div className="flex flex-row justify-between w-full">
        <span
          className="text-dark text-xl leading-5
       font-semibold"
        >
          {t("dashboard.horizontalTitle")}
        </span>
        {/*         <div className="relative flex flex-row gap-2">
          <IconButton
            changeColor={showFilterModal}
            method={() => {
              setShowFilterModal(!showFilterModal);
            }}
          >
            <FilterIcon active={showFilterModal} />
          </IconButton>

          {showFilterModal && (
            <DashboardFilterModal visible={showFilterModal}>
              {isLoadingCategories ? (
                <LoadingIndicator isPage={false} />
              ) : (
                categories.map((value, key) => (
                  <div
                    key={key}
                    onClick={() => {
                      setShowFilterModal(false);
                      const params = new URLSearchParams(
                        window.location.search
                      );
                      params.set("category", value.id);
                      const newUrl = `${
                        window.location.pathname
                      }?${params.toString()}`;
                      window.history.pushState({}, "", newUrl);
                      handleSelectCategory();
                      setSelectedCategoryForHorizontal(value.name || "");
                    }}
                    className="flex px-4 items-center h-9  border-b border-b-secondaryGray "
                  >
                    {value.name}
                  </div>
                ))
              )}
            </DashboardFilterModal>
          )}
        </div> */}
      </div>
      <div className="flex w-[28%] gap-[10px] pt-[2px] pb-[20px] border-b-dividerGray border-b-2">
        <select
          id="ruleCategory"
          value={ruleCategory}
          className=" border rounded-[5px] h-[50px] border-darkGray text-dark text-sm leading-[18px] block w-full px-[15px] "
          onChange={(e) => {
            setRuleCategory(e.target.value);
            const params = new URLSearchParams(window.location.search);
            params.set("category", e.target.value);
            const newUrl = `${window.location.pathname}?${params.toString()}`;
            window.history.pushState({}, "", newUrl);
            handleSelectCategory();
            //    setSelectedCategoryForHorizontal(value.name || "");
          }}
        >
          {categories.map((value, key) => (
            <option key={key} value={value.id}>
              {value.name}
            </option>
          ))}
        </select>
        {/*   <span className=" text-sm leading-[18px] font-semibold text-darkGray">
          {selectedCategoryForHorizontal}
        </span> */}
      </div>
      <div className=" w-full">
        {data == null ? (
          <div>
            <h1 className=" text-md text-center font-semibold text-red">
              {t("error.noData")}
            </h1>
          </div>
        ) : isLoading ? (
          <LoadingIndicator isPage={false} />
        ) : (
          <ReactApexChart
            options={options}
            series={data == null ? series : [data.keywordCounts]}
            type="bar"
            height={380}
          />
        )}
      </div>
    </div>
  );
}

export default HorizontalBarCard;
