import { useState } from "react";
import AddIcon from "../icons/AddIcon";
import { useTranslation } from "react-i18next";
import InfoPopup from "../Global/InfoPopup";

const MultipleDropdown = (props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(props.selected || null);
  const [showmore, setShowMore] = useState(false);

  const handleChange = (event) => {
    const value = JSON.parse(event.target.value);
    setSelected(value);

    if (props.array.some((m) => m.id === value.id) === false) {
      props.array.push(value);
    }
    event.target.value = JSON.stringify(value);
  };

  return (
    <>
      <div className="flex flex-col gap-[15px]">
        {props.keyword === true ? null : (
          <div className="flex flex-row justify-between">
            <label
              htmlFor={props.for}
              className="text-base font-bold leading-4 text-dark"
            >
              {props.title}
            </label>
            {props.add === true ? (
              <div onClick={props.addMethod}>
                <AddIcon></AddIcon>
              </div>
            ) : null}
          </div>
        )}
        {props.keyword === true ? (
          <div className="flex flex-col gap-[10px] w-full">
            <div className="flex flex-row justify-between">
              {" "}
              <label
                htmlFor={props.for}
                className=" text-base font-bold leading-4 text-dark"
              >
                {props.title}
              </label>
              {props.ruleKey && (
                <div className="flex flex-row h-8 gap-1">
                  <div className="flex w-[150px] justify-center">
                    <input
                      id="fileInput"
                      type="file"
                      accept=".xls, .xlsx"
                      ref={props.fileInputRef}
                      style={{ display: "none" }}
                      onChange={props.handleUpload}
                    />
                    <div className="group h-full relative">
                      <button
                        type="button"
                        className="flex bg-secondaryGreen border-none h-full items-center rounded-[5px]  text-center font-semibold w-[150px] justify-center text-green"
                        onClick={() => {
                          props.fileInputRef.current.click();
                        }}
                      >
                        <svg
                          className="fill-current stroke-current pr-1"
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 -960 960 960"
                          width="24"
                        >
                          <path d="M240-160q-33 0-56.5-23.5T160-240v-80q0-17 11.5-28.5T200-360q17 0 28.5 11.5T240-320v80h480v-80q0-17 11.5-28.5T760-360q17 0 28.5 11.5T800-320v80q0 33-23.5 56.5T720-160H240Zm200-486-75 75q-12 12-28.5 11.5T308-572q-11-12-11.5-28t11.5-28l144-144q6-6 13-8.5t15-2.5q8 0 15 2.5t13 8.5l144 144q12 12 11.5 28T652-572q-12 12-28.5 12.5T595-571l-75-75v286q0 17-11.5 28.5T480-320q-17 0-28.5-11.5T440-360v-286Z" />
                        </svg>
                        Import
                      </button>
                      <span class="absolute -top-[220%] right-0 scale-0 transition-all rounded bg-gray-800 bg-opacity-90 p-2 text-xs text-white group-hover:scale-100">
                        {t("import")}
                      </span>
                    </div>
                  </div>
                  <div className="group h-full relative">
                    <button
                      type="button"
                      className="flex w-[150px] h-full justify-center rounded-[5px] items-center text-center font-semibold bg-secondaryGreen border-none text-green"
                      onClick={props.handleExportClick}
                    >
                      <svg
                        className="fill-current stroke-current pr-1"
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path d="M480-337q-8 0-15-2.5t-13-8.5L308-492q-12-12-11.5-28t11.5-28q12-12 28.5-12.5T365-549l75 75v-286q0-17 11.5-28.5T480-800q17 0 28.5 11.5T520-760v286l75-75q12-12 28.5-11.5T652-548q11 12 11.5 28T652-492L508-348q-6 6-13 8.5t-15 2.5ZM240-160q-33 0-56.5-23.5T160-240v-80q0-17 11.5-28.5T200-360q17 0 28.5 11.5T240-320v80h480v-80q0-17 11.5-28.5T760-360q17 0 28.5 11.5T800-320v80q0 33-23.5 56.5T720-160H240Z" />
                      </svg>
                      {props.array?.length <= 0 || !props.array
                        ? "Template"
                        : "Export"}
                    </button>
                    <span
                      class={`absolute  ${
                        props.array?.length <= 0 || !props.array
                          ? "-top-[270%]"
                          : "-top-[220%]"
                      } right-0 scale-0 transition-all rounded bg-gray-800 bg-opacity-90 p-2 text-xs text-white group-hover:scale-100`}
                    >
                      {props.array?.length <= 0 || !props.array
                        ? t("template")
                        : t("export")}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <label className="relative block">
              <input
                type="text"
                id="ruleKeys"
                className=" border min-h-[50px] border-darkGray text-dark rounded-[5px] text-sm leading-[18px] font-semibold height-[50px] w-full px-4 py-[15px] "
                autoComplete="on"
                value={props.ruleKeyName}
                placeholder={props.placeholder}
                onChange={(e) => props.setRuleKeyName(e.target.value)}
                required={
                  props.exclude
                    ? false
                    : props.array.length === 0
                    ? true
                    : false
                }
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                <div onClick={props.addKeyword}>
                  <AddIcon />
                </div>
              </span>
            </label>
          </div>
        ) : (
          <select
            required={
              props.teams === true || props.array.length !== 0 ? false : true
            }
            className="border rounded-[5px] h-[50px] border-darkGray text-dark text-sm leading-[18px] block w-full px-[15px] "
            value={selected ? selected.value : ""}
            // onClick={props.options.length === 1 ? handleChange : null}
            // onChange={props.options.length !== 1 ? handleChange : null}
            onChange={handleChange}
          >
            <option value="" disabled>
              {props.options.length === 0 ? t("not") : t("pleaseChoose")}
            </option>
            {props.userGroup === true
              ? props.options.map((option) => (
                  <option key={option.id} value={JSON.stringify(option)}>
                    {option.displayName}
                  </option>
                ))
              : props.options.map((option) => (
                  <option key={option.id} value={JSON.stringify(option)}>
                    {option.name}
                  </option>
                ))}
          </select>
        )}
        <div
          className="flex flex-wrap content-center gap-x-5 gap-y-[15px] w-full " /* +
            (props.userGroup === true
              ? " grid-cols-3"
              : "  grid-cols-3 xxl:grid-cols-4 ")
          } */
        >
          {props.array.slice(0, 11).map((item, key) => (
            <div
              className={
                "flex text-dark  text-sm items-center p-[10.5px] leading-[18px] font-semibold h-[30px] rounded-[3px] w-[200px] text-center" +
                (props.secondaryGreen === true
                  ? "  underline bg-secondaryGreen"
                  : " bg-secondaryGray")
              }
              key={key}
            >
              <p className="w-full truncate">
                {props.userGroup === true ? item.displayName : item.name}
              </p>
              <div
                onClick={() => {
                  const index = props.array.indexOf(item);

                  props.setarray([
                    ...props.array.slice(0, index),
                    ...props.array.slice(index + 1),
                  ]);
                }}
                className="flex items-center pl-2 h-full justify-end cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    d="M6.05794 5.00014L9.28294 1.78264C9.42417 1.64141 9.50351 1.44987 9.50351 1.25014C9.50351 1.05041 9.42417 0.858867 9.28294 0.717639C9.14171 0.576411 8.95017 0.49707 8.75044 0.49707C8.55072 0.49707 8.35917 0.576411 8.21794 0.717639L5.00044 3.94264L1.78294 0.717639C1.64171 0.576411 1.45017 0.49707 1.25044 0.49707C1.05072 0.49707 0.85917 0.576411 0.717942 0.717639C0.576715 0.858867 0.497374 1.05041 0.497374 1.25014C0.497374 1.44987 0.576715 1.64141 0.717942 1.78264L3.94294 5.00014L0.717942 8.21764C0.647646 8.28736 0.591851 8.37031 0.553774 8.46171C0.515698 8.5531 0.496094 8.65113 0.496094 8.75014C0.496094 8.84915 0.515698 8.94718 0.553774 9.03857C0.591851 9.12997 0.647646 9.21292 0.717942 9.28264C0.787665 9.35293 0.870615 9.40873 0.96201 9.44681C1.0534 9.48489 1.15143 9.50449 1.25044 9.50449C1.34945 9.50449 1.44748 9.48489 1.53888 9.44681C1.63027 9.40873 1.71322 9.35293 1.78294 9.28264L5.00044 6.05764L8.21794 9.28264C8.28767 9.35293 8.37062 9.40873 8.46201 9.44681C8.5534 9.48489 8.65143 9.50449 8.75044 9.50449C8.84945 9.50449 8.94748 9.48489 9.03888 9.44681C9.13027 9.40873 9.21322 9.35293 9.28294 9.28264C9.35324 9.21292 9.40903 9.12997 9.44711 9.03857C9.48519 8.94718 9.50479 8.84915 9.50479 8.75014C9.50479 8.65113 9.48519 8.5531 9.44711 8.46171C9.40903 8.37031 9.35324 8.28736 9.28294 8.21764L6.05794 5.00014Z"
                    fill="#2A3036"
                  />
                </svg>
              </div>
            </div>
          ))}
          {props.array.length > 11 ? (
            <div
              onClick={() => setShowMore(true)}
              className=" cursor-pointer flex text-white bg-green text-sm items-center p-[10.5px] leading-[18px] font-semibold h-[30px] rounded-[3px] w-[200px] text-center"
            >
              <p className="w-full truncate">{t("showMore")}</p>
            </div>
          ) : null}
        </div>
      </div>
      <InfoPopup
        visible={showmore}
        more={true}
        moreTitle={props.moreTitle}
        close={() => setShowMore(false)}
      >
        {props.array.map((item, key) => (
          <div
            className={
              "flex text-dark  text-sm items-center p-[10.5px] leading-[18px] font-semibold h-[30px] rounded-[3px] w-[200px]  text-center" +
              (props.secondaryGreen === true
                ? "  underline bg-secondaryGreen"
                : " bg-secondaryGray")
            }
            key={key}
          >
            <p className="w-full truncate">
              {props.userGroup === true ? item.displayName : item.name}
            </p>
            <div
              onClick={() => {
                const index = props.array.indexOf(item);

                props.setarray([
                  ...props.array.slice(0, index),
                  ...props.array.slice(index + 1),
                ]);
              }}
              className="flex items-center pl-2 h-full justify-end cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  d="M6.05794 5.00014L9.28294 1.78264C9.42417 1.64141 9.50351 1.44987 9.50351 1.25014C9.50351 1.05041 9.42417 0.858867 9.28294 0.717639C9.14171 0.576411 8.95017 0.49707 8.75044 0.49707C8.55072 0.49707 8.35917 0.576411 8.21794 0.717639L5.00044 3.94264L1.78294 0.717639C1.64171 0.576411 1.45017 0.49707 1.25044 0.49707C1.05072 0.49707 0.85917 0.576411 0.717942 0.717639C0.576715 0.858867 0.497374 1.05041 0.497374 1.25014C0.497374 1.44987 0.576715 1.64141 0.717942 1.78264L3.94294 5.00014L0.717942 8.21764C0.647646 8.28736 0.591851 8.37031 0.553774 8.46171C0.515698 8.5531 0.496094 8.65113 0.496094 8.75014C0.496094 8.84915 0.515698 8.94718 0.553774 9.03857C0.591851 9.12997 0.647646 9.21292 0.717942 9.28264C0.787665 9.35293 0.870615 9.40873 0.96201 9.44681C1.0534 9.48489 1.15143 9.50449 1.25044 9.50449C1.34945 9.50449 1.44748 9.48489 1.53888 9.44681C1.63027 9.40873 1.71322 9.35293 1.78294 9.28264L5.00044 6.05764L8.21794 9.28264C8.28767 9.35293 8.37062 9.40873 8.46201 9.44681C8.5534 9.48489 8.65143 9.50449 8.75044 9.50449C8.84945 9.50449 8.94748 9.48489 9.03888 9.44681C9.13027 9.40873 9.21322 9.35293 9.28294 9.28264C9.35324 9.21292 9.40903 9.12997 9.44711 9.03857C9.48519 8.94718 9.50479 8.84915 9.50479 8.75014C9.50479 8.65113 9.48519 8.5531 9.44711 8.46171C9.40903 8.37031 9.35324 8.28736 9.28294 8.21764L6.05794 5.00014Z"
                  fill="#2A3036"
                />
              </svg>
            </div>
          </div>
        ))}
      </InfoPopup>
    </>
  );
};
export default MultipleDropdown;
