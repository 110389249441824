import axios from "axios";
import Cookies from 'js-cookie';
const baseDir = process.env.REACT_APP_API_BASE_DIR + "/UserGroup";

class UserGroupService {
  async UserGroupGetAll(signal) {
    const userToken = Cookies.get("userToken");
    /*    const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.get(baseDir + "/getall", {
        signal: signal,
        headers: {
          usertoken: userToken,
        },
      });
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (err) {
      if (err.name === "AbortError") return;
      else {
        throw err;
      }
    }
  }

  async AddUserGroup(groupName, groupMembers, excludedEmails) {
    const userToken = Cookies.get("userToken");
    /*    const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.post(
        baseDir + "/add",
        {
          name: String(groupName),
          groups: groupMembers,
          excludedEmails: excludedEmails,
        },
        {
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }

  async GetDomainDetailByTenant(signal) {
    const userToken = Cookies.get("userToken");
    /*  const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.get(baseDir + "/getdomaindetailbytenant", {
        signal: signal,
        headers: {
          usertoken: userToken,
        },
      });
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (err) {
      if (err.name === "AbortError") return;
      else {
        throw err;
      }
    }
  }

  async GetById(id) {
    const userToken = Cookies.get("userToken");
    /*    const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.get(baseDir + "/getbyid", {
        headers: {
          usertoken: userToken,
        },
        params: {
          id: id,
        },
      });
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }

  async UpdateGroup(id, name, group, excludedEmails, operationType) {
    const userToken = Cookies.get("userToken");
    /*    const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/update",
        {
          name: name,
          groups: group,
          excludedEmails: excludedEmails,
          id: parseInt(id),
          operationType: parseInt(operationType),
        },
        {
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }

  async SyncDomainGroup(domainId) {
    const userToken = Cookies.get("userToken");
    /*    const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.get(baseDir + "/syncdomaingroup?domainId=" + domainId, {
        headers: {
          usertoken: userToken,
        },
      });
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
      alert(e.message);
    }
  }
}

export default new UserGroupService();
