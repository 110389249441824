import React from "react";

function SortIcon({ active }) {
  const data = active ? "white" : "#2A3036";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M10.4255 19.6354C10.4255 19.4923 10.4817 19.3563 10.5796 19.2571C10.6774 19.158 10.8086 19.1036 10.944 19.1036H17.0551C17.1905 19.1036 17.3217 19.158 17.4195 19.2571C17.5175 19.3563 17.5736 19.4923 17.5736 19.6354C17.5736 19.7785 17.5175 19.9144 17.4195 20.0136C17.3217 20.1127 17.1905 20.1671 17.0551 20.1671H10.944C10.8086 20.1671 10.6774 20.1127 10.5796 20.0136C10.4817 19.9144 10.4255 19.7784 10.4255 19.6354ZM6.35142 13.4449C6.35142 13.3018 6.40759 13.1658 6.50556 13.0666C6.60331 12.9676 6.73451 12.9131 6.86994 12.9131H21.1292C21.2646 12.9131 21.3958 12.9676 21.4936 13.0666C21.5915 13.1658 21.6477 13.3018 21.6477 13.4449C21.6477 13.588 21.5915 13.7239 21.4936 13.8232C21.3958 13.9222 21.2646 13.9766 21.1292 13.9766H6.86994C6.73451 13.9766 6.60331 13.9222 6.50556 13.8232C6.40759 13.7239 6.35142 13.588 6.35142 13.4449ZM2.27734 7.2544C2.27734 7.11131 2.33352 6.97535 2.43149 6.87611C2.52924 6.77709 2.66044 6.72266 2.79586 6.72266H25.2033C25.3387 6.72266 25.4699 6.77709 25.5676 6.87611C25.6656 6.97535 25.7218 7.11131 25.7218 7.2544C25.7218 7.3975 25.6656 7.53345 25.5676 7.63269C25.4699 7.73171 25.3387 7.78615 25.2033 7.78615H2.79586C2.66044 7.78615 2.52924 7.73171 2.43149 7.63269C2.33352 7.53345 2.27734 7.3975 2.27734 7.2544Z"
        fill="#2A3036"
        stroke={data}
      />
    </svg>
  );
}

export default SortIcon;
