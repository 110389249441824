import React from "react";
import PopupMessage from "../Global/MessageForm";
import { useTranslation } from "react-i18next";
import Button from "../Global/Button";
import InfoPopup from "../Global/InfoPopup";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import popupMessageService from "../../Services/popupMessage.service";
import CustomToast from "../Global/CustomToast";
import LoadingIndicator from "../Global/LoadingIndicator";

function PopupMessageEdit(props) {
  const { t } = useTranslation();
  const [createdInfo, setCreatedInfo] = useState(false);
  const [popupName, setPopupName] = useState(props.popup.name || "");
  const [ruleCategory, setRuleCategory] = useState(props.popup.categoryId || 0);
  const [message, setMessage] = useState(props.popup.message || "");
  const [isUpdating, setIsUpdating] = useState(false);

  function toastError(label) {
    toast.error(label + t("toast"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const handleSubmitUpdatePopup = (event) => {
    event.preventDefault();
    props.popup.message = message;
    if (message === "") {
      toastError(t("popup.content"));
    } else {
      updatePopup();
    }
  };

  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  async function updatePopup() {
    setIsUpdating(true);
    const popupId = props.popup.id;
    const res = await popupMessageService.UpdatePopupMessage(
      popupName,
      ruleCategory,
      message,
      props.popup.isActive,
      popupId,
      0
    );
    setIsUpdating(false);
    if (res.status === false) toastErrorFalse();
    else {
      const arrayupdated = props.popups.map((item) =>
        item.id === props.popup.id ? { ...item, ...res.data } : item
      );
      props.setPopups(arrayupdated);
      setCreatedInfo(true);
    }
  }

  const createdPopupMessage = () => {
    setCreatedInfo(false);
    props.state(false);
  };

  if (props.visible === false) return null;
  return (
    <>
      <div className="fixed inset-0 z-10  overflow-y-auto">
        <CustomToast />
        <div className="fixed inset-0 w-full h-full bg-dark opacity-70"></div>
        <div className="flex items-center px-4 py-8">
          {isUpdating && <LoadingIndicator />}
          <div className="z-10 w-full max-w-[1015px] mx-auto min-w-min h-auto rounded-[10px] bg-white">
            <div className=" h-[120px] w-full p-10">
              <p className="text-dark text-[32px] font-bold leading-8">
                {props.title}
              </p>
            </div>
            <form
              className="no-scrollbar overflow-y-auto flex flex-col gap-[40px] pb-[40px]"
              onSubmit={handleSubmitUpdatePopup}
            >
              <PopupMessage
                onChange={(e) => setPopupName(e.target.value)}
                ruleCategoryOnChange={(e) => setRuleCategory(e.target.value)}
                setData={setMessage}
                input={t("popup.name")}
                for="popupName"
                value={popupName}
                data={message}
                ruleCategory={ruleCategory}
              />
              <div className="flex flex-row items-center justify-center gap-[15px]">
                <Button
                  method={props.close}
                  outline={true}
                  name={t("cancel")}
                />
                {/*     <Button secondaryGreen={true} name={t("draft")} /> */}
                <Button submit={true} green={true} name={t("save")} />
              </div>
            </form>
          </div>
        </div>
      </div>
      {createdInfo && (
        <InfoPopup
          visible={createdInfo}
          close={createdPopupMessage}
          content={"“" + popupName + "” " + t("popup.editInfo")}
        />
      )}
    </>
  );
}

export default PopupMessageEdit;
