import React from "react";

function CategoryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M7.56784 17.6504C8.01627 17.6542 8.45074 17.4945 8.78988 17.201L8.7899 17.2011L8.79139 17.1997L15.8601 10.831L15.8602 10.831L15.8619 10.8293C17.0014 9.75202 17.6749 8.27217 17.7388 6.70536C17.8027 5.13855 17.2519 3.60877 16.2039 2.44225C15.156 1.27574 13.6938 0.564794 12.1291 0.461037C10.5644 0.357281 9.0211 0.868922 7.82829 1.88685L7.82827 1.88683L7.82638 1.88853L0.757625 8.23853L0.757514 8.23863C0.396172 8.5642 0.178652 9.01976 0.152633 9.50544C0.126615 9.99113 0.294219 10.4673 0.61871 10.8296L0.618814 10.8297L6.20006 17.0422L6.20013 17.0423C6.37273 17.234 6.58376 17.3873 6.81949 17.4921C7.05507 17.5968 7.31004 17.6507 7.56784 17.6504ZM7.56784 17.6504C7.56767 17.6504 7.5675 17.6504 7.56732 17.6504L7.5682 17.5504L7.56834 17.6504C7.56818 17.6504 7.56801 17.6504 7.56784 17.6504ZM11.8368 1.71917H11.837C12.7761 1.71907 13.6933 2.00374 14.4673 2.53562C15.2414 3.06749 15.8359 3.82157 16.1726 4.69832C16.5092 5.57508 16.5721 6.53331 16.353 7.44655C16.1338 8.35979 15.6429 9.18512 14.945 9.81361L7.858 16.1632C7.85798 16.1632 7.85796 16.1632 7.85795 16.1632C7.76553 16.2454 7.64438 16.2877 7.5209 16.281C7.39749 16.2742 7.28175 16.219 7.19885 16.1273C7.19879 16.1273 7.19872 16.1272 7.19866 16.1271L1.63645 9.91496L1.63646 9.91495L1.63529 9.91368C1.59418 9.86933 1.56256 9.81705 1.54239 9.76003C1.52221 9.70302 1.51391 9.64249 1.518 9.58215L1.5181 9.58005C1.52095 9.51854 1.53605 9.45822 1.56253 9.40263C1.589 9.34703 1.62631 9.29728 1.67226 9.2563L1.67253 9.25606L8.74128 2.90606L8.74142 2.90594C9.59049 2.14042 10.6936 1.71749 11.8368 1.71917Z"
        fill="#1E5C2F"
        stroke="#1E5C2F"
        strokeWidth="0.2"
      />
    </svg>
  );
}

export default CategoryIcon;
