import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "../Global/IconButton";
import Switch from "react-ios-switch";
import Popup from "../Global/Popup";
import RuleCategoryEdit from "./RuleCategoryEdit";
import InfoPopup from "../Global/InfoPopup";
import { useTranslation } from "react-i18next";
import ruleCategoryService from "../../Services/ruleCategory.service";
import DeleteIcon from "../icons/DeleteIcon";
import EditIcon from "../icons/EditIcon";
import PopupDelete from "../icons/PopupDelete";
import CustomToast from "../Global/CustomToast";
import { toast } from "react-toastify";
import LoadingIndicator from "../Global/LoadingIndicator";

function RuleCategoryCard(props) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(props.checked);
  const [deletedPopup, setDeletedPopup] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [infoDeletedPopup, setInfoDeletedPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function toastError(label) {
    toast.error(label, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  async function deleteCategory() {
    setDeletedPopup(false);
    setIsLoading(true);
    const res = await ruleCategoryService.UpdateRuleCategory(
      props.ruleCategory.name,
      props.ruleCategory.description,
      props.ruleCategory.isActive,
      props.ruleCategory.isLogActive,
      props.ruleCategory.severityScore,
      props.ruleCategory.id,
      1
    );
    setIsLoading(false);
    if (res.status === false) toastError(t("ruleCategory.deleteError"));
    else {
      const index = props.categories.indexOf(props.ruleCategory);
      props.setCategories([
        ...props.categories.slice(0, index),
        ...props.categories.slice(index + 1),
      ]);
      setInfoDeletedPopup(true);
      sessionStorage.setItem(
        "RuleCategoryAll",
        JSON.stringify([
          ...props.categories.slice(0, index),
          ...props.categories.slice(index + 1),
        ])
      );
    }
  }

  async function changeIsActive() {
    setIsLoading(true);
    const res = await ruleCategoryService.UpdateRuleCategory(
      props.ruleCategory.name,
      props.ruleCategory.description,
      !props.ruleCategory.isActive,
      props.ruleCategory.isLogActive,
      props.ruleCategory.severityScore,
      props.ruleCategory.id,
      0
    );
    if(res.status){
      props.ruleCategory.isActive = !props.ruleCategory.isActive;
      setChecked(!checked)
    } else {
      toastError(t("errorIsActive"));
    }
    setIsLoading(false);
  }

  const navigate = useNavigate();

  return (
    <>
      <CustomToast />
      <div className="relative h-min min-w-[550px] w-full shadow-[0px_2px_12px_rgba(149,_151,_155,_0.2)] p-[30px] rounded-[10px] flex flex-col gap-5 ">
        {isLoading && <LoadingIndicator />}
        <p className=" font-bold text-dark text-[22px] leading-[22px]">
          {props.title}
        </p>
        <div className="flex lg:flex-row flex-col justify-between">
          <div className="flex flex-col ">
            {props.children}
            <div className="flex flex-col gap-[15px] max-w-[770px]">
              <p className="text-dark font-semibold text-sm leading-[18px]"></p>
              <div className="flex flex-row gap-[5px]  items-center h-[35px]">
                <p className=" font-bold text-dark text-base leading-[16px]">
                  {t("ruleCategory.belong")}
                </p>
                <div className="flex gap-5 underline text-green font-semibold text-base leading-4">
                  <div
                    className=" cursor-pointer"
                    onClick={() => {
                      navigate("/rule-list?category=" + props.ruleCategory.id);
                    }}
                  >
                    {t("ruleCategory.goRule")}
                  </div>
                  <div
                    className=" cursor-pointer"
                    onClick={() => {
                      navigate(
                        "/teams-contents?category=" + props.ruleCategory.id
                      );
                    }}
                  >
                    {t("ruleCategory.goTeams")}
                  </div>
                  <div
                    className=" cursor-pointer"
                    onClick={() => {
                      navigate(
                        "/popup-message-list?category=" + props.ruleCategory.id
                      );
                    }}
                  >
                    {t("ruleCategory.goPopup")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[20px] items-end ">
            <Switch
              checked={checked}
              handleColor="white"
              name={undefined}
              offColor="black"
              onChange={() => {
                changeIsActive();
              }}
              onColor="green"
            />
            <div className="flex flex-row gap-[15px] ">
              <IconButton
                className="bg-black"
                method={() => setDeletedPopup(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton edit={true} method={() => setEditModal(true)}>
                <EditIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      {deletedPopup && (
        <Popup
          submit={false}
          buttonName={t("delete")}
          method={() => setDeletedPopup(false)}
          methodOK={deleteCategory} // add rule card deleted method
          visible={deletedPopup}
          content={
            <span>
              {props.ruleCategory.name + " " + t("ruleCategory.deletePopup")}
            </span>
          }
        >
          <PopupDelete></PopupDelete>
        </Popup>
      )}
      {infoDeletedPopup && (
        <InfoPopup
          visible={infoDeletedPopup}
          close={() => setInfoDeletedPopup(false)}
          content={t("ruleCategory.deletePopupInfo")}
        />
      )}
      {editModal && (
        <RuleCategoryEdit
          ruleCategory={props.ruleCategory}
          categories={props.categories}
          setCategories={props.setCategories}
          setState={setEditModal}
          close={() => setEditModal(false)}
          visible={editModal}
        />
      )}
    </>
  );
}

export default RuleCategoryCard;
