import React from "react";

function DashboardIcon({ active }) {
  const data = active ? filled : outline;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      {data}
    </svg>
  );
}

const outline = (
  <g>
    <path
      d="M8.68751 18.625H6.81245C5.26134 18.625 4 17.363 4 15.8126V6.81245C4 5.26203 5.26157 4 6.81245 4H8.68751C10.2379 4 11.5 5.26203 11.5 6.81245V15.8126C11.5 17.363 10.2379 18.625 8.68751 18.625ZM6.81245 5.87523C6.29534 5.87523 5.87506 6.29576 5.87506 6.81262V15.8127C5.87506 16.3298 6.29559 16.7501 6.81245 16.7501H8.68751C9.20461 16.7501 9.62489 16.3296 9.62489 15.8127V6.81262C9.62489 6.29551 9.20436 5.87523 8.68751 5.87523H6.81245Z"
      fill="#2A3036"
    />
    <path
      d="M15.8125 28H6.81245C5.26134 28 4 26.7379 4 25.1875V23.3124C4 21.7613 5.26157 20.5 6.81245 20.5H15.8125C17.363 20.5 18.625 21.7616 18.625 23.3124V25.1875C18.625 26.7379 17.363 28 15.8125 28ZM6.81245 22.3751C6.29534 22.3751 5.87506 22.7956 5.87506 23.3124V25.1875C5.87506 25.7046 6.29559 26.1249 6.81245 26.1249H15.8125C16.3297 26.1249 16.7499 25.7044 16.7499 25.1875V23.3124C16.7499 22.7953 16.3294 22.3751 15.8125 22.3751H6.81245Z"
      fill="#2A3036"
    />
    <path
      d="M25.1875 28H23.3124C21.7613 28 20.5 26.7379 20.5 25.1875V23.3124C20.5 21.7613 21.7616 20.5 23.3124 20.5H25.1875C26.7379 20.5 28 21.7616 28 23.3124V25.1875C28 26.7379 26.7379 28 25.1875 28ZM23.3124 22.3751C22.7953 22.3751 22.3751 22.7956 22.3751 23.3124V25.1875C22.3751 25.7046 22.7956 26.1249 23.3124 26.1249H25.1875C25.7046 26.1249 26.1249 25.7044 26.1249 25.1875V23.3124C26.1249 22.7953 25.7044 22.3751 25.1875 22.3751H23.3124Z"
      fill="#2A3036"
    />
    <path
      d="M25.1875 18.625H16.1874C14.637 18.625 13.375 17.363 13.375 15.8126V6.81245C13.375 5.26203 14.6366 4 16.1874 4H25.1875C26.738 4 28 5.26203 28 6.81245V15.8126C28 17.363 26.738 18.625 25.1875 18.625ZM16.1874 5.87523C15.6703 5.87523 15.2501 6.29576 15.2501 6.81262V15.8127C15.2501 16.3296 15.6706 16.7501 16.1874 16.7501H25.1875C25.7047 16.7501 26.1249 16.3296 26.1249 15.8127V6.81262C26.1249 6.29551 25.7044 5.87523 25.1875 5.87523H16.1874Z"
      fill="#2A3036"
    />
  </g>
);

const filled = (
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.81245 18.625H8.68751C10.2379 18.625 11.5 17.363 11.5 15.8126V6.81245C11.5 5.26203 10.2379 4 8.68751 4H6.81245C5.26157 4 4 5.26203 4 6.81245V15.8126C4 17.363 5.26134 18.625 6.81245 18.625ZM9.23478 16.5734C9.27437 16.5449 9.31165 16.5133 9.34628 16.479C9.31029 16.5118 9.2731 16.5433 9.23478 16.5734Z"
      fill="#2A3036"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1874 18.625H25.1875C26.738 18.625 28 17.363 28 15.8126V6.81245C28 5.26203 26.738 4 25.1875 4H16.1874C14.6366 4 13.375 5.26203 13.375 6.81245V15.8126C13.375 17.363 14.637 18.625 16.1874 18.625Z"
      fill="#2A3036"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.81245 28H15.8125C17.363 28 18.625 26.7379 18.625 25.1875V23.3124C18.625 21.7616 17.363 20.5 15.8125 20.5H6.81245C5.26157 20.5 4 21.7613 4 23.3124V25.1875C4 26.7379 5.26134 28 6.81245 28ZM16.5717 22.763C16.544 22.7249 16.5135 22.6889 16.4805 22.6554C16.5122 22.6902 16.5426 22.7261 16.5717 22.763Z"
      fill="#2A3036"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3124 28H25.1875C26.7379 28 28 26.7379 28 25.1875V23.3124C28 21.7616 26.7379 20.5 25.1875 20.5H23.3124C21.7616 20.5 20.5 21.7613 20.5 23.3124V25.1875C20.5 26.7379 21.7613 28 23.3124 28Z"
      fill="#2A3036"
    />
  </g>
);

export default DashboardIcon;
