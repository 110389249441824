import Header from "../components/Global/Header";
import { useNavigate } from "react-router-dom";
import TeamsMessageCard from "../components/TeamsMessageList/TeamsMessageCard";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";
import teamsMessageService from "../Services/teamsMessage.service";
import { toast } from "react-toastify";
import LoadingIndicator from "../components/Global/LoadingIndicator";
import CustomToast from "../components/Global/CustomToast";
import axios from "axios";
const CancelToken = axios.CancelToken;
let cancel;
function TeamsMessagesList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [teams, setTeams] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [tetik, setTetik] = useState(false);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [isLoading, setIsLoading] = useState(false);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = teams.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else setFilteredResults(teams);
  };
  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  useEffect(() => {
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const fetchData = async () => {
      try {
        const response = await teamsMessageService.TeamsMessageGetAll(signal);
        setIsLoading(false);
        if (response.status) setTeams(response.data);
        else toastErrorFalse();
      } catch (err) {
        if (err.name === "AbortError" || err.code === "ERR_CANCELED") {
        } else {
          setIsLoading(false);
          toastErrorFalse();
        }
      }
    };
    fetchData();
    return () => controller.abort();
  }, []);

  return (
    <div className="px-10 pt-10 w-full min-w-min h-full">
      <CustomToast />
      <div className="bg-white w-full h-full rounded-t-[10px]">
        <Header
          force={forceUpdate}
          array={teams}
          teams={true}
          setArray={setTeams}
          searchOnChange={(e) => searchItems(e.target.value)}
          title={t("teams.title")}
          navigate={() => {
            navigate("/teams-contents/add");
          }}
          buttonName={t("teams.newCreate")}
        ></Header>
        <div className="py-2 px-10 overflow-y-scroll h-screen no-scrollbar flex flex-col gap-5">
          {searchInput.length > 0
            ? filteredResults.map((value, key) => (
                <TeamsMessageCard
                  key={key}
                  teams={value}
                  teamsArray={teams}
                  setTeamsArray={setTeams}
                  title={value.name}
                  ruleCategory={value.category}
                >
                  {value.message}
                </TeamsMessageCard>
              ))
            : teams.map((value, key) => (
                <TeamsMessageCard
                  key={key}
                  tetik={tetik}
                  setTetik={setTetik}
                  teams={value}
                  teamsArray={teams}
                  setTeamsArray={setTeams}
                  title={value.name}
                  ruleCategory={value.category}
                >
                  {value.message}
                </TeamsMessageCard>
              ))}
          {isLoading && <LoadingIndicator isPage={false} />}
        </div>
      </div>
    </div>
  );
}

export default TeamsMessagesList;
