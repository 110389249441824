import React from "react";

function UserInfoIcon() {
  return (
    <div className="hidden xxl:flex absolute -right-[0px] bottom-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="341"
        height="395"
        viewBox="0 0 341 395"
        fill="none"
      >
        <path
          d="M275.303 53.4277C274.298 57.141 275.453 62.9879 275.889 66.4541C276.354 70.1598 269.914 73.4763 266.229 73.6859C266.32 72.4593 265.974 71.24 265.253 70.2422C265.245 71.4288 265 72.602 264.533 73.6934H233.782C229.909 66.4241 226.614 56.4896 230.172 46.2183C230.352 44.1554 231.139 42.1916 232.434 40.5734C233.73 38.9551 235.475 37.7545 237.452 37.1223L254.22 31.7695C260.832 31.7695 268.946 32.7053 272.601 37.6164C274.219 39.8775 275.303 42.4746 275.771 45.2129C276.239 47.9513 276.079 50.7598 275.303 53.4277Z"
          fill="#2F2E41"
        />
        <path
          d="M304.746 374.656C305.294 374.676 305.835 374.528 306.296 374.231C306.756 373.934 307.114 373.503 307.321 372.996C307.528 372.489 307.573 371.931 307.452 371.398C307.33 370.864 307.047 370.381 306.641 370.014C306.571 369.747 306.52 369.556 306.45 369.289C306.962 367.955 307.861 366.805 309.033 365.984C310.204 365.164 311.595 364.711 313.026 364.684C314.457 364.657 315.864 365.057 317.066 365.832C318.268 366.608 319.21 367.724 319.772 369.037C323.013 376.408 327.189 384.031 325.226 392.202C338.431 363.583 333.51 328.187 313.359 304.012C307.825 301.039 303.311 295.707 302.09 289.478C304.823 290.372 308.199 288.13 306.123 285.286C305.225 284.201 304.318 283.124 303.42 282.038C313.665 270.945 326.162 288.108 314.911 303.055C318.683 307.68 321.959 312.686 324.687 317.991C324.014 312.337 324.698 306.605 326.68 301.265C328.693 296.288 332.515 292.075 335.876 287.757C339.961 282.495 348.495 284.823 349.115 291.446C345.31 292.733 344.867 298.236 349.24 298.947C348.214 311.288 340.246 322.695 329.176 328.208C333.393 339.664 335.113 351.885 334.223 364.057C336.051 349.824 351.314 346.23 361.952 340.479C362.629 340.037 363.419 339.801 364.228 339.8C365.036 339.799 365.827 340.032 366.505 340.472C367.183 340.912 367.718 341.538 368.044 342.276C368.371 343.014 368.475 343.83 368.344 344.626C366.874 345.239 365.469 345.995 364.149 346.885C363.53 347.308 363.058 347.912 362.797 348.613C362.536 349.315 362.499 350.079 362.691 350.803C362.883 351.526 363.295 352.172 363.869 352.653C364.444 353.134 365.154 353.426 365.901 353.489C360.639 366.844 346.79 375.91 332.479 375.996C331.161 381.969 329.224 387.79 326.699 393.365L305.459 393.567C305.381 393.332 305.31 393.09 305.239 392.855C307.206 392.959 309.179 392.823 311.113 392.45C305.853 386.357 301.8 383.261 304.745 374.657L304.746 374.656Z"
          fill="#F2F2F2"
        />
        <path
          d="M260.909 378.917H252.624L248.682 347.044H260.909V378.917Z"
          fill="#FFB6B6"
        />
        <path
          d="M265.279 393.233H259.338L258.277 387.639L255.561 393.233H239.804C239.049 393.233 238.313 392.993 237.705 392.546C237.096 392.1 236.647 391.471 236.422 390.752C236.197 390.033 236.209 389.26 236.455 388.548C236.702 387.836 237.17 387.221 237.791 386.793L250.375 378.125V372.469L263.61 373.257L265.279 393.233Z"
          fill="#2F2E41"
        />
        <path
          d="M273.912 321.189L268.095 327.073L242.572 307.497L251.157 298.812L273.912 321.189Z"
          fill="#FFB6B6"
        />
        <path
          d="M287.205 328.134L283.034 332.353L278.295 329.179L280.382 335.036L269.319 346.228C268.789 346.764 268.101 347.118 267.355 347.237C266.609 347.355 265.845 347.233 265.173 346.888C264.502 346.542 263.959 345.992 263.623 345.317C263.288 344.642 263.178 343.878 263.308 343.136L265.954 328.113L261.916 324.142L271.771 315.294L287.205 328.134Z"
          fill="#2F2E41"
        />
        <path
          d="M207.642 148.759L232.701 108.992C233.758 107.304 234.107 105.27 233.673 103.327C233.239 101.385 232.056 99.6909 230.381 98.6104C229.514 98.0515 228.542 97.6744 227.525 97.5023C226.507 97.3301 225.465 97.3665 224.462 97.6091C223.459 97.8518 222.516 98.2956 221.691 98.9137C220.865 99.5317 220.175 100.311 219.661 101.204L194.901 143.308L178.774 168.259C177.432 168.025 176.051 168.209 174.818 168.786C173.584 169.363 172.559 170.304 171.881 171.482C171.203 172.66 170.904 174.018 171.027 175.371C171.149 176.724 171.686 178.006 172.565 179.044C173.444 180.082 174.621 180.825 175.938 181.173C177.255 181.52 178.647 181.454 179.925 180.985C181.203 180.515 182.305 179.665 183.082 178.549C183.858 177.433 184.272 176.105 184.266 174.747L207.642 148.759Z"
          fill="#FFB6B6"
        />
        <path
          d="M231.259 119.392L233.372 118.994C233.087 118.477 232.969 117.885 233.031 117.299C233.094 116.712 233.336 116.159 233.723 115.714C234.111 115.268 234.626 114.952 235.2 114.807C235.773 114.662 236.377 114.696 236.93 114.904C234.385 112.256 235.621 107.896 237.258 104.611C238.442 102.237 239.772 99.8905 240.352 97.3024C241.231 93.3735 240.097 88.6181 234.844 87.6773C232.696 87.3422 230.499 87.7859 228.651 88.928C225.349 90.8739 224.607 90.0268 222.524 93.1591L221.337 95.3598C219.812 97.7424 217.992 99.9237 215.921 101.852C214.439 103.324 213.245 105.058 212.4 106.965C212.289 107.201 212.124 107.408 211.92 107.57C211.107 108.197 210.003 108.228 208.985 108.362C207.701 108.53 206.233 109.072 206.266 110.66C206.285 111.04 206.382 111.411 206.551 111.752C206.721 112.092 206.959 112.394 207.251 112.638C212.671 117.368 219.012 120.929 225.877 123.098C227.402 123.579 229.116 123.977 230.551 123.271C231.985 122.566 232.601 120.26 231.259 119.392Z"
          fill="#F8A085"
        />
        <path
          d="M263.05 194.974L214.263 194.225L212.762 182.996L217.265 157.542H255.544L263.05 194.974Z"
          fill="#FFB6B6"
        />
        <path
          d="M212.767 182.247L211.266 202.461L182.945 253.063C181.585 255.316 180.988 257.946 181.243 260.563C181.498 263.181 182.592 265.647 184.362 267.597L228.774 307.737C228.774 307.737 237.505 308.022 236.77 312.138C236.035 316.254 240.701 316.059 240.701 316.059L245.953 319.843L248.794 298.287C239.246 277.021 225.086 270.533 212.767 260.723C212.767 260.723 217.962 259.183 216.865 257.399C215.769 255.614 219.211 255.496 219.211 255.496C219.211 255.496 221.495 255.188 221.635 253.53C221.774 251.871 223.989 251.62 223.989 251.62L236.414 239.144L269.06 237.647C269.06 237.647 282.874 203.127 260.053 180.75L212.767 182.247Z"
          fill="#2F2E41"
        />
        <path
          d="M236.484 229.77L247.29 358.178L263.206 357.217L269.057 237.647L236.484 229.77Z"
          fill="#2F2E41"
        />
        <path
          d="M237.262 76.6109L255.481 75.6748L263.943 89.0117C263.943 89.0117 279.865 91.6668 278.762 110.38C277.658 129.094 259.39 145.516 259.39 145.516L260.17 155.261L258.814 159.126L258.988 161.297L260.838 163.595L261.029 165.972L259.39 169.733L260.052 174.761C260.052 174.761 243.568 178.025 234.922 171.527C226.277 165.029 213.517 168.772 213.517 168.772L213.037 162.578L215.537 159.234L214.14 154.872L216.24 150.677L217.334 137.351C217.334 137.351 205.358 119.404 219.107 105.887L224.625 96.5297L230.799 89.016L237.262 76.6109Z"
          fill="#F8A085"
        />
        <path
          d="M281.935 157.77L280.594 110.82C280.531 108.831 279.687 106.946 278.244 105.572C276.8 104.199 274.873 103.446 272.878 103.477C271.846 103.494 270.828 103.719 269.886 104.14C268.945 104.562 268.099 105.17 267.4 105.928C266.702 106.686 266.166 107.578 265.824 108.549C265.483 109.521 265.343 110.551 265.414 111.578L268.306 160.306L268.811 189.988C267.566 190.538 266.52 191.457 265.817 192.62C265.113 193.784 264.786 195.135 264.879 196.49C264.972 197.845 265.481 199.139 266.337 200.196C267.193 201.252 268.354 202.021 269.663 202.396C270.972 202.772 272.365 202.736 273.653 202.294C274.941 201.852 276.062 201.026 276.862 199.927C277.663 198.828 278.105 197.51 278.128 196.151C278.151 194.793 277.755 193.46 276.992 192.335L281.935 157.77Z"
          fill="#FFB6B6"
        />
        <path
          d="M285.196 120.266C285.54 119.917 285.76 119.465 285.822 118.98C285.884 118.494 285.785 118.002 285.539 117.578C285.245 117.049 285.125 116.443 285.194 115.842C285.208 115.727 285.231 115.612 285.26 115.499C285.456 114.822 285.486 114.109 285.346 113.419C285.206 112.728 284.901 112.082 284.457 111.535C282.971 109.754 282.253 107.058 281.943 104.654C281.603 102.024 281.4 99.3361 280.439 96.8637C279.052 93.2972 275.739 90.1188 271.216 91.64C269.303 92.3167 267.682 93.6307 266.626 95.3605C264.371 98.9477 264.346 103.285 264.363 107.435L263.552 105.795C263.649 110.327 262.827 114.832 261.136 119.04C262.145 118.844 262.858 120.195 262.532 121.167C262.206 122.138 261.305 122.776 260.534 123.452C259.561 124.304 258.642 125.569 259.555 126.87C259.782 127.175 260.07 127.43 260.401 127.619C260.731 127.808 261.098 127.927 261.476 127.968C268.618 128.894 275.874 128.337 282.789 126.331C284.325 125.885 285.971 125.266 286.77 123.884C287.569 122.502 286.795 120.244 285.196 120.266Z"
          fill="#F8A085"
        />
        <path
          d="M247.29 72.9453C256.825 72.9453 264.554 65.2362 264.554 55.7266C264.554 46.2169 256.825 38.5078 247.29 38.5078C237.756 38.5078 230.027 46.2169 230.027 55.7266C230.027 65.2362 237.756 72.9453 247.29 72.9453Z"
          fill="#FFB6B6"
        />
        <path
          d="M367.845 394.391L176.181 394.621C175.945 394.62 175.719 394.525 175.553 394.358C175.386 394.191 175.293 393.965 175.293 393.73C175.293 393.494 175.386 393.268 175.553 393.101C175.719 392.934 175.945 392.84 176.181 392.838L367.845 392.608C368.081 392.61 368.308 392.704 368.474 392.871C368.641 393.038 368.734 393.264 368.734 393.5C368.734 393.735 368.641 393.961 368.474 394.129C368.308 394.296 368.081 394.39 367.845 394.391Z"
          fill="#CACACA"
        />
        <path
          d="M269.059 51.2355V54.9787H254.123C254.275 54.2561 254.275 53.51 254.124 52.7873C253.973 52.0645 253.673 51.3808 253.245 50.7788C253.233 52.2438 252.867 53.6844 252.179 54.9787C243.542 57.5261 236.293 57.4773 230.353 54.9787C229.471 54.9787 228.625 54.6292 228.001 54.0072C227.378 53.3851 227.027 52.5414 227.027 51.6616C227.027 40.1851 236.097 30.5147 247.601 30.2781C250.397 30.2191 253.178 30.7175 255.778 31.7442C258.379 32.7709 260.749 34.3052 262.747 36.2572C264.746 38.2091 266.334 40.5394 267.418 43.1115C268.501 45.6835 269.06 48.4455 269.059 51.2355V51.2355Z"
          fill="#2F2E41"
        />
        <path
          d="M177.277 181.041H14.7749C10.8582 181.036 7.1032 179.483 4.3337 176.72C1.56419 173.958 0.0063545 170.212 0.00195312 166.306V73.4117C0.0064054 69.5051 1.56426 65.7598 4.33376 62.9974C7.10325 60.2351 10.8582 58.6812 14.7749 58.6768H177.277C181.193 58.6812 184.948 60.2351 187.718 62.9974C190.487 65.7598 192.045 69.5051 192.05 73.4117V166.306C192.045 170.212 190.487 173.958 187.718 176.72C184.948 179.483 181.193 181.036 177.277 181.041Z"
          fill="white"
        />
        <path
          d="M177.277 181.041H14.7749C10.8582 181.036 7.1032 179.483 4.3337 176.72C1.56419 173.958 0.0063545 170.212 0.00195312 166.306V73.4117C0.0064054 69.5051 1.56426 65.7598 4.33376 62.9974C7.10325 60.2351 10.8582 58.6812 14.7749 58.6768H177.277C181.193 58.6812 184.948 60.2351 187.718 62.9974C190.487 65.7598 192.045 69.5051 192.05 73.4117V166.306C192.045 170.212 190.487 173.958 187.718 176.72C184.948 179.483 181.193 181.036 177.277 181.041ZM14.7749 59.9581C11.1988 59.9621 7.77029 61.3808 5.24162 63.903C2.71294 66.4251 1.29056 69.8448 1.28655 73.4117V166.306C1.29062 169.873 2.71301 173.292 5.24167 175.814C7.77034 178.337 11.1988 179.755 14.7749 179.759H177.277C180.853 179.755 184.281 178.337 186.81 175.814C189.339 173.292 190.761 169.873 190.765 166.306V73.4117C190.761 69.8448 189.339 66.4251 186.81 63.903C184.281 61.3808 180.853 59.9621 177.277 59.9581H14.7749Z"
          fill="#E6E6E6"
        />
        <path
          d="M190.764 126.163H0.6423C0.471952 126.163 0.30857 126.096 0.188115 125.975C0.0676604 125.855 0 125.692 0 125.522C0 125.353 0.0676604 125.19 0.188115 125.069C0.30857 124.949 0.471952 124.882 0.6423 124.882H190.764C190.935 124.882 191.098 124.949 191.218 125.069C191.339 125.19 191.407 125.353 191.407 125.522C191.407 125.692 191.339 125.855 191.218 125.975C191.098 126.096 190.935 126.163 190.764 126.163Z"
          fill="#E6E6E6"
        />
        <path
          d="M176.632 30.4877C179.825 30.4877 182.413 27.9063 182.413 24.7219C182.413 21.5375 179.825 18.9561 176.632 18.9561C173.44 18.9561 170.852 21.5375 170.852 24.7219C170.852 27.9063 173.44 30.4877 176.632 30.4877Z"
          fill="#F2F2F2"
        />
        <path
          d="M49.4096 154.326H29.3415C29.1712 154.326 29.0078 154.259 28.8873 154.139C28.7669 154.018 28.6992 153.855 28.6992 153.686C28.6992 153.516 28.7669 153.353 28.8873 153.233C29.0078 153.112 29.1712 153.045 29.3415 153.045H49.4096C49.58 153.045 49.7433 153.112 49.8638 153.233C49.9843 153.353 50.0519 153.516 50.0519 153.686C50.0519 153.855 49.9843 154.018 49.8638 154.139C49.7433 154.259 49.58 154.326 49.4096 154.326Z"
          fill="#CCCCCC"
        />
        <path
          d="M49.4096 164.576H29.3415C29.1712 164.576 29.0078 164.509 28.8873 164.389C28.7669 164.268 28.6992 164.105 28.6992 163.936C28.6992 163.766 28.7669 163.603 28.8873 163.483C29.0078 163.362 29.1712 163.295 29.3415 163.295H49.4096C49.58 163.295 49.7433 163.362 49.8638 163.483C49.9843 163.603 50.0519 163.766 50.0519 163.936C50.0519 164.105 49.9843 164.268 49.8638 164.389C49.7433 164.509 49.58 164.576 49.4096 164.576Z"
          fill="#CCCCCC"
        />
        <path
          d="M159.242 154.326H139.174C139.003 154.326 138.84 154.259 138.719 154.139C138.599 154.018 138.531 153.855 138.531 153.686C138.531 153.516 138.599 153.353 138.719 153.233C138.84 153.112 139.003 153.045 139.174 153.045H159.242C159.412 153.045 159.575 153.112 159.696 153.233C159.816 153.353 159.884 153.516 159.884 153.686C159.884 153.855 159.816 154.018 159.696 154.139C159.575 154.259 159.412 154.326 159.242 154.326Z"
          fill="#CCCCCC"
        />
        <path
          d="M159.242 164.576H139.174C139.003 164.576 138.84 164.509 138.719 164.389C138.599 164.268 138.531 164.105 138.531 163.936C138.531 163.766 138.599 163.603 138.719 163.483C138.84 163.362 139.003 163.295 139.174 163.295H159.242C159.412 163.295 159.575 163.362 159.696 163.483C159.816 163.603 159.884 163.766 159.884 163.936C159.884 164.105 159.816 164.268 159.696 164.389C159.575 164.509 159.412 164.576 159.242 164.576Z"
          fill="#CCCCCC"
        />
        <path
          d="M122.632 154.326H102.564C102.394 154.326 102.23 154.259 102.11 154.139C101.99 154.018 101.922 153.855 101.922 153.686C101.922 153.516 101.99 153.353 102.11 153.233C102.23 153.112 102.394 153.045 102.564 153.045H122.632C122.803 153.045 122.966 153.112 123.086 153.233C123.207 153.353 123.275 153.516 123.275 153.686C123.275 153.855 123.207 154.018 123.086 154.139C122.966 154.259 122.803 154.326 122.632 154.326Z"
          fill="#CCCCCC"
        />
        <path
          d="M122.632 164.576H102.564C102.394 164.576 102.23 164.509 102.11 164.389C101.99 164.268 101.922 164.105 101.922 163.936C101.922 163.766 101.99 163.603 102.11 163.483C102.23 163.362 102.394 163.295 102.564 163.295H122.632C122.803 163.295 122.966 163.362 123.086 163.483C123.207 163.603 123.275 163.766 123.275 163.936C123.275 164.105 123.207 164.268 123.086 164.389C122.966 164.509 122.803 164.576 122.632 164.576Z"
          fill="#CCCCCC"
        />
        <path
          d="M86.021 154.326H65.9528C65.7825 154.326 65.6191 154.259 65.4987 154.139C65.3782 154.018 65.3105 153.855 65.3105 153.686C65.3105 153.516 65.3782 153.353 65.4987 153.233C65.6191 153.112 65.7825 153.045 65.9528 153.045H86.021C86.1913 153.045 86.3547 153.112 86.4751 153.233C86.5956 153.353 86.6633 153.516 86.6633 153.686C86.6633 153.855 86.5956 154.018 86.4751 154.139C86.3547 154.259 86.1913 154.326 86.021 154.326Z"
          fill="#CCCCCC"
        />
        <path
          d="M86.021 164.576H65.9528C65.7825 164.576 65.6191 164.509 65.4987 164.389C65.3782 164.268 65.3105 164.105 65.3105 163.936C65.3105 163.766 65.3782 163.603 65.4987 163.483C65.6191 163.362 65.7825 163.295 65.9528 163.295H86.021C86.1913 163.295 86.3547 163.362 86.4751 163.483C86.5956 163.603 86.6633 163.766 86.6633 163.936C86.6633 164.105 86.5956 164.268 86.4751 164.389C86.3547 164.509 86.1913 164.576 86.021 164.576Z"
          fill="#CCCCCC"
        />
        <path
          d="M99.9348 34.7409C109.448 34.7409 117.161 27.0485 117.161 17.5594C117.161 8.07035 109.448 0.37793 99.9348 0.37793C90.4212 0.37793 82.709 8.07035 82.709 17.5594C82.709 27.0485 90.4212 34.7409 99.9348 34.7409Z"
          fill="#F8A085"
        />
        <path
          d="M99.3325 24.4502C99.1013 24.4502 98.8733 24.3965 98.6666 24.2933C98.4598 24.1902 98.28 24.0404 98.1413 23.8559L94.4889 18.9985C94.3711 18.8425 94.2854 18.6649 94.2365 18.4757C94.1877 18.2866 94.1767 18.0898 94.2042 17.8964C94.2318 17.7031 94.2972 17.517 94.3969 17.3489C94.4966 17.1809 94.6285 17.034 94.7851 16.9169C94.9418 16.7997 95.1201 16.7145 95.3098 16.6661C95.4994 16.6177 95.6968 16.6071 95.8906 16.635C96.0844 16.6628 96.2708 16.7284 96.4392 16.8281C96.6075 16.9279 96.7545 17.0598 96.8716 17.2162L99.2612 20.3938L105.398 11.2118C105.618 10.8849 105.958 10.6581 106.345 10.5813C106.732 10.5045 107.134 10.584 107.462 10.8022C107.79 11.0205 108.018 11.3597 108.096 11.7454C108.174 12.1312 108.095 12.5319 107.877 12.8597L100.572 23.7887C100.44 23.9864 100.262 24.1495 100.054 24.2646C99.8451 24.3796 99.6121 24.4431 99.374 24.4497L99.3325 24.4502Z"
          fill="white"
        />
        <path
          d="M132.924 66.6371H59.1279C57.3302 66.6371 55.6061 65.9248 54.3349 64.6568C53.0637 63.3889 52.3496 61.6693 52.3496 59.8762C52.3496 58.083 53.0637 56.3634 54.3349 55.0955C55.6061 53.8275 57.3302 53.1152 59.1279 53.1152H132.924C133.815 53.1144 134.697 53.2886 135.52 53.628C136.343 53.9674 137.091 54.4652 137.721 55.0931C138.351 55.721 138.851 56.4666 139.192 57.2873C139.534 58.108 139.709 58.9877 139.709 59.8762C139.709 60.7646 139.534 61.6443 139.192 62.465C138.851 63.2857 138.351 64.0313 137.721 64.6592C137.091 65.2871 136.343 65.7849 135.52 66.1243C134.697 66.4637 133.815 66.6379 132.924 66.6371Z"
          fill="#F8A085"
        />
        <path
          d="M102.128 87.508H76.4364C75.755 87.508 75.1015 87.238 74.6197 86.7574C74.1379 86.2769 73.8672 85.6251 73.8672 84.9454C73.8672 84.2658 74.1379 83.614 74.6197 83.1334C75.1015 82.6528 75.755 82.3828 76.4364 82.3828H102.128C102.81 82.3828 103.463 82.6528 103.945 83.1334C104.427 83.614 104.698 84.2658 104.698 84.9454C104.698 85.6251 104.427 86.2769 103.945 86.7574C103.463 87.238 102.81 87.508 102.128 87.508Z"
          fill="#CCCCCC"
        />
        <path
          d="M108.548 142.154H82.8563C82.1749 142.154 81.5214 141.884 81.0396 141.403C80.5578 140.922 80.2871 140.271 80.2871 139.591C80.2871 138.911 80.5578 138.259 81.0396 137.779C81.5214 137.298 82.1749 137.028 82.8563 137.028H108.548C109.23 137.028 109.883 137.298 110.365 137.779C110.847 138.259 111.118 138.911 111.118 139.591C111.118 140.271 110.847 140.922 110.365 141.403C109.883 141.884 109.23 142.154 108.548 142.154Z"
          fill="#CCCCCC"
        />
        <path
          d="M157.366 101.601H75.7938C75.1124 101.601 74.4589 101.331 73.9771 100.85C73.4953 100.37 73.2246 99.7178 73.2246 99.0382C73.2246 98.3585 73.4953 97.7067 73.9771 97.2262C74.4589 96.7456 75.1124 96.4756 75.7938 96.4756H157.366C158.047 96.4756 158.701 96.7456 159.183 97.2262C159.664 97.7067 159.935 98.3585 159.935 99.0382C159.935 99.7178 159.664 100.37 159.183 100.85C158.701 101.331 158.047 101.601 157.366 101.601Z"
          fill="#CCCCCC"
        />
        <path
          d="M45.925 109.173C55.4385 109.173 63.1508 101.48 63.1508 91.9911C63.1508 82.502 55.4385 74.8096 45.925 74.8096C36.4115 74.8096 28.6992 82.502 28.6992 91.9911C28.6992 101.48 36.4115 109.173 45.925 109.173Z"
          fill="#E6E6E6"
        />
      </svg>
    </div>
  );
}

export default UserInfoIcon;
