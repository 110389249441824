import React from "react";
import Input from "../../Global/Input";
import Button from "../../Global/Button";
import { useState, useEffect, useRef } from "react";
import MultipleDropdown from "../MultipleDropdown";
import FilterCheckbox from "./FilterCheckbox";
import InfoPopup from "../../Global/InfoPopup";
import { ColorPicker } from "../ColorPicker/ColorPicker";
import { useTranslation } from "react-i18next";
import Popup from "../../Global/Popup";
import NewPopupMessageCreate from "../../PopupMessagesList/NewPopupMessageCreate";
import NewTeamsMessageCreate from "../../TeamsMessageList/NewTeamsMessageCreate";
import ruleCategoryService from "../../../Services/ruleCategory.service";
import popupMessageService from "../../../Services/popupMessage.service";
import teamsMessageService from "../../../Services/teamsMessage.service";
import userGroupService from "../../../Services/userGroup.service";
import ruleService from "../../../Services/rule.service";
import keywordService from "../../../Services/keyword.service";
import { toast } from "react-toastify";
import CustomToast from "../../Global/CustomToast";
import UserGroupMultipleDropdown from "../../UserGroup/UserGroupMultipleDropdown";
import AddIcon from "../../icons/AddIcon";
import LoadingIndicator from "../../Global/LoadingIndicator";
import * as XLSX from "xlsx";

function RuleEditModal(props) {
  const isActive = props.rule.isActive;
  const [educationCheck, setEducationCheck] = useState(
    props.rule.isEducation || false
  );
  const [advisorCheck, setAdvisorCheck] = useState(
    props.rule.isAdvisor || false
  );
  const { t } = useTranslation();
  const [saveInfo, setSaveInfo] = useState(false);
  const [colorHighlight, setColorHighlight] = useState(
    props.rule.color || "#DE8244"
  );
  const [array, setarray] = useState([]);
  const [addArray, setAddArray] = useState([]);
  const [sendIt, setSendIt] = useState(
    props.rule.holdingPeriod !== 0 ? true : false
  );
  const [excludedEmail, setExcludedEmail] = useState("");

  const [excludedEmails, setExcludedEmails] = useState([]);

  const addToExcludedEmails = () => {
    if (
      excludedEmail &&
      !excludedEmails.some((emailObj) => emailObj.name === excludedEmail)
    ) {
      let excludedObject = { name: excludedEmail };
      excludedEmails.push(excludedObject);
      setExcludedEmail(""); // Clear input after adding
    }
  };
  const [arrayKeys, setArrayKeys] = useState([...props.rule.keyword] || []);
  const [arrayPopup, setArrayPopup] = useState(
    [...props.rule.popupMessage] || []
  );
  const [arrayTeams, setArrayTeams] = useState(
    [...props.rule.teamsMessage] || []
  );
  const [arrayUserGroup, setArrayUserGroup] = useState(
    [...props.rule.userGroup] || []
  );
  const [ruleKeyName, setRuleKeyName] = useState("");
  const [colorText, setColorText] = useState(props.rule.color || "#000000");
  const [ruleName, setRuleName] = useState(props.rule.name || "");
  const [ruleCategory, setRuleCategory] = useState(props.rule.categoryId || "");
  const [ruleCategoryName, setRuleCategoryName] = useState("");
  const [ruleCategoryDescription, setRuleCategoryDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [ruleKeys, setRuleKeys] = useState([...props.rule.keyword] || []);
  const [userGroupName, setUserGroupName] = useState("");
  const [addRuleKey, setAddRuleKey] = useState(false);
  const [ruleRegex, setRuleRegex] = useState(props.rule.regex || "");
  const [rulePopup, setRulePopup] = useState([]);
  const [ruleTeams, setRuleTeams] = useState([]);
  const [ruleUserGroup, setRuleUserGroup] = useState(
    [...props.rule.userGroup] || []
  );
  const [severityScore, setSeverityScore] = useState(
    props.rule.severityScore || 3
  );
  const [advisorEmail, setAdvisorEmail] = useState(
    props.rule.consultantEmailAddress || ""
  );
  const [educationEmail, setEducationEmail] = useState(
    props.rule.educationLink || ""
  );
  const [holdingPeriod, setHoldingPeriod] = useState(
    props.rule.holdingPeriod || 0
  );
  const [reminderPeriod, setReminderPeriod] = useState(
    props.rule.reminderCount || 1
  );
  const [reminderCount, setReminderCount] = useState(
    props.rule.reminderPeriod || 5
  );
  const [addRuleCategory, setAddRuleCategory] = useState(false);
  const [addPopup, setAddPopup] = useState(false);
  const [addTeams, setAddTeams] = useState(false);
  const [adduserGroup, setAddUserGroup] = useState(false);
  const [userGroupOptions, setUserGroupOptions] = useState([]);
  const id = props.rule.id || 0;

  const [isAnonym, setIsAnonym] = useState(props.rule.isAnonym || false);
  const [isLogging, setIsLogging] = useState(props.rule.isLogActive || false);
  const [isLoading, setIsLoading] = useState(false);

  const [isLoggingCategory, setIsLoggingCategory] = useState(false);
  const [severityScoreCategory, setSeverityScoreCategory] = useState(3);

  function toastErrorWord(label) {
    toast.error(label, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  function toastError(label) {
    toast.error(label + t("toast"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const handleSubmitRule = (event) => {
    event.preventDefault();
    if (arrayKeys.length === 0) {
      toastError(t("ruleList.keys"));
    } else if (arrayPopup.length === 0) {
      toastError(t("ruleList.popupFor"));
    } else if (arrayUserGroup.length === 0) {
      toastError(t("sidebar.userGroups"));
    } else {
      updateRule();
    }
  };

  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  async function updateRule() {
    setIsLoading(true);
    const userGroupsArray = arrayUserGroup.map((item) => parseInt(item.id));
    const keywordsArray = arrayKeys.map((item) => parseInt(item.id));
    const popupMessagesArray = arrayPopup.map((item) => parseInt(item.id));
    const teamMessagesArray = arrayTeams.map((item) => parseInt(item.id));
    const res = await ruleService.UpdateRule(
      ruleName,
      ruleCategory,
      reminderPeriod,
      reminderCount,
      sendIt ? holdingPeriod : 0,
      isActive,
      advisorCheck,
      educationCheck,
      isAnonym,
      ruleRegex,
      colorText,
      severityScore,
      advisorEmail,
      educationEmail,
      keywordsArray,
      popupMessagesArray,
      teamMessagesArray,
      userGroupsArray,
      id,
      0
    );
    setIsLoading(false);
    if (!res.status) toastErrorFalse();
    else {
      const arrayupdated = props.rules.map((item) =>
        item.id === props.rule.id ? { ...item, ...res.data } : item
      );
      props.setRules(arrayupdated);
      setSaveInfo(true);
    }
  }

  async function addKeyword() {
    setIsLoading(true);
    if (ruleKeyName !== null && ruleKeyName !== "") {
      const res = await keywordService.AddKeyword(ruleKeyName);
      setIsLoading(false);
      if (res.status === false) {
        toastErrorFalse();
      } else {
        const value = res.data;
        if (
          arrayKeys.filter((index) => index?.name === ruleKeyName)?.length <= 0
        ) {
          arrayKeys.push(value);
        }
        setAddRuleKey(false);
        setRuleKeyName("");
      }
    } else {
      setIsLoading(false);
      toastError(t("ruleList.keys"));
    }
  }

  const handleUpload = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const wordsColumnIndex = dataParse[0].indexOf("Words");
      const separatedWordsArray = dataParse
        .slice(1)
        .map((row) => row[wordsColumnIndex]);

      arrayKeys.splice(0, arrayKeys.length);

      separatedWordsArray.map(async (index) => {
        try {
          if (arrayKeys.filter((a) => a?.name === index)?.length <= 0) {
            const res = await addKeywordFile(index);
          } else {
            toastErrorWord(t("exist"));
          }
        } catch (error) {
          console.error("An error occurred while processing data:", error);
        }
      }); // Input elementini yeniden oluştur
      fileInputRef.current.value = "";
    };

    reader.readAsBinaryString(f);
  };

  async function addKeywordFile(ruleKeyName) {
    setIsLoading(true);
    if (ruleKeyName !== null && ruleKeyName !== "") {
      const res = await keywordService.AddKeyword(ruleKeyName);
      setIsLoading(false);
      if (res.status === false) {
        toastErrorFalse();
      } else {
        const value = res.data;
        if (
          arrayKeys.filter((index) => index?.name === ruleKeyName)?.length <= 0
        ) {
          setArrayKeys((prevArray) => [...prevArray, value]);
        } else {
          toastErrorWord(t("exist"));
        }
        setAddRuleKey(false);
        setRuleKeyName("");
      }
    } else {
      setIsLoading(false);
    }
  }

  const exportToExcel = (data) => {
    const excelData = data?.map((item, index) => ({
      Index: index + 1,
      Words: item?.name,
    }));
    const templateHeaders = ["Index", "Words"];
    const templateData = [templateHeaders, ...excelData.map(Object.values)];

    const ws = XLSX.utils.aoa_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "exported_rule_keywords.xlsx");
  };

  const handleExportClick = () => {
    exportToExcel(arrayKeys);
  };

  const fileInputRef = useRef();

  const handleSubmitUserGroup = (event) => {
    event.preventDefault();
    if (addArray.length === 0) toastError(t("sidebar.userGroups"));
    else addUserGroup();
  };

  async function addUserGroup() {
    setIsLoading(true);
    const newArray = addArray.map((item) => parseInt(item.id));
    const excludedEmailsArray = excludedEmails.map((item) => item.name);
    const res = await userGroupService.AddUserGroup(
      userGroupName,
      newArray,
      excludedEmailsArray
    );
    setIsLoading(false);
    if (!res.status) toastErrorFalse();
    else {
      let newUserGroup = res.data;
      if (arrayUserGroup.includes(newUserGroup) === false) {
        arrayUserGroup.push(newUserGroup);
      }
      for (let index = 0; index < newUserGroup.group.length; index++) {
        newUserGroup.group[index] = userGroupOptions.filter(
          (op) => op.id === newUserGroup.group[index]
        )[0];
      }
      ruleUserGroup.push(newUserGroup);
      setAddUserGroup(false);
      setUserGroupName("");
      setAddArray([]);
      setExcludedEmails([]);
      setExcludedEmail("");
    }
  }

  const handleSubmitCategory = (event) => {
    event.preventDefault();
    addCategory();
  };

  async function addCategory() {
    setIsLoading(true);
    const res = await ruleCategoryService.AddRuleCategory(
      ruleCategoryName,
      ruleCategoryDescription,
      isLoggingCategory,
      severityScoreCategory
    );
    setIsLoading(false);
    if (!res.status) toastErrorFalse();
    else {
      setAddRuleCategory(false);
      setRuleCategoryName("");
      setRuleCategoryDescription("");
      setIsLoggingCategory(false);
      setSeverityScoreCategory(3);
    }
  }

  useEffect(() => {
    ruleCategoryService.RuleCategoryGetAll().then((response) => {
      if (response.status) {
        setCategories(response.data.filter((item) => item.isActive === true));
      } else toastErrorFalse();
    });
    userGroupService.UserGroupGetAll().then((response) => {
      if (response.status) setRuleUserGroup(response.data);
      else toastErrorFalse();
    });
    userGroupService.GetDomainDetailByTenant().then((response) => {
      if (response.status) setUserGroupOptions(response.data[0].group);
      else toastErrorFalse();
    });
  }, []);

  useEffect(() => {
    if (!props.rule.isLogActive) {
      setIsAnonym(false);
    }
    popupMessageService.GetByCategoryId(ruleCategory).then((response) => {
      if (response.status) {
        setRulePopup(response.data.filter((item) => item.isActive === true));
      } else setRulePopup([]);
    });
  }, [ruleCategory]);

  useEffect(() => {
    teamsMessageService.GetByCategoryId(ruleCategory).then((response) => {
      if (response.status) {
        setRuleTeams(response.data.filter((item) => item.isActive === true));
      } else setRuleTeams([]);
    });
  }, [ruleCategory]);

  if (props.visible === false) return null;

  return (
    <div className="fixed inset-0 z-10  overflow-y-auto">
      <div className="fixed inset-0 w-full h-full bg-dark opacity-70"></div>
      <CustomToast />
      {isLoading && <LoadingIndicator />}
      <div className="flex items-center px-4 py-8">
        <div className="z-10 w-full max-w-[1015px] mx-auto min-w-min h-auto rounded-[10px] bg-white">
          <div className=" h-[120px] w-full p-10">
            <p className="text-dark text-[32px] font-bold leading-8">
              {t("ruleList.ruleEdit")}
            </p>
          </div>
          <form
            className="flex flex-col  px-[40px] pb-[40px] gap-[40px] no-scrollbar overflow-y-auto"
            onSubmit={handleSubmitRule}
          >
            <Input
              placeholder="Kural Adını Giriniz"
              input={t("ruleList.ruleName")}
              for="ruleName"
              value={ruleName}
              onChange={(e) => {
                setRuleName(e.target.value);
              }}
            />

            <div className="flex flex-col gap-[10px]">
              <div className="flex flex-row  justify-between">
                <label
                  htmlFor="ruleCategory"
                  className="text-base font-bold leading-4 text-dark"
                >
                  {t("sidebar.ruleCategory")}
                </label>
                <div
                  onClick={() => {
                    setAddRuleCategory(true);
                  }}
                >
                  <AddIcon></AddIcon>
                </div>
              </div>
              <select
                required
                id="ruleCategory"
                className=" border rounded-[5px] h-[50px] border-darkGray text-dark text-sm leading-[18px] block w-full px-[15px]"
                value={ruleCategory}
                onChange={(e) => {
                  setRuleCategory(e.target.value);
                  setArrayPopup([]);
                  setArrayTeams([]);
                }}
              >
                <option value="" disabled selected hidden>
                  {t("loading")}
                </option>
                {categories.map((value, key) => (
                  <option key={key} value={value.id}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>
            <MultipleDropdown
              ruleKeyName={ruleKeyName}
              setRuleKeyName={setRuleKeyName}
              addKeyword={addKeyword}
              title={t("ruleList.keys")}
              moreTitle={t("ruleList.keys")}
              for="ruleKeys"
              keyword={true}
              ruleKey={true}
              handleUpload={handleUpload}
              fileInputRef={fileInputRef}
              handleExportClick={handleExportClick}
              options={ruleKeys}
              array={arrayKeys}
              setarray={setArrayKeys}
              add={true}
              addMethod={() => setAddRuleKey(true)}
            />

            {/*   <Input
              input={t("ruleList.regex")}
              value={ruleRegex}
              onChange={(e) => setRuleRegex(e.target.value)}
              for="ruleRegex"
              required={false}
            ></Input> */}
            <MultipleDropdown
              title={t("ruleList.popupFor")}
              moreTitle={t("ruleList.popupFor")}
              for="rulePopup"
              secondaryGreen={true}
              options={rulePopup}
              array={arrayPopup}
              setarray={setArrayPopup}
              add={true}
              addMethod={() => setAddPopup(true)}
            />
            <MultipleDropdown
              title={t("ruleList.teamsFor")}
              moreTitle={t("ruleList.teamsFor")}
              for="ruleTeams"
              teams={true}
              secondaryGreen={true}
              options={ruleTeams}
              array={arrayTeams}
              setarray={setArrayTeams}
              add={true}
              addMethod={() => setAddTeams(true)}
            />
            <MultipleDropdown
              title={t("sidebar.userGroups")}
              moreTitle={t("sidebar.userGroups")}
              for="ruleEmployeeGroup"
              options={ruleUserGroup}
              array={arrayUserGroup}
              setarray={setArrayUserGroup}
              add={true}
              addMethod={() => setAddUserGroup(true)}
            />
            <FilterCheckbox
              checked={advisorCheck}
              onChange={() => setAdvisorCheck(!advisorCheck)}
              name={t("ruleList.askAdvisor")}
              visible="true"
            />
            <Input
              visible={advisorCheck}
              input={t("ruleList.advisorEmail")}
              value={advisorEmail}
              onChange={(e) => setAdvisorEmail(e.target.value)}
              for="advisorEmail"
            />
            {/*         {advisorCheck === true ? (
              <div className="flex flex-col gap-[10px]">
                <label className="text-base font-bold leading-4 text-dark">
                  {t("ruleList.buttonName")}
                </label>
                <input
                  placeholder="Advisor Name"
                  className="bg-green text-white w-[300px] rounded-[5px] text-center font-bold text-xl px-2 py-3 leading-5"
                  maxlength="18"
                ></input>
              </div>
            ) : null} */}
            <FilterCheckbox
              checked={educationCheck}
              onChange={() => setEducationCheck(!educationCheck)}
              name={t("ruleList.goEducation")}
            />
            <Input
              visible={educationCheck}
              input={t("ruleList.educationEmail")}
              value={educationEmail}
              onChange={(e) => setEducationEmail(e.target.value)}
              for="educationAddress"
            />
            <FilterCheckbox
              checked={sendIt}
              name={t("ruleList.send")}
              onChange={() => setSendIt(!sendIt)}
            />
            {sendIt ? (
              <div className="w-1/2 pr-[10px]">
                <Input
                  visible={sendIt}
                  input={t("ruleList.waitTime")}
                  value={holdingPeriod}
                  placeholder="30 sec"
                  onChange={(e) => setHoldingPeriod(e.target.value)}
                  for="waitingTime"
                />
              </div>
            ) : null}
            <FilterCheckbox
              checked={isAnonym}
              name={t("ruleList.isAnonym")}
              onChange={() => setIsAnonym(!isAnonym)}
              disabled={isLogging ? false : true}
            />
            <div className="flex flex-col gap-[10px] w-full ">
              <div className="flex flex-row gap-3">
                {" "}
                <label className=" text-base font-bold leading-4 text-dark">
                  {t("ruleList.ruleSeverityScore")}
                </label>
                <div className="group relative">
                  <svg
                    className="fill-current h-5 w-5 text-dark mr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>

                  <span className="absolute w-[250px] bottom-0 left-6 scale-0 transition-all rounded bg-gray-800 bg-opacity-90 p-2 text-xs text-white group-hover:scale-100">
                    {t("ruleList.ruleScoreDesc")}
                  </span>
                </div>
              </div>
              <div className="flex flex-col space-y-2 p-2 w-96">
                <input
                  className="accent-green"
                  type="range"
                  value={severityScore}
                  min="1"
                  max="5"
                  step="1"
                  onChange={(e) => {
                    setSeverityScore(e.target.value);
                  }}
                />
                <div className="flex justify-between w-full px-[10px]">
                  <li className="flex justify-center ">1</li>
                  <li className="flex justify-center ">2</li>
                  <li className="flex justify-center ">3</li>
                  <li className="flex justify-center ">4</li>
                  <li className="flex justify-center ">5</li>
                </div>
                <div className="flex justify-between w-full text-xs font-semibold text-darkGray ">
                  <div className="w-1/5 flex justify-start">
                    {t("ruleList.tooLow")}
                  </div>
                  <div className="w-1/5 flex justify-center">
                    {t("ruleList.low")}
                  </div>
                  <div className="w-1/5 flex justify-center">
                    {t("ruleList.medium")}
                  </div>
                  <div className="w-1/5 flex justify-center">
                    {t("ruleList.high")}
                  </div>
                  <div className="w-1/5 flex justify-end">
                    {t("ruleList.tooHigh")}
                  </div>
                </div>
              </div>
            </div>
            {/*             <div className="flex flex-row gap-5 w-full ">
              <div className="w-full">
                <div className="flex flex-col gap-[10px]">
                  <label
                    for="reminderCount"
                    className="text-base font-bold leading-4 text-dark"
                  >
                    {t("ruleList.reminderAmount")}
                  </label>
                  <select
                    id="reminderCount"
                    className=" border rounded-[5px] h-[50px] border-darkGray text-dark text-sm leading-[18px] block w-full px-[15px] "
                    value={reminderCount}
                    onChange={(e) => {
                      setReminderCount(e.target.value);
                    }}
                  >
                    {" "}
                    <option value="" disabled selected hidden>
                      {t("pleaseChoose")}
                    </option>
                    <option value={1}>1 kere</option>
                    <option value={2}>2 kere</option>
                    <option value={3}>3 kere</option>
                    <option value={4}>4 kere</option>
                    <option value={5}>5 kere</option>
                  </select>
                </div>
              </div>
              <div className="w-full">
                <div className="flex flex-col gap-[10px]">
                  <label
                    for="reminderPeriod"
                    className="text-base font-bold leading-4 text-dark"
                  >
                    {t("ruleList.reminderRange")}
                  </label>
                  <select
                    id="reminderPeriod"
                    className=" border rounded-[5px] h-[50px] border-darkGray text-dark text-sm leading-[18px] block w-full px-[15px] "
                    value={reminderPeriod}
                    onChange={(e) => {
                      setReminderPeriod(e.target.value);
                    }}
                  >
                    {" "}
                    <option value="" disabled selected hidden>
                      {t("pleaseChoose")}
                    </option>
                    <option value={4}>4 gün arayla</option>
                    <option value={5}>5 gün arayla</option>
                    <option value={7}>7 gün arayla</option>
                    <option value={10}>10 gün arayla</option>
                    <option value={30}>ayda bir</option>
                  </select>
                </div>
              </div>
            </div> */}
            {/* <div className="flex flex-row gap-5 w-full ">
              <div className="w-1/2 pr-[10px] flex flex-col gap-[10px]">
                <label className="text-base font-bold leading-4 text-dark">
                  {t("ruleList.unsuitableText")}
                </label>
                <ColorPicker color={colorText} onChange={setColorText} />
              </div>
                   <div className="w-full flex flex-col gap-[10px]">
                <label className="text-base font-bold leading-4 text-dark">
                  {t("ruleList.unsuitableHighlight")}
                </label>
                <ColorPicker
                  color={colorHighlight}
                  onChange={setColorHighlight}
                />
              </div>
            </div> */}
            <div className="flex flex-row justify-center gap-[15px]">
              <Button method={props.close} outline={true} name={t("cancel")} />
              {/*       <Button secondaryGreen={true} name={t("draft")} /> */}
              <Button submit={true} green={true} name={t("save")} />
            </div>
          </form>
        </div>
      </div>
      {saveInfo && (
        <InfoPopup
          visible={saveInfo}
          close={() => {
            setSaveInfo(false);
            props.setState(false);
          }}
          content={t("ruleList.editInfo")}
        />
      )}
      {addRuleCategory && (
        <Popup
          visible={addRuleCategory}
          method={() => {
            setAddRuleCategory(false);
            setRuleCategoryName("");
            setRuleCategoryDescription("");
            setIsLoggingCategory(false);
            setSeverityScoreCategory(3);
          }}
          methodOK={handleSubmitCategory}
          buttonName={t("add")}
        >
          <Input
            input={t("sidebar.ruleCategory")}
            value={ruleCategoryName}
            onChange={(e) => setRuleCategoryName(e.target.value)}
            for="ruleCategory"
          />
          <div className="flex flex-col gap-[10px] w-full">
            <label
              htmlFor="ruleDescription"
              className=" text-base font-bold leading-4 text-dark"
            >
              {t("ruleCategory.description")}
            </label>
            <label className="relative block">
              <textarea
                type="text"
                id="ruleDescription"
                value={ruleCategoryDescription}
                onChange={(e) => setRuleCategoryDescription(e.target.value)}
                className="border min-h-[50px] border-darkGray text-dark rounded-[5px] text-sm leading-[18px] font-semibold height-[50px] w-full px-4 py-[15px] "
                required
              />
            </label>
          </div>
          <div className="w-full">
            <FilterCheckbox
              checked={isLoggingCategory}
              name={t("ruleList.logging")}
              onChange={() => setIsLoggingCategory(!isLoggingCategory)}
            />
          </div>
          <div className="flex flex-col gap-[10px] w-full ">
            <div className="flex flex-row gap-3">
              {" "}
              <label className=" text-base font-bold leading-4 text-dark">
                {t("ruleList.categorySeverityScore")}
              </label>
              <div className="group relative">
                <svg
                  className="fill-current h-5 w-5 text-dark mr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>

                <span className="absolute w-[250px] bottom-0 left-6 scale-0 transition-all rounded bg-gray-800 bg-opacity-90 p-2 text-xs text-white group-hover:scale-100">
                  {t("ruleList.categoryScoreDesc")}
                </span>
              </div>
            </div>
            <div className="flex flex-col space-y-2 p-2 w-80">
              <input
                className="accent-green"
                type="range"
                value={severityScoreCategory}
                min="1"
                max="5"
                step="1"
                onChange={(e) => {
                  setSeverityScoreCategory(e.target.value);
                }}
              />
              <div className="flex justify-between w-full px-[10px]">
                <li className="flex justify-center ">1</li>
                <li className="flex justify-center ">2</li>
                <li className="flex justify-center ">3</li>
                <li className="flex justify-center ">4</li>
                <li className="flex justify-center ">5</li>
              </div>
              <div className="flex justify-between w-full text-xs font-semibold text-darkGray">
                <div className="w-1/5 flex justify-start">
                  {t("ruleList.tooLow")}
                </div>
                <div className="w-1/5 flex justify-center">
                  {t("ruleList.low")}
                </div>
                <div className="w-1/5 flex justify-center">
                  {t("ruleList.medium")}
                </div>
                <div className="w-1/5 flex justify-center">
                  {t("ruleList.high")}
                </div>
                <div className="w-1/5 flex justify-end">
                  {t("ruleList.tooHigh")}
                </div>
              </div>
            </div>
          </div>
        </Popup>
      )}
      <Popup
        visible={addRuleKey}
        method={() => setAddRuleKey(false)}
        methodOK={addKeyword}
        buttonName={t("add")}
      >
        <Input
          input={t("ruleList.keys")}
          value={ruleKeyName}
          onChange={(e) => setRuleKeyName(e.target.value)}
          for="ruleKey"
        ></Input>
      </Popup>
      {addPopup && (
        <NewPopupMessageCreate
          rule={true}
          ruleCategory={ruleCategory}
          visible={addPopup}
          setState={setAddPopup}
          close={() => setAddPopup(false)}
          array={arrayPopup}
        />
      )}
      {addTeams && (
        <NewTeamsMessageCreate
          rule={true}
          ruleCategory={ruleCategory}
          visible={addTeams}
          setState={setAddTeams}
          array={arrayTeams}
          close={() => setAddTeams(false)}
        />
      )}
      {adduserGroup && (
        <Popup
          visible={adduserGroup}
          method={() => {
            setAddUserGroup(false);
            setUserGroupName("");
            setAddArray([]);
          }}
          methodOK={handleSubmitUserGroup}
          buttonName={t("add")}
        >
          <div className="flex flex-col gap-7 w-full">
            <Input
              input={t("userGroups.groupName")}
              value={userGroupName}
              onChange={(e) => setUserGroupName(e.target.value)}
              for="userGroupName"
            ></Input>
            <UserGroupMultipleDropdown
              title={t("userGroups.memberAdd")}
              moreTitle={t("sidebar.userGroups")}
              for="groupMembers"
              options={userGroupOptions}
              array={addArray}
              userGroup={true}
              setarray={setAddArray}
            />
            <MultipleDropdown
              ruleKeyName={excludedEmail}
              setRuleKeyName={setExcludedEmail}
              addKeyword={addToExcludedEmails}
              placeholder={t("userGroups.excludePlaceholder")}
              title={t("userGroups.exclude")}
              moreTitle={t("userGroups.exclude")}
              keyword={true}
              for="excludeEmails"
              array={excludedEmails}
              setarray={setExcludedEmails}
              add={true}
              exclude={true}
            />
          </div>
        </Popup>
      )}
    </div>
  );
}

export default RuleEditModal;
