import React from 'react';
import './i18n';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Home from './pages/Home';
import Login from './pages/Login.jsx';
import UserService from './Services/user.service';
import Sidebar from './components/Global/Sidebar/Sidebar';
import RuleList from './pages/RuleList';
import NewRuleCreate from './components/RuleList/NewRuleCreate';
import PopupMessagesList from './pages/PopupMessagesList';
import NewPopupMessageCreate from './components/PopupMessagesList/NewPopupMessageCreate';
import TeamsMessagesList from './pages/TeamsMessagesList';
import NewTeamsMessageCreate from './components/TeamsMessageList/NewTeamsMessageCreate';
import NotFound from './pages/NotFound';
import RuleCategoryList from './pages/RuleCategoryList';
import ProfileInfo from './pages/ProfileInfo';
import AccountInfo from './pages/AccountInfo';
import UserInfo from './components/AccountInfo/UserInfo';
import UserGroup from './pages/UserGroup';
import Dashboard from './pages/Dashboard';
import Admin from './pages/Admin';
import Support from './pages/Support';
import Log from './pages/Log';
import LoadingIndicator from './components/Global/LoadingIndicator';

function App() {
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    UserService.checkUserToken().then((res) => {
      setToken(res);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
    })
  }, [])
  /* const [token, setToken] = useState<string | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    UserService.checkUserToken().then((res) => {
      setToken(res);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err)
    })
  }, [])*/

  if (token === null && isLoading === false) {
    return <Login />
  }

  return (
    isLoading ? <div>
      <LoadingIndicator />
    </div> :
      <BrowserRouter>
        <div className="min-w-min min-h-screen flex flex-col md:flex-row h-full w-full bg-backgroundGray ">
          <Sidebar token={token} />
          <div className="w-full min-h-screen justify-center items-center">
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route path="/rule-list" element={<RuleList />} />
              <Route path="/rule-list/add" element={<NewRuleCreate />} />
              <Route path="/popup-message-list" element={<PopupMessagesList />} />
              <Route path="/popup-message-list/add" element={<NewPopupMessageCreate />} />
              <Route path="/teams-contents" element={<TeamsMessagesList />} />
              <Route path="/teams-contents/add" element={<NewTeamsMessageCreate />} />
              <Route path="/rule-category" element={<RuleCategoryList />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/profile-info" element={<ProfileInfo />} />
              <Route path="/account-info" element={<AccountInfo />} />
              <Route path="/account-info/user-infos" element={<UserInfo />} />
              <Route path="/user-groups" element={<UserGroup />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/support" element={<Support />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/login" element={<Navigate to="/home" replace />} />
              <Route path="/log" element={<Log />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
  );
}

export default App;
