import axios from "axios";
import Cookies from 'js-cookie';
const baseDir = process.env.REACT_APP_API_BASE_DIR + "/Dashboard";

class DashboardService {
  async GetAll(signal) {
    const userToken = Cookies.get("userToken");
    /*   const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/getall",
        {},
        {
          signal: signal,
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (err) {
      if (err.name === "AbortError") return;
      else {
        throw err;
      }
    }
  }
  async GetSummary(fromDate, toDate, signal) {
    const userToken = Cookies.get("userToken");
    /*    const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/getSummary",
        {
          fromDate: fromDate,
          toDate: toDate
        },
        {
          signal: signal,
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (err) {
      if (err.name === "AbortError") return;
      else {
        throw err;
      }
    }
  }

  async GetWeeklyRuleCount(fromDate, toDate, signal) {
    const userToken = Cookies.get("userToken");
    /*      const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/getweeklyscore",
        {
          fromDate: fromDate,
          toDate: toDate
        },
        {
          signal: signal,
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (err) {
      if (err.name === "AbortError") return;
      else {
        throw err;
      }
    }
  }

  async GetRuleCount(fromDate, toDate, signal) {
    const userToken = Cookies.get("userToken");
    /*    const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/getRuleCount",
        {
          fromDate: fromDate,
          toDate: toDate
        },
        {
          signal: signal,
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (err) {
      if (err.name === "AbortError") return;
      else {
        throw err;
      }
    }
  }

  async GetScore(fromDate, toDate, signal) {
    const userToken = Cookies.get("userToken");
    /*   const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/getScore",
        {
          fromDate: fromDate,
          toDate: toDate
        },
        {
          signal: signal,
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      }
      else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (err) {
      if (err.name === "AbortError") return;
      else {
        throw err;
      }
    }
  }

  async GetKeywordsByCategory(fromDate, toDate, categoryId, signal) {
    const userToken = Cookies.get("userToken");
    /*   const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/getKeywordsByCategory",
        {
          fromDate: fromDate,
          toDate: toDate,
          categoryId: categoryId
        },
        {
          signal: signal,
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (err) {
      if (err.name === "AbortError") return;
      else {
        throw err;
      }
    }
  }
}

export default new DashboardService();