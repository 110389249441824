import React from "react";

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M9.56139 2.81944L11.0149 4.2723L9.56139 2.81944ZM10.4961 1.53952L6.56577 5.46986C6.36269 5.67265 6.22419 5.93103 6.16773 6.21241L5.80469 8.02969L7.62196 7.66596C7.90334 7.60968 8.16138 7.47174 8.36452 7.2686L12.2948 3.33827C12.413 3.22016 12.5066 3.07995 12.5706 2.92563C12.6345 2.77132 12.6674 2.60592 12.6674 2.4389C12.6674 2.27187 12.6345 2.10647 12.5706 1.95216C12.5066 1.79784 12.413 1.65763 12.2948 1.53952C12.1767 1.42142 12.0365 1.32773 11.8822 1.26381C11.7279 1.19989 11.5625 1.16699 11.3955 1.16699C11.2284 1.16699 11.0631 1.19989 10.9087 1.26381C10.7544 1.32773 10.6142 1.42142 10.4961 1.53952V1.53952Z"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2942 9.40285V11.4617C11.2942 11.8257 11.1496 12.1748 10.8922 12.4322C10.6348 12.6896 10.2857 12.8343 9.92166 12.8343H2.37256C2.00854 12.8343 1.65942 12.6896 1.40201 12.4322C1.14461 12.1748 1 11.8257 1 11.4617V3.9126C1 3.54858 1.14461 3.19946 1.40201 2.94205C1.65942 2.68465 2.00854 2.54004 2.37256 2.54004H4.43141"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EditIcon;
