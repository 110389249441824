import React from "react";

function SummaryCard(props) {
  return (
    <div className="w-1/6 flex flex-col items-center justify-center gap-1 h-auto min-h-[114px] p-[20px] shadow-[0px_2px_12px_rgba(149,_151,_155,_0.5)]  rounded-[10px]">
      <div className="flex text-sm max-w-[150px] text-darkGray font-semibold text-center">
        {props.define}
      </div>
      <div className="flex text-[32px] leading-8 font-semibold max-w-[150px] text-green text-center ">
        {props.title}
      </div>
    </div>
  );
}

export default SummaryCard;
