import axios from "axios";
import Cookies from 'js-cookie';
const baseDir = process.env.REACT_APP_API_BASE_DIR + "/Rule";

class RuleService {
  async RuleGetAll(signal) {
    const userToken = Cookies.get("userToken");
    /*  const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.post(
        baseDir + "/getall",
        {},
        {
          signal: signal,
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded) {
        return { status: true, message: res.data.message, data: res.data.data };
      } else {
        return { status: false, message: res.data.message };
      }
    } catch (err) {
      if (err.name === "AbortError") return;
      else {
        throw err;
      }
    }
  }
  async AddRule(
    ruleName,
    categoryId,
    reminderPeriod,
    reminderCount,
    holdingPeriod,
    isAdvisor,
    isEducation,
    isAnonym,
    regex,
    color,
    severityScore,
    consultantEmailAddress,
    educationLink,
    keywordsArray,
    popupMessagesArray,
    teamsMessagesArray,
    userGroupsArray
  ) {
    const userToken = Cookies.get("userToken");
    /*       const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/add",
        {
          name: String(ruleName),
          categoryId: categoryId,
          reminderPeriod: reminderPeriod,
          reminderCount: reminderCount,
          isSecurityGroup: true,
          accessibilityForNonSecurityGroup: true,
          holdingPeriod: parseInt(holdingPeriod),
          isAdvisor: isAdvisor,
          isEducation: isEducation,
          isAttachment: true,
          isAnonym: isAnonym,
          regex: String(regex),
          color: String(color),
          severityScore: parseInt(severityScore),
          consultantEmailAddress: String(consultantEmailAddress),
          educationLink: String(educationLink),
          keywords: keywordsArray,
          popupMessages: popupMessagesArray,
          teamsMessages: teamsMessagesArray,
          userGroups: userGroupsArray,
        },
        {
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }

  async UpdateRule(
    ruleName,
    categoryId,
    reminderPeriod,
    reminderCount,
    holdingPeriod,
    isActive,
    isAdvisor,
    isEducation,
    isAnonym,
    regex,
    color,
    severityScore,
    consultantEmailAddress,
    educationLink,
    keywordsArray,
    popupMessagesArray,
    teamsMessagesArray,
    userGroupsArray,
    id,
    operationType
  ) {
    const userToken = Cookies.get("userToken");
    /*    const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/update",
        {
          name: String(ruleName),
          categoryId: parseInt(categoryId),
          reminderPeriod: parseInt(reminderPeriod),
          reminderCount: parseInt(reminderCount),
          isSecurityGroup: true,
          accessibilityForNonSecurityGroup: true,
          holdingPeriod: parseInt(holdingPeriod),
          isActive: isActive,
          isAdvisor: isAdvisor,
          isEducation: isEducation,
          isAttachment: true,
          isAnonym: isAnonym,
          regex: String(regex),
          color: String(color),
          severityScore: parseInt(severityScore),
          consultantEmailAddress: String(consultantEmailAddress),
          educationLink: String(educationLink),
          keywords: keywordsArray,
          popupMessages: popupMessagesArray,
          teamsMessages: teamsMessagesArray,
          userGroups: userGroupsArray,
          id: parseInt(id),
          operationType: parseInt(operationType),
        },
        {
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }

}

export default new RuleService();
