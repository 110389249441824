import { useNavigate } from "react-router-dom";
function Card(props) {
  const navigate = useNavigate();
  /*  const [rules, setRules] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  async function logout() {
    await UserService.logout(token);
    window.location.replace("/");
  }

  async function GetAllRules() {
    const res = await RuleService.RuleGetAll(token);
    if (res.status === false) {
      setErrorMessage("Something went wrong!");
    } else {
      setRules(res.data);
    }
  } */

  return (
    <div
      className="bg-white flex h-full flex-wrap min-w-[320px] justify-evenly p-8 items-center rounded-[10px] cursor-pointer  hover:shadow-2xl"
      onClick={() => navigate(props.href)}
    >
      {props.children}
      <div className="flex flex-col gap-5 w-[400px]">
        <div className="flex flex-row gap-[5px] justify-center items-end h-[22px] text-green text-center">
          <p className="text-[22px] font-bold leading-[22px] ">{props.title}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
          >
            <path
              d="M2.91211 9H15.7225"
              stroke="#1E5C2F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.4805 3.9375L15.7211 9L10.4805 14.0625"
              stroke="#1E5C2F"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <p className="text-base leading-4 text-dark text-center font-semibold">
          {props.content}
        </p>
      </div>
    </div>
  );
}
export default Card;
