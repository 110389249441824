import { useEffect, useState } from "react";
import SortModalRadiobox from "./SortModalRadiobox";
import { useTranslation } from "react-i18next";

function SortModal(props) {
  const { t } = useTranslation();
  const [selectedFilterOption, setSelectedFilterOption] = useState(1);

  useEffect(() => {
    const sort = Number(new URLSearchParams(window.location.search).get("sortby"));
    setSelectedFilterOption(sort || 1);
  }, []);

  useEffect(() => {

    if(selectedFilterOption === 1){
      let sorted = props.array.sort(
        (p1, p2) => (p1.createDate < p2.createDate) 
        ? 1 
        : (p1.createDate > p2.createDate) ? -1 : 0
      );
      props.setArray(sorted);
      props.force();
    } else if(selectedFilterOption === 2){
      let sorted = props.array.sort(
        (p1, p2) => (p1.createDate > p2.createDate) 
        ? 1 
        : (p1.createDate < p2.createDate) ? -1 : 0
      );
      props.setArray(sorted);
      props.force();
    } else if(selectedFilterOption === 3){
      let sorted = props.array.sort(function(a, b) {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      props.setArray(sorted);
      props.force();
    } else if(selectedFilterOption === 4) {
      let sorted = props.array.sort(function(a, b) {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      });
      props.setArray(sorted);
      props.force();
    }
  }, [selectedFilterOption])

  function handleSelectOption(id) {
    setSelectedFilterOption(id);

    const params = new URLSearchParams(window.location.search);
    params.set("sortby", id);
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.pushState({}, "", newUrl);
  }

  if (props.visible === false) return null;
  return (
    <div className="absolute left-[85px] top-14 z-10 w-[327px] h-[717px] overflow-scroll no-scrollbar bg-secondaryGray flex flex-col rounded-[10px] ">
      <div className="flex flex-row justify-between items-center px-[20px] py-[30px]">
        <p className=" text-[22px] leading-[22px] text-textGray font-bold">
          {t("ruleList.sort")}
        </p>
      </div>
      <div className="flex flex-col gap-[10px] text-base font-bold leading-4 text-textGray justify-start">
        <div className="flex flex-col gap-[14px]">
          <div className="flex flex-row justify-between px-[35px] pt-[12px] pb-[9px]">
            <p>{t("ruleList.sortCriteria")}</p>
          </div>
          <div className="flex flex-col px-[55px] gap-[8px] ">
            <SortModalRadiobox name={t("ruleList.new")} optionCode={1} handleChange={handleSelectOption} selected={selectedFilterOption}></SortModalRadiobox>
            <SortModalRadiobox name={t("ruleList.old")} optionCode={2} handleChange={handleSelectOption} selected={selectedFilterOption}></SortModalRadiobox>
            <SortModalRadiobox name={t("ruleList.az")} optionCode={3} handleChange={handleSelectOption} selected={selectedFilterOption}></SortModalRadiobox>
            <SortModalRadiobox name={t("ruleList.za")} optionCode={4} handleChange={handleSelectOption} selected={selectedFilterOption}></SortModalRadiobox>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SortModal;
