import { useTranslation } from "react-i18next";
import EditableUserInfo from "../components/Global/EditableUserInfo";
import userService from "../Services/user.service";
import { useState, useEffect } from "react";
import LoginForm from "../components/Login/LoginForm";
import Cookies from "js-cookie";

function ProfileInfo() {
  const { t } = useTranslation();
  //  const [id, setId] = useState(0);
  const [user, setUser] = useState({});

  useEffect(() => {
    const user = Cookies.get("user");

    //  setId(obj.id);
    userService.getbyid(user).then((response) => {
      if (response.status) {
        setUser(response.data);
      } else {
      }
    });
  }, []);

  /* useEffect(() => {
    let text = localStorage.getItem("user");
    let obj = JSON.parse(text);
    //  setId(obj.id);
    userService.getbyid(obj.id).then((response) => {
      if (response.status) {
        setUser(response.data);
      } else {
      }
    });
  }, []);*/

  return (
    <EditableUserInfo
      title={t("profile.title")}
      //name={user.name}
      // email={user.email}
      user={user}
      setuser={setUser}
      password={"*********"}
    ></EditableUserInfo>
  );
}

export default ProfileInfo;
