import React from "react";

function SidebarOption({ active, text, Icon, badge }) {
  return (
    <div className="flex flex-row gap-[15px] justify-start items-center">
      <Icon active={active} />
      <p>{text}</p>
    </div>
  );
}

export default SidebarOption;
