import React from "react";
import { useTranslation } from "react-i18next";
import notFound from "../images/notFound.svg";
function NotFound(props) {
  const { t } = useTranslation();
  return (
    <div
      className={"px-10 py-10 w-full" + (props.login ? " h-screen" : " h-full")}
    >
      <div className="bg-white w-full overflow-y-scroll  no-scrollbar h-full rounded-t-[10px] p-24 flex flex-col gap-24 justify-center items-center">
        <div className="min-w-min max-w-full ">
          <img src={notFound} alt="NotFoundImage" />
        </div>
        <div className="flex flex-col gap-4 justify-center items-center text-[#22221F]">
          <p className="text-[45px] leading-[54px] font-bold ">
            {t("error.sory")}
          </p>
          <p className=" text-xl leading-[30px] text-center font-light">
            {t("error.notFound")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
