import React, { useState } from "react";
import LicenceCard from "../components/Admin/LicenceCard";
import ImpressionCard from "../components/Dashboard/ImpressionCard";
import OutlineButton from "../components/Global/OutlineButton";
import CompanyCard from "../components/Admin/CompanyCard";
import InfoPopup from "../components/Global/InfoPopup";
import Input from "../components/Global/Input";
import Popup from "../components/Global/Popup";
import { useTranslation } from "react-i18next";

function Admin() {
  const { t } = useTranslation();
  const [newCompany, setNewCompany] = useState(false);
  const [newdemo, setNewDemo] = useState(false);
  const [newDemoInfo, setNewDemoInfo] = useState(false);
  const [newLicence, setNewLicence] = useState(false);
  const [newCompanyInfo, setNewCompanyInfo] = useState(false);
  const [newLicenceInfo, setNewLicenceInfo] = useState(false);
  const addednewCompany = () => {
    setNewCompany(false);
    setNewCompanyInfo(true);
  };
  const addednewLicence = () => {
    setNewLicence(false);
    setNewLicenceInfo(true);
  };
  const addednewDemo = () => {
    setNewLicence(false);
    setNewDemoInfo(true);
  };
  return (
    <div className="px-10 pt-10 w-full min-w-min h-full">
      <div className="bg-white w-full h-full rounded-t-[10px] pb-10">
        <div className=" h-[120px] w-full flex  justify-start items-center p-10">
          <p className="text-dark text-[32px] font-bold leading-8">
            Admin Page
          </p>
        </div>
        <div className="h-screen px-10 overflow-y-scroll no-scrollbar flex flex-col gap-5 pt-1">
          <div className="flex flex-row gap-5 justify-center">
            <ImpressionCard
              title="Tüm Müşteriler"
              label="Müşteri Sayısı;"
              number=" 15K"
              green={true}
            />
            <ImpressionCard
              title="Toplam Lisanslar"
              label="Toplam Lisans Sayısı;"
              number=" 150K"
            />
          </div>
          <div className="flex justify-end items-center">
            <OutlineButton
              method={() => {
                setNewCompany(true);
              }}
              green={true}
              content="Yeni Lisans Tanımla"
            ></OutlineButton>
          </div>
          <div className="flex flex-col border  border-secondaryGray rounded-[10px] min-w-[900px] w-full h-[400px]">
            <div className="text-dark font-bold text-sm leading-[14px] h-[76px] w-full flex flex-row items-center border-b border-b-secondaryGray px-[35px]">
              <p className="w-1/6 text-start">Müşteri Adı</p>
              <p className="w-1/6 text-center">Lisans Adedi</p>
              <p className="w-1/6 text-center">User Bazlı Lisans Kullanım %</p>
              <p className="w-1/6 text-center">Lisans Geçerlilik</p>
              <p className="w-1/6 text-center">Abonelik Türü</p>
              <p className="w-1/6 text-center">Ödeme Yöntemi</p>
            </div>
            <div className="overflow-y-scroll no-scrollbar w-full flex flex-col">
              <LicenceCard
                name="Beril Kıpırtı"
                licenceNumber="2"
                licencePercentage="%65"
                date="24.09.2023"
                type="Klasik"
                payMethod="Klasik"
              ></LicenceCard>
              <LicenceCard
                name="Beril Kıpırtı"
                licenceNumber="2"
                licencePercentage="%65"
                date="24.09.2023"
                type="Klasik"
                payMethod="Klasik"
              ></LicenceCard>
              <LicenceCard
                name="Beril Kıpırtı"
                licenceNumber="2"
                licencePercentage="%65"
                date="24.09.2023"
                type="Klasik"
                payMethod="Klasik"
              ></LicenceCard>
              <LicenceCard
                name="Beril Kıpırtı"
                licenceNumber="2"
                licencePercentage="%65"
                date="24.09.2023"
                type="Klasik"
                payMethod="Klasik"
              ></LicenceCard>
              <LicenceCard
                name="Beril Kıpırtı"
                licenceNumber="2"
                licencePercentage="%65"
                date="24.09.2023"
                type="Klasik"
                payMethod="Klasik"
              ></LicenceCard>
              <LicenceCard
                name="Beril Kıpırtı"
                licenceNumber="2"
                licencePercentage="%65"
                date="24.09.2023"
                type="Klasik"
                payMethod="Klasik"
              ></LicenceCard>
              <LicenceCard
                name="Beril Kıpırtı"
                licenceNumber="2"
                licencePercentage="%65"
                date="24.09.2023"
                type="Klasik"
                payMethod="Klasik"
              ></LicenceCard>
            </div>
          </div>
          <div className="flex justify-end items-center">
            <OutlineButton
              method={() => {
                setNewLicence(true);
              }}
              green={true}
              content="Yeni Firma Ekle"
            ></OutlineButton>
          </div>
          <div className="flex flex-col border border-secondaryGray rounded-[10px] min-w-[900px] w-full h-[400px]">
            <div className="text-dark font-bold text-sm leading-[14px] h-[76px] w-full flex flex-row items-center border-b border-b-secondaryGray px-[35px]">
              <p className="w-1/6 text-start">Admin Hesabı</p>
              <p className="w-1/6 text-center">Şirket Bilgileri</p>
              <p className="w-1/6 text-center">Tax Number</p>
              <p className="w-1/6 text-center">Application ID</p>
              <p className="w-1/6 text-center">Directory ID</p>
              <p className="w-1/6 text-center">Client Secret Value</p>
            </div>
            <div className="overflow-y-scroll overflow-x-scroll no-scrollbar w-full flex flex-col">
              <CompanyCard
                name="Beril Kıpırtı"
                companyInfo="lorem ıpsum dolar sıt amet"
                taxNumber="12564564646465"
                applicationId="54564465454"
                directoryId="5454544787554877"
                clientValue="lorem ıpsum dolar sıt amet lorem ıpsum dolar"
              ></CompanyCard>
              <CompanyCard
                name="Beril Kıpırtı"
                companyInfo="lorem ıpsum dolar sıt amet"
                taxNumber="12564564646465"
                applicationId="54564465454"
                directoryId="5454544787554877"
                clientValue="lorem ıpsum dolar sıt amet lorem ıpsum dolar"
              ></CompanyCard>
              <CompanyCard
                name="Beril Kıpırtı"
                companyInfo="lorem ıpsum dolar sıt amet"
                taxNumber="12564564646465"
                applicationId="54564465454"
                directoryId="5454544787554877"
                clientValue="lorem ıpsum dolar sıt amet lorem ıpsum dolar"
              ></CompanyCard>
              <CompanyCard
                name="Beril Kıpırtı"
                companyInfo="lorem ıpsum dolar sıt amet"
                taxNumber="12564564646465"
                applicationId="54564465454"
                directoryId="5454544787554877"
                clientValue="lorem ıpsum dolar sıt amet lorem ıpsum dolar"
              ></CompanyCard>
              <CompanyCard
                name="Beril Kıpırtı"
                companyInfo="lorem ıpsum dolar sıt amet"
                taxNumber="12564564646465"
                applicationId="54564465454"
                directoryId="5454544787554877"
                clientValue="lorem ıpsum dolar sıt amet lorem ıpsum dolar"
              ></CompanyCard>
              <CompanyCard
                name="Beril Kıpırtı"
                companyInfo="lorem ıpsum dolar sıt amet"
                taxNumber="12564564646465"
                applicationId="54564465454"
                directoryId="5454544787554877"
                clientValue="lorem ıpsum dolar sıt amet lorem ıpsum dolar"
              ></CompanyCard>
            </div>
          </div>
        </div>
      </div>
      <Popup
        /*  taslak={true} */
        visible={newCompany}
        buttonName="Kaydet"
        methodTaslak={() => setNewCompany(false)}
        method={() => setNewCompany(false)}
        methodOK={addednewCompany} // add user deleted method
      >
        <div className="w-full h-[60px]  justify-start items-center text-dark text-[32px] leading-8 font-bold">
          Yeni Firma Ekle
        </div>
        <Input for="name" input="Admin Hesabı"></Input>
        <Input for="companyInfo" input="Şirket Bilgileri"></Input>
        <Input for="taxNumber" input="Tax Number"></Input>
        <Input for="aplicationID" input="Application ID"></Input>
        <Input for="directoryID" input="Directory ID"></Input>
        <Input for="clientValue" input="Client Server Value"></Input>
      </Popup>
      <InfoPopup
        visible={newCompanyInfo}
        content="Yeni Firma Oluşturuldu."
        close={() => setNewCompanyInfo(false)}
      ></InfoPopup>
      <Popup
        visible={newLicence}
        buttonName="Kaydet"
        method={() => setNewLicence(false)}
        methodOK={newdemo === true ? addednewDemo : addednewLicence}
      >
        {newdemo === true ? (
          <>
            <div className="w-full h-[60px] flex flex-row justify-between items-center text-dark text-[32px] leading-8 font-bold">
              <span>Yeni Lisans Ekle</span>
              <div className="h-[50px] w-[400px] flex text-xl leading-5 border-secondaryGray border-2 rounded-[5px]  shadow-[0px_2px_12px_rgba(149,_151,_155,_0.2)]">
                {/* <div className="relative border-b-[50px] overflow-hidden border-r-[25px] border-r-transparent border-b-green w-28 text-white"></div> */}
                <button
                  onClick={() => setNewDemo(false)}
                  className="w-1/2  text-green"
                >
                  Yeni Alım
                </button>
                <button
                  onClick={() => setNewDemo(true)}
                  className="w-1/2 text-white bg-green rounded-r-[5px]"
                >
                  Demo Tanımlama
                </button>
              </div>
            </div>
            <Input
              for="customerName"
              input="Müşteri Adı"
              placeholder="Müşteri adı giriniz."
            ></Input>
            <div className="w-full flex flex-col gap-[10px]">
              <label
                for="licenceNumber"
                className="text-base font-bold leading-4 text-dark"
              >
                Lisans Adedi
              </label>
              <select className=" border rounded-[5px] h-[50px] border-darkGray text-dark text-sm leading-[18px] block w-full px-[15px] ">
                <option value="" disabled selected hidden>
                  {t("pleaseChoose")}
                </option>{" "}
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
              </select>
            </div>
            <Input for="demoStartDate" input="Demo Başlangıç tarihi"></Input>
            <Input for="demoFinishDate" input="Demo Bitiş Tarihi"></Input>
          </>
        ) : (
          <>
            <div className="w-full h-[60px] flex flex-row justify-between items-center text-dark text-[32px] leading-8 font-bold">
              <span>Yeni Lisans Ekle</span>
              <div className="h-[50px] w-[400px] flex text-xl leading-5 border-secondaryGray border-2 rounded-[5px]  shadow-[0px_2px_12px_rgba(149,_151,_155,_0.2)]">
                {/* <div className="relative border-b-[50px] overflow-hidden border-r-[25px] border-r-transparent border-b-green w-28 text-white"></div> */}
                <button
                  oonClick={() => setNewDemo(false)}
                  className="w-1/2 bg-green text-white rounded-l-[5px]"
                >
                  Yeni Alım{" "}
                </button>
                <button
                  onClick={() => setNewDemo(true)}
                  className="w-1/2 text-green rounded-[5px]"
                >
                  Demo Tanımlama
                </button>
              </div>
            </div>
            <Input
              for="customerName"
              input="Müşteri Adı"
              placeholder="Müşteri adı giriniz."
            ></Input>
            <div className="w-full flex flex-col gap-[10px]">
              <label
                for="licenceNumber"
                className="text-base font-bold leading-4 text-dark"
              >
                Lisans Adedi
              </label>
              <select className=" border rounded-[5px] h-[50px] border-darkGray text-dark text-sm leading-[18px] block w-full px-[15px] ">
                <option value="" disabled selected hidden>
                  {t("pleaseChoose")}
                </option>{" "}
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
              </select>
            </div>
            <Input
              for="licenceStartDate"
              input="Lisans Başlangıç tarihi"
            ></Input>
            <Input for="licenceFinishDate" input="Lisans Bitiş Tarihi"></Input>
          </>
        )}
      </Popup>
      <InfoPopup
        visible={newLicenceInfo}
        content="Yeni Lisans Oluşturuldu."
        close={() => setNewLicenceInfo(false)}
      ></InfoPopup>
      <InfoPopup
        visible={newDemoInfo}
        content="Yeni Demo Oluşturuldu."
        close={() => setNewDemoInfo(false)}
      ></InfoPopup>
    </div>
  );
}

export default Admin;
