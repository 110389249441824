import React from "react";

function RuleCategoryIcon({ active }) {
  const data = active ? filled : outline;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      {data}
    </svg>
  );
}

const outline = (
  <g>
    <path
      d="M12 16H20"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 20H20"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 5H25C25.2652 5 25.5196 5.10536 25.7071 5.29289C25.8946 5.48043 26 5.73478 26 6V25C26 25.7956 25.6839 26.5587 25.1213 27.1213C24.5587 27.6839 23.7956 28 23 28H9C8.20435 28 7.44129 27.6839 6.87868 27.1213C6.31607 26.5587 6 25.7956 6 25V6C6 5.73478 6.10536 5.48043 6.29289 5.29289C6.48043 5.10536 6.73478 5 7 5V5Z"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 3V7"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 3V7"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 3V7"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);

const filled = (
  <g>
    <path
      d="M22.9375 4V4.0625H23H25C25.5139 4.0625 26.0067 4.26663 26.37 4.62998C26.7334 4.99333 26.9375 5.48614 26.9375 6V25C26.9375 26.0443 26.5227 27.0458 25.7842 27.7842C25.0458 28.5227 24.0443 28.9375 23 28.9375H9C7.95571 28.9375 6.95419 28.5227 6.21577 27.7842C5.47734 27.0458 5.0625 26.0443 5.0625 25V6C5.0625 5.48614 5.26663 4.99333 5.62998 4.62998C5.99333 4.26663 6.48614 4.0625 7 4.0625H9H9.0625V4V3C9.0625 2.75136 9.16127 2.5129 9.33709 2.33709C9.5129 2.16127 9.75136 2.0625 10 2.0625C10.2486 2.0625 10.4871 2.16127 10.6629 2.33709C10.8387 2.5129 10.9375 2.75136 10.9375 3V4V4.0625H11H15H15.0625V4V3C15.0625 2.75136 15.1613 2.5129 15.3371 2.33709C15.5129 2.16127 15.7514 2.0625 16 2.0625C16.2486 2.0625 16.4871 2.16127 16.6629 2.33709C16.8387 2.5129 16.9375 2.75136 16.9375 3V4V4.0625H17H21H21.0625V4V3C21.0625 2.75136 21.1613 2.5129 21.3371 2.33709C21.5129 2.16127 21.7514 2.0625 22 2.0625C22.2486 2.0625 22.4871 2.16127 22.6629 2.33709C22.8387 2.5129 22.9375 2.75136 22.9375 3V4ZM12 21.0625H20C20.2818 21.0625 20.552 20.9506 20.7513 20.7513C20.9506 20.552 21.0625 20.2818 21.0625 20C21.0625 19.7182 20.9506 19.448 20.7513 19.2487C20.552 19.0494 20.2818 18.9375 20 18.9375H12C11.7182 18.9375 11.448 19.0494 11.2487 19.2487C11.0494 19.448 10.9375 19.7182 10.9375 20C10.9375 20.2818 11.0494 20.552 11.2487 20.7513C11.448 20.9506 11.7182 21.0625 12 21.0625ZM12 17.0625H20C20.2818 17.0625 20.552 16.9506 20.7513 16.7513C20.9506 16.552 21.0625 16.2818 21.0625 16C21.0625 15.7182 20.9506 15.448 20.7513 15.2487C20.552 15.0494 20.2818 14.9375 20 14.9375H12C11.7182 14.9375 11.448 15.0494 11.2487 15.2487C11.0494 15.448 10.9375 15.7182 10.9375 16C10.9375 16.2818 11.0494 16.552 11.2487 16.7513C11.448 16.9506 11.7182 17.0625 12 17.0625Z"
      fill="#2A3036"
      stroke="#2A3036"
      strokeWidth="0.125"
    />
  </g>
);

export default RuleCategoryIcon;
