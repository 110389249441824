import React from "react";
import Button from "./Button";
import { useTranslation } from "react-i18next";
function Popup(props) {
  const { t } = useTranslation();
  if (props.visible === false) return null;

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="fixed inset-0 w-full h-full bg-dark opacity-70"></div>
      <div className="flex items-center min-h-screen px-4 py-8">
        <form
          className="z-10 flex flex-col w-full max-w-[815px] mx-auto rounded-[10px] justify-center gap-[20px] items-center min-h-min min-w-min bg-white px-[80px] py-[40px]"
          onSubmit={props.submit === false ? undefined : props.methodOK}
        >
          {props.children}
          <p className=" text-dark text-xl text-center leading-[26px] font-semibold">
            {props.content}
          </p>
          <div className="flex flex-row gap-[15px]">
            <Button method={props.method} outline={true} name={t("cancel")} />
            {/*         {props.taslak === true ? (
              <Button
                secondaryGreen={true}
                name={t("draft")}
                method={props.methodTaslak}
              />
            ) : (
              ""
            )} */}

            <Button
              submit={props.submit === false ? false : true}
              green={true}
              method={props.submit === false ? props.methodOK : undefined}
              name={props.buttonName}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Popup;
