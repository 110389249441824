import React from "react";

function RuleListIcon({ active }) {
  const data = active ? filled : outline;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      {data}
    </svg>
  );
}

const outline = (
  <g>
    <path
      d="M25.2702 12.3203H6.74023C4.5311 12.3203 2.74023 14.1112 2.74023 16.3203V26.0003C2.74023 28.2095 4.5311 30.0003 6.74023 30.0003H25.2702C27.4794 30.0003 29.2702 28.2095 29.2702 26.0003V16.3203C29.2702 14.1112 27.4794 12.3203 25.2702 12.3203Z"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.9998 2C18.0525 2 20.0212 2.81546 21.4728 4.26699C22.9243 5.71853 23.7398 7.68722 23.7398 9.74V12.32H8.25977V9.74C8.25977 7.68722 9.07523 5.71853 10.5268 4.26699C11.9783 2.81546 13.947 2 15.9998 2V2Z"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0008 21.9C17.63 21.9 18.9508 20.5792 18.9508 18.95C18.9508 17.3208 17.63 16 16.0008 16C14.3715 16 13.0508 17.3208 13.0508 18.95C13.0508 20.5792 14.3715 21.9 16.0008 21.9Z"
      fill="#2A3036"
    />
    <path
      d="M16 23.3697V17.4697"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);

const filled = (
  <g>
    <path
      d="M25.2702 12.3203H6.74023C4.5311 12.3203 2.74023 14.1112 2.74023 16.3203V26.0003C2.74023 28.2095 4.5311 30.0003 6.74023 30.0003H25.2702C27.4794 30.0003 29.2702 28.2095 29.2702 26.0003V16.3203C29.2702 14.1112 27.4794 12.3203 25.2702 12.3203Z"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 13C4.34315 13 3 14.3431 3 16V27C3 28.6569 4.34315 30 6 30H26C27.6569 30 29 28.6569 29 27V16C29 14.3431 27.6569 13 26 13H6ZM18.95 18.95C18.95 20.2282 18.1371 21.3165 17 21.7262V23.37C17 23.9223 16.5523 24.37 16 24.37C15.4477 24.37 15 23.9223 15 23.37V21.7262C13.8629 21.3166 13.05 20.2282 13.05 18.95C13.05 17.3208 14.3707 16 16 16C17.6292 16 18.95 17.3208 18.95 18.95Z"
      fill="#2A3036"
    />
    <path
      d="M15.9998 2C18.0525 2 20.0212 2.81546 21.4728 4.26699C22.9243 5.71853 23.7398 7.68722 23.7398 9.74V12.32H8.25977V9.74C8.25977 7.68722 9.07523 5.71853 10.5268 4.26699C11.9783 2.81546 13.947 2 15.9998 2V2Z"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);

export default RuleListIcon;
