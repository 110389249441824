import React from "react";

function LicenceCard(props) {
  return (
    <div className="px-[35px] w-full  py-5 border-b border-b-secondaryGray">
      <div className="flex flex-row justify-between  text-dark text-sm leading-[18px] font-semibold">
        <p className="w-1/6 text-start">{props.name}</p>
        <p className="w-1/6 text-center">{props.licenceNumber}</p>
        <p className="w-1/6 text-center">{props.licencePercentage}</p>
        <p className="w-1/6 text-center">{props.date}</p>
        <p className="w-1/6 text-center">{props.type}</p>
        <p className="w-1/6 text-center">{props.payMethod}</p>
      </div>
    </div>
  );
}

export default LicenceCard;
