import RuleCard from "../components/RuleList/RuleCard";
import Header from "../components/Global/Header";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";
import ruleService from "../Services/rule.service";
import userService from "../Services/user.service";
import LoadingIndicator from "../components/Global/LoadingIndicator";
import { toast } from "react-toastify";
import CustomToast from "../components/Global/CustomToast";
import axios from "axios";

function RuleList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [rules, setRules] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [isLoading, setIsLoading] = useState(false);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);

    if (searchInput !== "") {
      const filteredData = rules.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(rules);
    }
  };

  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  useEffect(() => {
    /*     userService.checkUserToken().then((response) => {
      if (response == null) {
        window.location.reload();
      }
    }); */

    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const fetchData = async () => {
      try {
        const response = await ruleService.RuleGetAll(signal);
        setIsLoading(false);
        if (response.status) {
          setRules(response.data);
        } else toastErrorFalse();
      } catch (err) {
        if (err.name === "AbortError" || err.code === "ERR_CANCELED") {
        } else {
          setIsLoading(false);
          toastErrorFalse();
        }
      }
    };
    fetchData();
    return () => controller.abort();
    /* setNewArray(
      rules.map((item, index) => ({
        id: index + 1,
        name: item.consultantEmailAddress,
        checked: false,
      }))
    );
    console.log("arrrr" + newArray); */
  }, []);

  return (
    <div className="px-10 pt-10 w-full min-w-min h-full">
      <CustomToast />
      <div className="bg-white w-full h-full rounded-t-[10px]">
        <Header
          force={forceUpdate}
          rule={true}
          array={rules}
          setArray={setRules}
          searchOnChange={(e) => searchItems(e.target.value)}
          title={t("ruleList.title")}
          navigate={() => {
            navigate("/rule-list/add");
          }}
          buttonName={t("ruleList.newRuleAdd")}
        />
        <div className="py-2 px-10 h-screen overflow-y-scroll no-scrollbar flex flex-col gap-5">
          {searchInput.length > 0
            ? filteredResults.map((value, key) => (
                <RuleCard
                  isActive={value.isActive}
                  key={key}
                  rule={value}
                  rules={rules}
                  setRules={setRules}
                  title={value.name}
                  ruleCategory={value.category}
                  popupMessage={value.popupMessage.map(
                    (msg) => msg.name + ", "
                  )}
                  teamsMessage={value.teamsMessage.map(
                    (msg) => msg.name + ", "
                  )}
                  keywords={value.keyword.map((msg) => msg.name + ", ")}
                />
              ))
            : rules.map((value, key) => (
                <RuleCard
                  isActive={value.isActive}
                  key={key}
                  rule={value}
                  rules={rules}
                  setRules={setRules}
                  title={value.name}
                  ruleCategory={value.category}
                  popupMessage={value.popupMessage.map(
                    (msg) => msg.name + ", "
                  )}
                  teamsMessage={value.teamsMessage.map(
                    (msg) => msg.name + ", "
                  )}
                  keywords={value.keyword.map((msg) => msg.name + ", ")}
                />
              ))}
          {isLoading && <LoadingIndicator isPage={false} />}
        </div>
      </div>
    </div>
  );
}

export default RuleList;
