import React from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "./DatePicker";
function ImpressionCard(props) {
  const { t } = useTranslation();
  return (
    <div className="h-full p-[30px] shadow-[0px_2px_12px_rgba(149,_151,_155,_0.2)]  rounded-[10px] flex flex-col gap-5">
      <div className="flex flex-row justify-between w-full">
        <span
          className="text-dark text-xl leading-5
     font-semibold"
        >
          {props.title}
        </span>
        <DatePicker></DatePicker>
      </div>
      <div className=" w-full gap-[10px] pt-[2px] border-b-dividerGray border-b-2">
        <span className=" text-sm leading-[18px] font-semibold text-darkGray">
          {props.label}
        </span>
        <span className=" text-base leading-4 text-darkGray font-bold">
          {props.number}
        </span>
      </div>
      <div
        className={
          "flex w-full flex-row gap-8 justify-start items-center" +
          (props.green === true ? " text-graphicGreen" : " text-graphicRed")
        }
      >
        <span className="text-sm leading-[18px] font-semibold ">
          {t("dashboard.previous")}
        </span>
        <span className=" text-[40px] leading-[40px] font-bold">
          {props.green === true ? <span>↑</span> : <span>↓</span>} %33
        </span>
      </div>
    </div>
  );
}

export default ImpressionCard;
