import React from "react";
import { useTranslation } from "react-i18next";
import EditableUserInfo from "../Global/EditableUserInfo";

function UserInfo() {
  const { t } = useTranslation();
  return <EditableUserInfo title={t("account.userInfo")}></EditableUserInfo>;
}

export default UserInfo;
