import React from "react";
import PopupMessage from "../Global/MessageForm";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Global/Button";
import InfoPopup from "../Global/InfoPopup";
import teamsMessageService from "../../Services/teamsMessage.service";
import { toast } from "react-toastify";
import CustomToast from "../Global/CustomToast";
import "react-toastify/dist/ReactToastify.css";
import LoadingIndicator from "../Global/LoadingIndicator";

function TeamsMessageEdit(props) {
  const { t } = useTranslation();
  const [createdInfo, setCreatedInfo] = useState(false);
  const [teamsName, setTeamsName] = useState(props.teams.name || "");
  const [ruleCategory, setRuleCategory] = useState(props.teams.categoryId || 0);
  const [message, setMessage] = useState(props.teams.message || "");
  const teamsId = props.teams.id;
  const [isUpdating, setIsUpdating] = useState(false);

  function toastError(label) {
    toast.error(label + t("toast"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const handleSubmitTeams = (event) => {
    event.preventDefault();
    if (message === "") {
      toastError(t("popup.content"));
    } else {
      updateTeams();
    }
  };

  async function updateTeams() {
    setIsUpdating(true);
    const res = await teamsMessageService.UpdateTeamsMessage(
      teamsName,
      ruleCategory,
      message,
      teamsId,
      props.teams.isActive,
      0
    );
    setIsUpdating(false);
    if (!res.status) toastErrorFalse();
    else {
      const arrayupdated = props.teamsArray.map((item) =>
        item.id === props.teams.id ? { ...item, ...res.data } : item
      );
      props.setTeamsArray(arrayupdated);
      setCreatedInfo(true);
    }
  }

  const createdPopupMessage = () => {
    setCreatedInfo(false);
    props.state(false);
  };

  if (props.visible === false) return null;

  return (
    //api get data popup message
    <div className="fixed inset-0 z-10  overflow-y-auto">
      <div className="fixed inset-0 w-full h-full bg-dark opacity-70"></div>
      <CustomToast />
      {isUpdating && <LoadingIndicator />}
      <div className="flex items-center px-4 py-8">
        <div className="z-10 w-full max-w-[1015px] mx-auto min-w-min h-auto rounded-[10px] bg-white">
          <div className=" h-[120px] w-full p-10">
            <p className="text-dark text-[32px] font-bold leading-8">
              {props.title}
            </p>
          </div>
          <form
            className="no-scrollbar overflow-y-auto flex flex-col gap-[40px] pb-[40px]"
            onSubmit={handleSubmitTeams}
          >
            <PopupMessage
              onChange={(e) => setTeamsName(e.target.value)}
              ruleCategoryOnChange={(e) => setRuleCategory(e.target.value)}
              setData={setMessage}
              input={t("teams.name")}
              for="teamsName"
              value={teamsName}
              data={message}
              ruleCategory={ruleCategory}
            />
            <div className="flex flex-row items-center justify-center gap-[15px]">
              <Button method={props.close} outline={true} name={t("cancel")} />
              {/*           <Button secondaryGreen={true} name={t("draft")} /> */}
              {/* Add new popupmessage create mehod as draft */}
              <Button submit={true} green={true} name={t("save")} />
            </div>
          </form>
        </div>
        {createdInfo && (
          <InfoPopup
            visible={createdInfo}
            close={createdPopupMessage}
            content={"“" + teamsName + "” " + t("teams.editInfo")}
          />
        )}
      </div>
    </div>
  );
}

export default TeamsMessageEdit;
