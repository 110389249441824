import React from "react";
import { useState } from "react";
import IconButton from "../Global/IconButton";
import Switch from "react-ios-switch";
import Popup from "../Global/Popup";
import InfoPopup from "../Global/InfoPopup";
import TeamsMessageEdit from "./TeamsMessageEdit";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import teamsMessageService from "../../Services/teamsMessage.service";
import DeleteIcon from "../icons/DeleteIcon";
import EditIcon from "../icons/EditIcon";
import CategoryIcon from "../icons/CategoryIcon";
import PopupDelete from "../icons/PopupDelete";
import { toast } from "react-toastify";
import CustomToast from "../Global/CustomToast";
import LoadingIndicator from "../Global/LoadingIndicator";

function TeamsMessageCard(props) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(props.teams.isActive || false);
  const [deletedPopup, setDeletedPopup] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [infoDeletedPopup, setInfoDeletedPopup] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  async function deleteTeams() {
    setDeletedPopup(false);
    setIsDeleting(true);
    const res = await teamsMessageService.UpdateTeamsMessage(
      props.teams.name,
      props.teams.categoryId,
      props.teams.message,
      props.teams.id,
      props.teams.isActive,
      1
    );
    setIsDeleting(false);
    if (!res.status) toastErrorFalse();
    else {
      // props.setTeamsArray((current) => {
      //   current.filter((team) => team.id !== props.teams.id)
      // });
      const index = props.teamsArray.indexOf(props.teams);
      props.setTeamsArray([
        ...props.teamsArray.slice(0, index),
        ...props.teamsArray.slice(index + 1),
      ]);

      const arrayupdated = props.teamsArray.filter(
        (item) => item.id !== props.teams.id
      );
      props.setTeamsArray(arrayupdated);
      setInfoDeletedPopup(true);
    }
  }
  function toastError(label) {
    toast.error(label, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  async function changeIsActive() {
    setIsDeleting(true);
    const res = await teamsMessageService.UpdateTeamsMessage(
      props.teams.name,
      props.teams.categoryId,
      props.teams.message,
      props.teams.id,
      !props.teams.isActive,
      0
    );
    if(res.status){
      props.teams.isActive = !props.teams.isActive;
      setChecked(!checked);
    } else {
      toastError(t("errorIsActive"));
    }
    setIsDeleting(false);
  }

  return (
    <>
      <div className="relative h-min min-w-[550px] w-full shadow-[0px_2px_12px_rgba(149,_151,_155,_0.2)] px-[30px] pt-[48px] pb-[30px] rounded-[10px] flex flex-col gap-5 ">
        <CustomToast /> {isDeleting && <LoadingIndicator />}
        <p className=" font-bold text-dark text-[22px] leading-[22px]">
          {props.title}
        </p>
        <div className="flex lg:flex-row flex-col justify-between ">
          <div className="flex overflow-y-auto no-scrollbar max-h-[180px]  max-w-[770px]">
            <div className="text-sm leading-[18px] text-dark font-semibold">
              {parse(props.children)}
            </div>
          </div>
          <div className="flex flex-col gap-[20px] items-end justify-end ">
            <Switch
              checked={checked}
              handleColor="white"
              name={undefined}
              offColor="black"
              onChange={() => {
                changeIsActive();
              }}
              onColor="green"
            />
            <div className="flex flex-row gap-[15px] ">
              <IconButton
                className="bg-black"
                method={() => setDeletedPopup(!deletedPopup)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton edit={true} method={() => setEditModal(!editModal)}>
                <EditIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-[10px] px-[30px] py-[15px] items-center text-green absolute top-0 right-0 bg-[#DEF9E3] w-auto  rounded-bl-[10px] rounded-tr-[10px]">
          <CategoryIcon />
          <p className=" text-sm leading-[18px] font-semibold">
            {props.ruleCategory}
          </p>
        </div>
      </div>
      {deletedPopup && (
        <Popup
          submit={false}
          buttonName={t("delete")}
          method={() => setDeletedPopup(false)}
          methodOK={deleteTeams} // add teams card deleted method
          visible={deletedPopup}
          content={"“" + props.title + "” " + t("teams.deletePopup")}
        >
          <PopupDelete />
        </Popup>
      )}
      {infoDeletedPopup && (
        <InfoPopup
          visible={infoDeletedPopup}
          close={() => setInfoDeletedPopup(false)}
          content={t("teams.deletePopupInfo")}
        />
      )}
      {editModal && (
        <TeamsMessageEdit
          teams={props.teams}
          title={t("teams.edit")}
          teamsArray={props.teamsArray}
          setTeamsArray={props.setTeamsArray}
          close={() => setEditModal(false)}
          state={setEditModal}
          visible={editModal}
        />
      )}
    </>
  );
}

export default TeamsMessageCard;
