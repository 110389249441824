import React from "react";

function DashboardCard(props) {
  return (
    <div className="flex w-full h-full flex-col gap-2 p-[30px] shadow-[0px_2px_12px_rgba(149,_151,_155,_0.5)]  rounded-[10px]">
      <div className=" text-dark text-[20px] font-semibold leading-5">
        {props.title}
      </div>
      <div className=" text-sm leading-[18px] text-darkGray font-semibold">
        {props.description}
      </div>
      <div className=" h-full  mt-1 z-0">{props.children}</div>
    </div>
  );
}

export default DashboardCard;
