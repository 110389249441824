import React from "react";

function PopupDelete() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="201"
      height="120"
      viewBox="0 0 201 120"
      fill="none"
    >
      <g clipPath="url(#clip0_1778_12110)">
        <path
          opacity="0.8"
          d="M49.0722 119.619H21.797C21.797 119.619 21.2441 111.504 24.5152 111.412C27.7863 111.32 27.4177 115.009 31.5183 109.937C35.6189 104.865 40.5946 105.141 41.2396 108.138C41.8846 111.136 39.9957 113.533 43.4511 112.795C46.9065 112.058 51.8825 113.994 49.0722 119.619Z"
          fill="#DEF9E3"
        />
        <path
          d="M34.974 119.621L34.8818 119.617C35.0966 114.274 36.2146 110.842 37.1149 108.902C38.0922 106.797 39.034 105.945 39.0435 105.937L39.1048 106.005C39.0955 106.014 38.1648 106.858 37.1952 108.949C36.2999 110.879 35.188 114.298 34.974 119.621Z"
          fill="white"
        />
        <path
          d="M44.8458 119.632L44.7573 119.606C45.4433 117.342 46.6798 115.283 48.3561 113.614L48.4179 113.683C46.7541 115.34 45.5267 117.384 44.8458 119.632Z"
          fill="white"
        />
        <path
          d="M26.0932 119.626L26.0018 119.613C26.2992 117.61 25.9846 115.564 25.0992 113.743C24.7362 112.979 24.2558 112.277 23.6753 111.662L23.7391 111.596C24.3274 112.218 24.8145 112.928 25.1826 113.702C26.076 115.539 26.3934 117.604 26.0932 119.626Z"
          fill="white"
        />
        <path
          d="M52.7738 119.743H18.8553L18.8476 119.628C18.8282 119.342 18.3954 112.594 20.5799 110.187C20.7967 109.933 21.0642 109.726 21.3653 109.582C21.6665 109.437 21.9945 109.357 22.3284 109.347C23.7523 109.307 24.6471 109.84 25.369 110.269C26.7295 111.076 27.628 111.61 30.8958 107.568C34.4356 103.19 38.2551 102.248 40.4507 102.771C41.8693 103.11 42.847 104.067 43.1332 105.396C43.4048 106.655 43.3179 107.838 43.2484 108.789C43.1741 109.806 43.1155 110.609 43.533 110.973C43.8822 111.277 44.5769 111.305 45.7214 111.06C48.0493 110.563 51.2983 110.988 52.9288 113.097C53.806 114.231 54.4972 116.293 52.8079 119.675L52.7738 119.743ZM19.0868 119.496H52.6211C53.8799 116.94 53.9193 114.781 52.7338 113.248C51.2379 111.314 48.0997 110.805 45.7728 111.302C44.5291 111.567 43.7883 111.523 43.371 111.16C42.8606 110.715 42.9233 109.857 43.0024 108.772C43.0707 107.837 43.1555 106.673 42.892 105.449C42.6267 104.216 41.7159 103.328 40.3932 103.012C38.2677 102.505 34.5541 103.435 31.0873 107.724C27.6858 111.931 26.661 111.323 25.2431 110.481C24.5123 110.047 23.6828 109.555 22.3352 109.594C22.0346 109.603 21.7394 109.676 21.4685 109.806C21.1976 109.937 20.957 110.123 20.7623 110.352C18.7995 112.516 19.0389 118.584 19.087 119.496H19.0868Z"
          fill="#2F2E41"
        />
        <path
          d="M163.601 119.88H130.192L130.186 119.693L128.591 74.0342H165.202L163.601 119.88ZM130.567 119.492H163.226L164.801 74.4225H128.992L130.567 119.492Z"
          fill="#3F3D56"
        />
        <path
          d="M138.162 82.4941H135.621V113.964H138.162V82.4941Z"
          fill="#3F3D56"
        />
        <path
          d="M148.167 82.4941H145.626V113.964H148.167V82.4941Z"
          fill="#3F3D56"
        />
        <path
          d="M158.172 82.4941H155.631V113.964H158.172V82.4941Z"
          fill="#3F3D56"
        />
        <path
          d="M186.432 87.4414L186.268 87.3377L144.77 61.0929L148.37 55.3926L148.534 55.4963L190.031 81.7409L186.432 87.4414ZM145.305 60.9722L186.311 86.9056L189.496 81.8619L148.491 55.9282L145.305 60.9722Z"
          fill="#3F3D56"
        />
        <path
          d="M176.412 58.9777C175.206 58.2137 173.806 57.8121 172.379 57.8204C170.951 57.8288 169.556 58.2469 168.359 59.025C167.162 59.8031 166.213 60.9085 165.625 62.2102C165.037 63.5119 164.834 64.9551 165.041 66.3686L167.019 67.6201C166.522 66.4646 166.408 65.1801 166.692 63.9547C166.977 62.7293 167.647 61.6273 168.603 60.8103C169.559 59.9932 170.751 59.5038 172.005 59.4139C173.259 59.3239 174.509 59.6381 175.571 60.3104C176.634 60.9827 177.454 61.9778 177.91 63.15C178.367 64.3222 178.436 65.61 178.109 66.8247C177.781 68.0393 177.074 69.1172 176.09 69.9004C175.106 70.6835 173.898 71.1309 172.641 71.177L174.62 72.4284C175.985 72.0091 177.2 71.2069 178.123 70.1168C179.046 69.0268 179.637 67.6947 179.826 66.2786C180.014 64.8626 179.793 63.422 179.189 62.1279C178.584 60.8338 177.621 59.7405 176.414 58.9777H176.412Z"
          fill="#3F3D56"
        />
        <path d="M187.838 119.611H11.5V120H187.838V119.611Z" fill="#3F3D56" />
        <path
          d="M88.5216 116.962H85.6963L84.3516 106.057H88.5224L88.5216 116.962Z"
          fill="#FEB8B8"
        />
        <path
          d="M80.248 119.588H89.1272V116.154H83.6311C82.7338 116.154 81.8734 116.511 81.2389 117.146C80.6045 117.781 80.248 118.642 80.248 119.54V119.588Z"
          fill="#2F2E41"
        />
        <path
          d="M87.5288 100.063L89.4776 102.11L98.2949 95.5625L95.4188 92.541L87.5288 100.063Z"
          fill="#FEB8B8"
        />
        <path
          d="M91.4851 103.049L87.6927 99.0664L85.209 101.435L91.3356 107.869L91.37 107.836C91.6917 107.529 91.9498 107.162 92.1296 106.755C92.3095 106.349 92.4076 105.911 92.4183 105.466C92.4289 105.022 92.352 104.579 92.1919 104.165C92.0318 103.75 91.7916 103.371 91.4851 103.049V103.049Z"
          fill="#2F2E41"
        />
        <path
          d="M67.8639 70.1854C67.6851 69.8976 67.5715 69.5739 67.5311 69.2374C67.4908 68.9008 67.5247 68.5595 67.6304 68.2375C67.7361 67.9154 67.911 67.6205 68.1429 67.3734C68.3748 67.1263 68.658 66.9331 68.9725 66.8074L80.0647 38.5156L84.3884 41.1065L72.0665 68.2475C72.2426 68.7905 72.2135 69.3793 71.9847 69.9023C71.756 70.4252 71.3435 70.846 70.8254 71.0849C70.3073 71.3238 69.7197 71.3642 69.1738 71.1984C68.628 71.0326 68.1619 70.6722 67.8639 70.1854Z"
          fill="#FEB8B8"
        />
        <path
          d="M109.966 72.2126C109.628 72.1884 109.3 72.0904 109.004 71.9254C108.708 71.7603 108.452 71.5323 108.253 71.2574C108.055 70.9826 107.919 70.6675 107.855 70.3344C107.792 70.0013 107.802 69.6583 107.885 69.3296L91.5215 43.7283L96.1561 41.748L110.868 67.6705C111.411 67.8462 111.871 68.2155 112.16 68.7082C112.449 69.2009 112.547 69.7827 112.436 70.3432C112.325 70.9036 112.012 71.4037 111.557 71.7485C111.101 72.0933 110.536 72.2587 109.966 72.2134V72.2126Z"
          fill="#FEB8B8"
        />
        <path
          d="M80.1738 67.352L83.0703 110.435L89.9923 109.823L91.4174 78.0381L95.2855 91.689L103.633 92.3002L100.329 65.3145L80.1738 67.352Z"
          fill="#2A3036"
        />
        <path
          d="M96.5073 89.6523L95.2851 91.6899L86.7354 97.5984L92.843 100.858C92.843 100.858 104.651 94.3385 103.633 92.301L96.5073 89.6523Z"
          fill="#2F2E41"
        />
        <path
          d="M74.1123 49.9209L76.5219 43.1259C77.058 41.614 77.8883 40.2235 78.9649 39.0349C80.0415 37.8463 81.343 36.8831 82.794 36.2012C86.0554 34.9988 89.6024 34.8045 92.9754 35.6435L93.8523 35.8613C96.1905 36.4285 98.3812 37.4874 100.279 38.9676C101.764 40.139 103.099 41.6223 103.218 43.2177C103.219 43.2211 103.22 43.2244 103.221 43.2276C103.633 45.0316 103.836 54.4038 103.836 54.4038H100.208L100.782 67.036L100.736 66.9446C100.736 66.9446 79.8127 70.9072 79.8127 68.8697V55.8296L79.3838 51.108L74.1123 49.9209Z"
          fill="#CCCCCC"
        />
        <path
          d="M93.6424 33.8779C96.8447 33.8779 99.4407 31.2799 99.4407 28.0752C99.4407 24.8704 96.8447 22.2725 93.6424 22.2725C90.4402 22.2725 87.8442 24.8704 87.8442 28.0752C87.8442 31.2799 90.4402 33.8779 93.6424 33.8779Z"
          fill="#FEB8B8"
        />
        <path
          d="M94.5105 25.4726L99.0127 25.6531C99.5791 25.6514 100.198 25.6314 100.629 25.2649C101.279 24.7117 101.17 23.668 100.822 22.8884C99.8521 20.7175 97.6885 19.9397 95.3127 20.0036C92.9369 20.0675 90.4474 20.8734 89.168 22.8769C87.8886 24.8805 87.5429 27.4105 88.0248 29.7387C88.5819 28.5548 89.4395 27.5379 90.512 26.7895C91.5846 26.0411 92.8345 25.5872 94.1371 25.4734L94.5105 25.4726Z"
          fill="#2F2E41"
        />
        <path
          d="M121.245 82.2317L104.95 70.8019C104.798 70.6953 104.695 70.533 104.663 70.3505C104.631 70.168 104.673 69.9803 104.779 69.8284L117.569 51.5649C117.675 51.4134 117.838 51.3103 118.02 51.2782C118.202 51.2461 118.39 51.2876 118.542 51.3936L134.837 62.8233C134.989 62.93 135.092 63.0923 135.124 63.2748C135.156 63.4572 135.114 63.645 135.008 63.7969L122.219 82.0599C122.112 82.2116 121.95 82.3149 121.768 82.3471C121.585 82.3793 121.397 82.3378 121.245 82.2317Z"
          fill="#F8A085"
        />
        <path
          d="M124.91 69.8295L115.348 63.1228C115.238 63.0452 115.143 62.9466 115.071 62.8326C114.998 62.7186 114.949 62.5914 114.926 62.4583C114.902 62.3252 114.905 62.1888 114.935 62.0569C114.964 61.925 115.019 61.8001 115.096 61.6894C115.174 61.5788 115.272 61.4845 115.386 61.4119C115.5 61.3393 115.627 61.2899 115.76 61.2665C115.893 61.2431 116.03 61.2462 116.161 61.2755C116.293 61.3048 116.418 61.3598 116.529 61.4373L126.091 68.1441C126.314 68.3007 126.466 68.5398 126.513 68.8086C126.56 69.0774 126.499 69.3539 126.342 69.5774C126.186 69.8009 125.947 69.9531 125.679 70.0003C125.41 70.0476 125.134 69.9862 124.91 69.8295Z"
          fill="white"
        />
        <path
          d="M122.716 72.9628L113.154 66.2561C113.043 66.1786 112.949 66.08 112.876 65.966C112.803 65.852 112.754 65.7247 112.73 65.5916C112.707 65.4584 112.71 65.3219 112.739 65.1899C112.769 65.0579 112.824 64.933 112.901 64.8222C112.979 64.7115 113.077 64.6172 113.191 64.5446C113.305 64.472 113.432 64.4226 113.565 64.3993C113.699 64.3759 113.835 64.379 113.967 64.4084C114.099 64.4378 114.223 64.493 114.334 64.5707L123.896 71.2774C124.119 71.4342 124.271 71.6731 124.318 71.9418C124.365 72.2105 124.304 72.4869 124.147 72.7102C123.991 72.9336 123.752 73.0857 123.484 73.1331C123.215 73.1804 122.939 73.1192 122.716 72.9628Z"
          fill="white"
        />
        <path
          d="M120.498 76.1303L110.936 69.4235C110.825 69.346 110.731 69.2474 110.658 69.1334C110.586 69.0194 110.536 68.8922 110.513 68.7591C110.49 68.626 110.493 68.4896 110.522 68.3577C110.551 68.2257 110.606 68.1009 110.684 67.9902C110.761 67.8795 110.86 67.7852 110.974 67.7127C111.088 67.6401 111.215 67.5907 111.348 67.5673C111.481 67.5439 111.617 67.5469 111.749 67.5762C111.881 67.6056 112.005 67.6606 112.116 67.7381L121.678 74.4449C121.901 74.6015 122.053 74.8405 122.101 75.1093C122.148 75.3781 122.086 75.6547 121.93 75.8782C121.773 76.1017 121.535 76.2538 121.266 76.3011C120.997 76.3484 120.721 76.287 120.498 76.1303V76.1303Z"
          fill="white"
        />
        <path
          d="M124.883 63.6312L121.18 61.0334C121.069 60.9559 120.974 60.8573 120.902 60.7433C120.829 60.6293 120.78 60.5021 120.756 60.3689C120.733 60.2357 120.736 60.0993 120.765 59.9673C120.794 59.8353 120.849 59.7103 120.927 59.5996C121.004 59.4889 121.103 59.3945 121.217 59.3219C121.331 59.2494 121.458 59.2 121.591 59.1766C121.724 59.1532 121.861 59.1564 121.993 59.1858C122.125 59.2152 122.249 59.2703 122.36 59.348L126.064 61.9457C126.287 62.1024 126.439 62.3414 126.486 62.6102C126.533 62.879 126.472 63.1556 126.315 63.3791C126.159 63.6026 125.92 63.7547 125.651 63.802C125.383 63.8492 125.107 63.7878 124.883 63.6312Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1778_12110">
          <rect
            width="178.531"
            height="100"
            fill="white"
            transform="translate(11.5 20)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PopupDelete;
