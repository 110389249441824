import React from "react";

function FilterCheckbox(props) {
  if (props.visible === false) return null;
  return (
    <div className="flex flex-row gap-[8px]">
      <label className="text-sm leading-[18px] font-semibold text-textGray flex gap-[8px]">
        <input
          disabled={props.disabled || false}
          id={props.id}
          type="checkbox"
          checked={props.checked}
          value={props.checked}
          onChange={props.onChange}
          className="min-w-[20px] min-h-[20px] box-border accent-[#DEF9E3] rounded-[5px] border-green "
        />
        {props.name}
      </label>
    </div>
  );
}

export default FilterCheckbox;
