import axios from "axios";
import Cookies from 'js-cookie';
const baseDir = process.env.REACT_APP_API_BASE_DIR + "/PopupMessage";

class PopupMessageService {
  async PopupMessageGetAll(signal) {
    const userToken = Cookies.get("userToken");
    /*   const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.post(
        baseDir + "/getall",
        {},
        {
          signal: signal,
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (err) {
      if (err.name === "AbortError") return;
      else {
        throw err;
      }
    }
  }

  async AddPopupMessage(popupName, categoryId, message) {
    const userToken = Cookies.get("userToken");
    /*     const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.post(
        baseDir + "/add",
        {
          name: String(popupName),
          categoryId: categoryId,
          message: String(message),
        },
        {
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }

  async UpdatePopupMessage(
    popupName,
    categoryId,
    message,
    isActive,
    popupId,
    operationId
  ) {
    const userToken = Cookies.get("userToken");
    /*     const userToken = localStorage.getItem("userToken"); */
    try {
      const res = await axios.post(
        baseDir + "/update",
        {
          name: String(popupName),
          categoryId: categoryId,
          message: String(message),
          isActive: isActive,
          id: popupId,
          operationType: operationId,
        },
        {
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }

  async GetByCategoryId(categoryId) {
    const userToken = Cookies.get("userToken");
    /*  const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/getbycategoryid",
        {},
        {
          headers: {
            usertoken: userToken,
          },
          params: {
            categoryId: categoryId,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export default new PopupMessageService();
