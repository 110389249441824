import React from "react";
import IconButton from "../Global/IconButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Popup from "../Global/Popup";
import InfoPopup from "../Global/InfoPopup";
import { useTranslation } from "react-i18next";
import PopupDelete from "../icons/PopupDelete";
import ShowMoreText from "react-show-more-text2";

function LogCard(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="pl-[34px] pr-[36px] w-full py-[10px]  border-b border-b-secondaryGray">
      <div className="flex flex-row justify-between  text-dark text-sm leading-[18px] font-semibold">
        <div className="flex flex-row w-full justify-between items-center h-auto min-h-10">
          <p
            className="w-1/4 text-start"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {props.name}
          </p>
          <p className="w-1/4 text-center">{props.date}</p>
          <p className="w-1/4 text-center">{props.rule}</p>
          <div className="w-1/4 text-center">
            <ShowMoreText
              lines={1}
              more={t("showMore")}
              less={t("showLess")}
              className="content-css inline-block"
              anchorClass="underline text-green"
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              {props.keyword.join(", ")}
            </ShowMoreText>
          </div>
        </div>
        {/* <div className="flex flex-row gap-[15px] min-w-[180px] max-w-[180px] items-center justify-center">
          <IconButton
            edit={true}
            method={() => {}}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="4"
              viewBox="0 0 14 4"
              fill="none"
            >
              <path
                d="M6.8125 2.5C7.22671 2.5 7.5625 2.16421 7.5625 1.75C7.5625 1.33579 7.22671 1 6.8125 1C6.39829 1 6.0625 1.33579 6.0625 1.75C6.0625 2.16421 6.39829 2.5 6.8125 2.5Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.875 2.5C12.2892 2.5 12.625 2.16421 12.625 1.75C12.625 1.33579 12.2892 1 11.875 1C11.4608 1 11.125 1.33579 11.125 1.75C11.125 2.16421 11.4608 2.5 11.875 2.5Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.75 2.5C2.16421 2.5 2.5 2.16421 2.5 1.75C2.5 1.33579 2.16421 1 1.75 1C1.33579 1 1 1.33579 1 1.75C1 2.16421 1.33579 2.5 1.75 2.5Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div> */}
      </div>
    </div>
  );
}

export default LogCard;
