import React from "react";

function AddIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0002 18.1666C14.5105 18.1666 18.1668 14.5103 18.1668 9.99998C18.1668 5.48965 14.5105 1.83331 10.0002 1.83331C5.48984 1.83331 1.8335 5.48965 1.8335 9.99998C1.8335 14.5103 5.48984 18.1666 10.0002 18.1666Z"
        stroke="#000000"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <path
        d="M6.59717 10H13.4027"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6.59723V13.4028"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AddIcon;
