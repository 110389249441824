import React from "react";

function ProfileIcon({ active }) {
  const data = active ? filled : outline;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      {data}
    </svg>
  );
}

const outline = (
  <g>
    <path
      d="M16.0006 13.72C14.8416 13.72 13.7087 13.3763 12.745 12.7324C11.7813 12.0885 11.0302 11.1733 10.5867 10.1025C10.1432 9.03175 10.0271 7.8535 10.2532 6.71677C10.4793 5.58004 11.0374 4.53589 11.857 3.71636C12.6765 2.89682 13.7207 2.33871 14.8574 2.1126C15.9941 1.88649 17.1724 2.00254 18.2432 2.44607C19.3139 2.8896 20.2291 3.64069 20.873 4.60436C21.5169 5.56803 21.8606 6.701 21.8606 7.86C21.858 9.41336 21.2397 10.9023 20.1414 12.0007C19.043 13.0991 17.554 13.7174 16.0006 13.72V13.72ZM16.0006 3.87C15.2095 3.87 14.4361 4.1046 13.7783 4.54412C13.1206 4.98365 12.6079 5.60836 12.3051 6.33927C12.0024 7.07017 11.9231 7.87444 12.0775 8.65036C12.2318 9.42629 12.6128 10.139 13.1722 10.6984C13.7316 11.2578 14.4443 11.6388 15.2203 11.7931C15.9962 11.9475 16.8005 11.8683 17.5314 11.5655C18.2623 11.2628 18.887 10.7501 19.3265 10.0923C19.766 9.43449 20.0006 8.66113 20.0006 7.87C20.0006 6.80914 19.5792 5.79172 18.8291 5.04158C18.0789 4.29143 17.0615 3.87 16.0006 3.87Z"
      fill="#2A3036"
    />
    <path
      d="M27.6994 30.0001H4.29938C4.05179 30.0001 3.81421 29.9024 3.63821 29.7283C3.46221 29.5542 3.36201 29.3176 3.35938 29.0701V26.8001C3.36466 23.5608 4.65382 20.4556 6.94437 18.1651C9.23492 15.8745 12.3401 14.5854 15.5794 14.5801H16.4194C19.6587 14.5854 22.7638 15.8745 25.0544 18.1651C27.3449 20.4556 28.6341 23.5608 28.6394 26.8001V29.0701C28.6367 29.3176 28.5365 29.5542 28.3605 29.7283C28.1845 29.9024 27.947 30.0001 27.6994 30.0001V30.0001ZM5.22938 28.1301H26.7694V26.8001C26.7667 24.0559 25.6754 21.4249 23.735 19.4844C21.7946 17.544 19.1636 16.4527 16.4194 16.4501H15.5794C12.8352 16.4527 10.2042 17.544 8.26374 19.4844C6.32332 21.4249 5.23202 24.0559 5.22938 26.8001V28.1301Z"
      fill="#2A3036"
    />
    <path
      d="M23.9994 26.5803C23.7595 26.5805 23.5289 26.488 23.3555 26.3222C23.1822 26.1563 23.0797 25.9299 23.0694 25.6903C23.048 25.0461 22.8948 24.413 22.6194 23.8303C22.3941 23.3253 21.9923 22.92 21.4894 22.6903C21.3752 22.6471 21.2707 22.5817 21.182 22.4977C21.0934 22.4138 21.0223 22.313 20.973 22.2013C20.9236 22.0896 20.897 21.9692 20.8947 21.8472C20.8924 21.7251 20.9144 21.6038 20.9594 21.4903C21.0027 21.376 21.0681 21.2713 21.1518 21.1823C21.2356 21.0933 21.336 21.0217 21.4475 20.9716C21.5589 20.9214 21.6792 20.8937 21.8013 20.8901C21.9235 20.8864 22.0452 20.9069 22.1594 20.9503C23.0935 21.3287 23.853 22.0418 24.2894 22.9503C24.7125 23.7728 24.9546 24.6764 24.9994 25.6003C25.0089 25.7249 24.9932 25.8501 24.9533 25.9685C24.9133 26.0869 24.8499 26.196 24.7668 26.2893C24.6837 26.3826 24.5827 26.4583 24.4698 26.5117C24.3568 26.5651 24.2343 26.5953 24.1094 26.6003L23.9994 26.5803Z"
      fill="#2A3036"
    />
  </g>
);

const filled = (
  <g>
    <path
      d="M16.0006 13.72C14.8416 13.72 13.7087 13.3763 12.745 12.7324C11.7813 12.0885 11.0302 11.1733 10.5867 10.1025C10.1432 9.03175 10.0271 7.8535 10.2532 6.71677C10.4793 5.58004 11.0374 4.53589 11.857 3.71636C12.6765 2.89682 13.7207 2.33871 14.8574 2.1126C15.9941 1.88649 17.1724 2.00254 18.2432 2.44607C19.3139 2.8896 20.2291 3.64069 20.873 4.60436C21.5169 5.56803 21.8606 6.701 21.8606 7.86C21.858 9.41336 21.2397 10.9023 20.1414 12.0007C19.043 13.0991 17.554 13.7174 16.0006 13.72ZM16.0006 3.87C15.2095 3.87 14.4361 4.1046 13.7783 4.54412C13.1206 4.98365 12.6079 5.60836 12.3051 6.33927C12.0024 7.07017 11.9231 7.87444 12.0775 8.65036C12.2318 9.42629 12.6128 10.139 13.1722 10.6984C13.7316 11.2578 14.4443 11.6388 15.2203 11.7931C15.9962 11.9475 16.8005 11.8683 17.5314 11.5655C18.2623 11.2628 18.887 10.7501 19.3265 10.0923C19.766 9.43449 20.0006 8.66113 20.0006 7.87C20.0006 6.80914 19.5792 5.79172 18.8291 5.04158C18.0789 4.29143 17.0615 3.87 16.0006 3.87Z"
      fill="#2A3036"
    />
    <circle cx="16" cy="8" r="6" fill="#2A3036" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.29938 30.0001H27.6994C27.947 30.0001 28.1845 29.9024 28.3605 29.7283C28.5365 29.5542 28.6367 29.3176 28.6394 29.0701V26.8001C28.6341 23.5608 27.3449 20.4556 25.0544 18.1651C22.7638 15.8745 19.6587 14.5854 16.4194 14.5801H15.5794C12.3401 14.5854 9.23492 15.8745 6.94437 18.1651C4.65382 20.4556 3.36466 23.5608 3.35938 26.8001V29.0701C3.36201 29.3176 3.46221 29.5542 3.63821 29.7283C3.81421 29.9024 4.05179 30.0001 4.29938 30.0001ZM23.3555 26.322C23.5288 26.4878 23.7595 26.5803 23.9994 26.5801L24.1094 26.6001C24.2342 26.5951 24.3568 26.565 24.4697 26.5115C24.5827 26.4581 24.6837 26.3825 24.7668 26.2891C24.8498 26.1958 24.9132 26.0867 24.9532 25.9683C24.9932 25.8499 25.0089 25.7247 24.9994 25.6001C24.9545 24.6762 24.7125 23.7726 24.2894 22.9501C23.853 22.0416 23.0935 21.3285 22.1594 20.9501C22.0451 20.9067 21.9234 20.8862 21.8013 20.8899C21.6791 20.8935 21.5589 20.9212 21.4474 20.9714C21.336 21.0215 21.2355 21.0932 21.1518 21.1822C21.068 21.2712 21.0026 21.3758 20.9594 21.4901C20.9143 21.6036 20.8923 21.7249 20.8947 21.847C20.897 21.9691 20.9236 22.0895 20.9729 22.2011C21.0222 22.3128 21.0933 22.4136 21.182 22.4975C21.2707 22.5815 21.3751 22.6469 21.4894 22.6901C21.9923 22.9198 22.3941 23.3252 22.6194 23.8301C22.8948 24.4129 23.0479 25.0459 23.0694 25.6901C23.0797 25.9297 23.1822 26.1561 23.3555 26.322Z"
      fill="#2A3036"
    />
  </g>
);

export default ProfileIcon;
