import React from "react";
import emailjs from "@emailjs/browser";
import Input from "../components/Global/Input";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import Button from "../components/Global/Button";

function Support() {
  const form = useRef();
  const { t } = useTranslation();
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_4woqebe", //service id
        "template_kxhi9d1", //template id
        form.current,
        "iOP7LOPFS4m5mZ_pE" // public key
      )
      .then(
        (result) => {
          alert("Your message has been successfully sended.");
          setEmail("");
          setFullname("");
          setMessage("");
        },
        (error) => {
          alert("Something went wrong.");
        }
      );
  };
  return (
    <div className="px-10 pt-10 w-full min-w-min h-full">
      <div className="relative bg-white w-full h-full flex flex-col rounded-t-[10px]">
        <div className=" h-[120px] w-full px-10 pt-10  ">
          <p className=" text-start text-[32px] leading-8 font-bold text-dark">
            {t("sidebar.support")}
          </p>
        </div>
        <form
          className="flex flex-col px-10 gap-[50px] max-w-[600px] pb-10"
          ref={form}
          onSubmit={sendEmail}
        >
          <Input
            value={fullname}
            input={t("fullname")}
            name="fullname"
            onChange={(e) => setFullname(e.target.value)}
          />
          <Input
            value={email}
            input={t("email")}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          ></Input>
          <div className="flex flex-col gap-[10px]">
            <label
              className="text-base font-bold leading-4 text-dark"
              for="message"
            >
              {t("message")}
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="border border-darkGray text-dark rounded-[5px] text-sm leading-[18px] font-semibold w-full px-4 py-[15px] "
              rows={6}
              required
              name="message"
            ></textarea>
          </div>
          <Button name={t("send")} full={true} secondaryGreen={true}></Button>
        </form>
      </div>
    </div>
  );
}

export default Support;
