import React from "react";
import RuleCategoryCard from "../components/RuleCategoryList/RuleCategoryCard";
import Header from "../components/Global/Header";
import Popup from "../components/Global/Popup";
import Input from "../components/Global/Input";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ruleCategoryService from "../Services/ruleCategory.service";
import CustomToast from "../components/Global/CustomToast";
import LoadingIndicator from "../components/Global/LoadingIndicator";
import FilterCheckbox from "../components/RuleList/Modal/FilterCheckbox";
import InfoPopup from "../components/Global/InfoPopup";
import { toast } from "react-toastify";

function RuleCategoryList() {
  const { t } = useTranslation();
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [isLogging, setIsLogging] = useState(false);
  const [severityScore, setSeverityScore] = useState(3);

  const [editModalVisible, setEditModalVisible] = useState(false);
  /*   const [errorMessage, setErrorMessage] = useState(""); */
  const [categories, setCategories] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [createdInfo, setCreatedInfo] = useState(false);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = categories.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else setFilteredResults(categories);
  };

  const handleSubmitAddCateory = (event) => {
    event.preventDefault();
    addCategory();
  };

  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  const createdPopupMessage = () => {
    setEditModalVisible(false);
    setCreatedInfo(false);
    setCategoryName("");
    setCategoryDescription("");
    setIsLogging(false);
  };

  async function addCategory() {
    setIsLoading(true);
    ruleCategoryService
      .AddRuleCategory(
        categoryName,
        categoryDescription,
        isLogging,
        severityScore
      )
      .then((res) => {
        setIsLoading(false);
        if (res.status === false) toastErrorFalse();
        else {
          categories.push(res.data);
          setEditModalVisible(false);
          setCreatedInfo(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toastErrorFalse();
      });
  }

  useEffect(() => {
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    /*  if (sessionStorage.getItem("RuleCategoryAll")) {
      setCategories(JSON.parse(sessionStorage.getItem("RuleCategoryAll")));
      setIsLoading(false);
    } */
    const fetchData = async () => {
      try {
        setIsLoading(true); // İstek başlamadan önce loading durumunu true yap
        const response = await ruleCategoryService.RuleCategoryGetAll(signal);
        if (response.status) {
          setCategories(response.data);
        } else {
          toastErrorFalse();
        }
      } catch (err) {
        if (err.name === "AbortError" || err.code === "ERR_CANCELED") {
          // Handle abort or cancellation
        } else {
          setIsLoading(false); // Hata durumunda da loading durumunu false yap
          toastErrorFalse();
        }
      } finally {
        setIsLoading(false); // Asenkron işlem tamamlandığında da loading durumunu false yap
      }
    };
    fetchData();
    return () => controller.abort();
  }, []);

  return (
    <div className="px-10 pt-10 w-full min-w-min h-full">
      <CustomToast />
      <div className="bg-white w-full h-full rounded-t-[10px]">
        <Header
          force={forceUpdate}
          array={categories}
          setArray={setCategories}
          searchOnChange={(e) => searchItems(e.target.value)}
          filterVisible={false}
          title={t("ruleCategory.title")}
          navigate={() => {
            setEditModalVisible(true);
          }}
          buttonName={t("ruleCategory.newCreate")}
        />
        <div className="py-2 px-10 overflow-y-scroll h-screen no-scrollbar flex flex-col gap-5">
          {searchInput.length > 0
            ? filteredResults.map((value, key) => (
                <RuleCategoryCard
                  key={key}
                  ruleCategory={value}
                  categories={categories}
                  setCategories={setCategories}
                  title={value.name}
                  checked={value.isActive}
                >
                  <p> {value.description}</p>
                </RuleCategoryCard>
              ))
            : categories.map((value, key) => (
                <RuleCategoryCard
                  key={key}
                  ruleCategory={value}
                  categories={categories}
                  setCategories={setCategories}
                  title={value.name}
                  checked={value.isActive}
                >
                  <p> {value.description}</p>
                </RuleCategoryCard>
              ))}
          {isLoading && <LoadingIndicator isPage={false} />}
        </div>
      </div>
      {createdInfo && (
        <InfoPopup
          visible={createdInfo}
          close={createdPopupMessage}
          content={"“" + categoryName + "” " + t("teams.newCreateInfo")}
        />
      )}
      {editModalVisible && (
        <Popup
          visible={editModalVisible}
          method={() => {
            setEditModalVisible(false);
            setCategoryName("");
            setCategoryDescription("");
            setIsLogging(false);
            setSeverityScore(3);
          }}
          methodOK={handleSubmitAddCateory}
          buttonName={t("add")}
        >
          <div className="flex flex-col gap-7 w-full">
            {isLoading && <LoadingIndicator />}
            <Input
              input={t("sidebar.ruleCategory")}
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              for="ruleCategory"
            />
            <div className="flex flex-col gap-[10px]">
              <label
                htmlFor="ruleDescription"
                className=" text-base font-bold leading-4 text-dark"
              >
                {t("ruleCategory.description")}
              </label>
              <label className="relative block">
                <textarea
                  type="text"
                  id="ruleDescription"
                  value={categoryDescription}
                  onChange={(e) => setCategoryDescription(e.target.value)}
                  className=" border min-h-[50px] border-darkGray text-dark rounded-[5px] text-sm leading-[18px] font-semibold height-[50px] w-full px-4 py-[15px] "
                  required
                />
              </label>
            </div>
            <FilterCheckbox
              checked={isLogging}
              name={t("ruleList.logging")}
              onChange={() => setIsLogging(!isLogging)}
            />
            <div className="flex flex-col gap-[10px] w-full ">
              <div className="flex flex-row gap-3">
                <label className=" text-base font-bold leading-4 text-dark">
                  {t("ruleList.categorySeverityScore")}
                </label>
                <div className="group relative">
                  <svg
                    className="fill-current h-5 w-5 text-dark mr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                  <span className="absolute w-[250px] bottom-0 left-6 scale-0 transition-all rounded bg-gray-800 bg-opacity-90 p-2 text-xs text-white group-hover:scale-100">
                    {t("ruleList.categoryScoreDesc")}
                  </span>
                </div>
              </div>
              <div className="flex flex-col space-y-2 p-2 w-80">
                <input
                  className="accent-green"
                  type="range"
                  value={severityScore}
                  min="1"
                  max="5"
                  step="1"
                  onChange={(e) => {
                    setSeverityScore(e.target.value);
                  }}
                />
                <div className="flex justify-between w-full px-[10px]">
                  <li className="flex justify-center ">1</li>
                  <li className="flex justify-center ">2</li>
                  <li className="flex justify-center ">3</li>
                  <li className="flex justify-center ">4</li>
                  <li className="flex justify-center ">5</li>
                </div>
                <div className="flex justify-between w-full text-xs font-semibold text-darkGray  ">
                  <div className="w-1/5 flex justify-start">
                    {t("ruleList.tooLow")}
                  </div>
                  <div className="w-1/5 flex justify-center">
                    {t("ruleList.low")}
                  </div>
                  <div className="w-1/5 flex justify-center">
                    {t("ruleList.medium")}
                  </div>
                  <div className="w-1/5 flex justify-center">
                    {t("ruleList.high")}
                  </div>
                  <div className="w-1/5 flex justify-end">
                    {t("ruleList.tooHigh")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
}

export default RuleCategoryList;
