import React from "react";
import loginImage from "../images/LoginImage.png";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginForm from "../components/Login/LoginForm.jsx";
import NotFound from "./NotFound";

function Login() {
  return (
    <BrowserRouter>
      <div className="flex min-w-[210px] w-full px-4 sm:px-0 min-h-[650px] h-screen">
        <img
          className="hidden  lg:flex w-max-[700px] w-1/2 w-min-[400px] object-cover"
          src={loginImage}
          alt="loginImage"
        />
        <div className="lg:w-1/2 w-full h-auto flex justify-center my-auto  ">
          <Routes>
            {/* <Route path="*" element={<NotFound login={true} />} /> */}
            <Route path="*" element={<Navigate to="/login" replace />} />
            <Route exact path="/login" element={<LoginForm />} />
            {/*   <Route path="/forget-password" element={<ForgetPasswordForm />} />
            <Route
              path="/login-verification-code"
              element={<LoginVerificationCodeForm />}
            />
            <Route
              path="/create-new-password"
              element={<CreateNewPasswordForm />}
            />
            <Route
              path="/email-verification-code"
              element={<EmailVerificationCodeForm />}
            /> */}
            {/* <Route path="/" element={<Navigate to="/login" replace />} /> */}
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default Login;
