import React from "react";

function FilterIcon({ active }) {
  const data = active ? "white" : "#2A3036";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
    >
      <path
        d="M11.6785 17.8224C11.7202 18.1372 11.616 18.473 11.3765 18.6933C10.9703 19.1025 10.3142 19.1025 9.90798 18.6933L5.73159 14.4859C5.49204 14.2446 5.38789 13.9193 5.42955 13.615V8.24292L0.440794 1.81109C0.0866863 1.35992 0.170006 0.698901 0.617848 0.34216C0.815732 0.195267 1.03445 0.111328 1.26357 0.111328H15.8445C16.0736 0.111328 16.2923 0.195267 16.4902 0.34216C16.9381 0.698901 17.0214 1.35992 16.6673 1.81109L11.6785 8.24292V17.8224ZM3.38822 2.2098L7.51254 7.51894V13.3107L9.59553 15.4092V7.50845L13.7198 2.2098H3.38822Z"
        fill={data}
      />
    </svg>
  );
}

export default FilterIcon;
