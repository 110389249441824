import React, { useState, useEffect } from "react";
import DashboardCard from "../components/Dashboard/DashboardCard";
import CardbyCategory from "../components/Dashboard/CardbyCategory";
import SummaryCard from "../components/Dashboard/SummaryCard";
import LineChart from "../components/Dashboard/LineChart";
import ColumnChart from "../components/Dashboard/ColumnChart";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "../styles/dashboard.css";
import "react-calendar/dist/Calendar.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import dashboardService from "../Services/dashboard.service";
import LoadingIndicator from "../components/Global/LoadingIndicator";
import HorizontalBarCard from "../components/Dashboard/HorizontalBarCard";
import CalendarIcon from "../components/icons/Calendar";

function Dashboard() {
  const [value, onChange] = useState([
    new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
    new Date(),
  ]);

  const { t } = useTranslation();

  const [summary, setSummary] = useState(null);

  const [weekly, setWeekly] = useState(null);
  const [keywords, setKeywords] = useState(null);
  const [bar, setBar] = useState([]);
  const [score, setScore] = useState(null);

  const [isLoadingSummary, setIsLoadingSummary] = useState(false);
  const [isLoadingWeekly, setIsLoadingWeekly] = useState(false);
  const [isLoadingKeyword, setIsLoadingKeyword] = useState(false);
  const [isLoadingBar, setIsLoadingBar] = useState(false);
  const [isLoadingScore, setIsLoadingScore] = useState(false);

  const transformedData = (data) => {
    const categoryColors = [
      "#549BF2",
      "#FAB338",
      "#F8A085",
      "#F8A0FF",
      "#90CAF9",
    ];

    const labels = [
      ...new Set(data.flatMap((item) => item.weeks.map((week) => week.week))),
    ]
      .sort((a, b) => a - b)
      .slice(0, 6);
    const labelsText = labels.map((a) => `${a}. hafta`);

    const datasets = data.map((item, index) => {
      const weekData = item.weeks.reduce((acc, week) => {
        acc[week.week] = week.ruleCount;
        return acc;
      }, {});

      const dataWithZero = labels.map((week) => weekData[week]);

      return {
        label: item.categoryName,
        data: dataWithZero,
        borderColor: categoryColors[index % categoryColors.length],
        backgroundColor: categoryColors[index % categoryColors.length],
        pointRadius: 4,
      };
    });

    const transformed = {
      labels: labelsText,
      datasets: datasets,
    };
    return transformed;
  };

  function transformBarData(data) {
    const categoryColors = [
      "#549BF2",
      "#FAB338",
      "#F8A085",
      "#F8A0FF",
      "#90CAF9",
    ];

    const transformedData = {
      name: "Rules",
      data: [],
    };

    data.forEach((category, index) => {
      const fillColor = categoryColors[index % categoryColors.length];

      category.rules.forEach((rule) => {
        transformedData.data.push({
          x: rule.ruleName,
          y: rule.ruleCount,
          fillColor: fillColor,
        });
      });
    });

    return transformedData;
  }

  function generateGroups(data) {
    return data.map((category) => ({
      title: category.categoryName == null ? "null" : category.categoryName,
      cols: category.rules.length,
    }));
  }

  function transformKeywordData(data) {
    data = data.sort((a, b) => b.keywordCount - a.keywordCount).slice(0, 6);
    const keywordCounts = {
      data: data.map((item) => item.keywordCount),
    };

    const keywordNames = data.map((item) => item.keywordName);

    return { keywordCounts: keywordCounts, keywordNames: keywordNames };
  }

  const getSum = async (signal) => {
    setIsLoadingSummary(true);

    try {
      const res = await dashboardService.GetSummary(value[0], value[1], signal);
      setIsLoadingSummary(false);
      if (res.status) {
        setSummary(res.data);
      } else {
        setSummary(null);
      }
    } catch (err) {
      if (err.name === "AbortError" || err.code === "ERR_CANCELED") {
      } else {
        setIsLoadingSummary(false);
      }
    }
  };

  function handleSelectCategory() {
    getKeywordsByCategory();
  }

  const getKeywordsByCategory = async (signal) => {
    const queryParameters = new URLSearchParams(window.location.search);
    setIsLoadingKeyword(true);

    try {
      const res = await dashboardService.GetKeywordsByCategory(
        value[0],
        value[1],
        queryParameters.get("category"),
        signal
      );

      setIsLoadingKeyword(false);
      if (res.status) {
        setKeywords(transformKeywordData(res.data));
      } else {
        setKeywords(null);
      }
    } catch (err) {
      if (err.name === "AbortError" || err.code === "ERR_CANCELED") {
      } else {
        setIsLoadingKeyword(false);
      }
    }
  };

  const getWeekly = async (signal) => {
    setIsLoadingWeekly(true);

    try {
      const res = await dashboardService.GetWeeklyRuleCount(
        value[0].toISOString().toString(),
        value[1].toISOString().toString(),
        signal
      );

      setIsLoadingWeekly(false);
      if (res.status) {
        setWeekly(transformedData(res.data));
      } else {
        setWeekly(null);
      }
    } catch (err) {
      if (err.name === "AbortError" || err.code === "ERR_CANCELED") {
      } else {
        setIsLoadingWeekly(false);
      }
    }
  };

  const getBar = async (signal) => {
    setIsLoadingBar(true);

    try {
      const res = await dashboardService.GetRuleCount(
        value[0],
        value[1],
        signal
      );
      setIsLoadingBar(false);
      if (res.status) {
        setBar({
          data: [transformBarData(res.data)],
          groups: generateGroups(res.data),
        });
      } else {
        setBar(null);
      }
    } catch (err) {
      if (err.name === "AbortError" || err.code === "ERR_CANCELED") {
      } else {
        setIsLoadingBar(false);
      }
    }
  };

  const getScore = async (signal) => {
    setIsLoadingScore(true);
    try {
      const res = await dashboardService.GetScore(value[0], value[1], signal);
      setIsLoadingScore(false);
      if (res.status) {
        setScore(res.data);
      } else {
        setScore(null);
      }
    } catch (err) {
      if (err.name === "AbortError" || err.code === "ERR_CANCELED") {
      } else {
        setIsLoadingScore(false);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getSum(signal);
    getWeekly(signal);
    getBar(signal);
    getScore(signal);
    getKeywordsByCategory(signal);
    return () => controller.abort();
  }, []);

  useEffect(() => {
    getSum();
    getWeekly();
    getBar();
    getScore();
    getKeywordsByCategory();
  }, [value]);

  const handleDateChange = (newValue) => {
    newValue[0] = new Date(newValue[0].setHours(0, 0, 0, 0));
    newValue[1] = new Date(newValue[1].setHours(23, 59, 59, 0));
    onChange(newValue);
  };

  return (
    <div className="px-10 pt-10 w-full h-full">
      <div className="bg-white w-full h-full rounded-t-[10px]">
        <div className=" h-[120px] w-full flex flex-row justify-between items-center p-10">
          <p className="flex text-dark text-[32px] font-bold leading-8">
            Dashboard
          </p>
          <div className="border-secondaryGray border-2  h-10 flex justify-center items-center rounded-[3px]  shadow-[0px_2px_12px_rgba(149,_151,_155,_0.2)]">
            <DateRangePicker
              iconPosition="left"
              className={"react-daterange-picker__wrapper"}
              onChange={handleDateChange}
              calendarIcon={<CalendarIcon />}
              clearIcon={null}
              value={value}
              required={true}
            />
          </div>
        </div>
        <div className="py-2 px-10 h-screen overflow-y-scroll no-scrollbar flex flex-col gap-5">
          <div
            className="bg-white border-t-4 border-graphicGreen rounded-b text-black px-4 py-3 shadow-md"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <svg
                  className="fill-current h-6 w-6 text-graphicGreen mr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                <p className="font-bold">{t("dashboard.notificationTitle")}</p>
                <p className="text-sm">{t("dashboard.notificationDesc")}</p>
              </div>
            </div>
          </div>
          <div className="w-full  h-auto flex flex-row gap-4">
            <div className="w-1/4 h-full">
              <DashboardCard
                title={t("dashboard.totalScore")}
                description={t("dashboard.totalScoreDesc")}
              >
                <div
                  className={
                    isLoadingScore
                      ? "flex "
                      : score &&
                        score.filter((item) => item.categoryName === "Total")[0]
                          .score >= 85
                      ? "flex text-5xl leading-[48px] text-graphicGreen font-semibold"
                      : score
                      ? "flex text-5xl leading-[48px] text-[#F0054B] font-semibold"
                      : "text-md  font-semibold text-red"
                  }
                >
                  {isLoadingScore ? (
                    <LoadingIndicator isPage={false} />
                  ) : score ? (
                    score.filter((item) => item.categoryName === "Total")[0]
                      .score
                  ) : (
                    t("error.noData")
                  )}
                </div>
              </DashboardCard>
            </div>
            <div className="w-3/4  h-full">
              <DashboardCard
                title={t("dashboard.scoreByCategory")}
                description={t("dashboard.sliderDesc")}
              >
                {isLoadingScore ? (
                  <LoadingIndicator isPage={false} />
                ) : (
                  <div className="flex w-full flex-row xl:flex-nowrap gap-[19px] flex-wrap">
                    {/* <div
                    className="flex-none cursor-pointer"
                    ref={navigationPrevRef}
                  >
                    <img
                      src={arrowLeft}
                      className=" flex-none w-[9px] h-[17px]"
                      alt="left"
                    />
                  </div> */}
                    {/* <Swiper
                    breakpoints={{
                      640: {
                        width: 140,
                        slidesPerView: 1,
                      },
                      768: {
                        width: 270,
                        slidesPerView: 2,
                      },
                      1024: {
                        width: 380,
                        slidesPerView: 3,
                      },
                      1280: {
                        width: 700,
                        slidesPerView: 3,
                      },
                      1536: {
                        width: 1000,
                        slidesPerView: 5,
                      },
                    }}
                    spaceBetween={30}
                    // freeMode={true}
                    navigation={{
                      prevEl: navigationPrevRef.current,
                      nextEl: navigationNextRef.current,
                    }}
                    onSwiper={(swiper) => {
                      setTimeout(() => {
                        swiper.params.navigation.prevEl =
                          navigationPrevRef.current;
                        swiper.params.navigation.nextEl =
                          navigationNextRef.current;
                        swiper.navigation.destroy();
                        swiper.navigation.init();
                        swiper.navigation.update();
                      });
                    }}
                    modules={[FreeMode, Pagination, Navigation]}
                    className="mySwiper"
                  >
                    { score && score.map((item, index) => (
                      <SwiperSlide key={index}>
                        <CardbyCategory
                          title={item.categoryName}
                          green={item.score >= 80 ? true : false}
                          score={item.score == -1 ? "-" : item.score}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper> */}
                    {score != null && score.length > 0 ? (
                      score
                        .filter((s) => s.categoryId != 0)
                        .map((item, index) => (
                          <CardbyCategory
                            key={index}
                            title={item.categoryName}
                            green={item.score >= 85 ? true : false}
                            score={item.score}
                          />
                        ))
                    ) : (
                      <div>
                        <h1 className=" text-md text-center font-semibold text-red">
                          {t("error.noData")}
                        </h1>
                      </div>
                    )}
                    {/* <div
                    className="flex-none cursor-pointer"
                    ref={navigationNextRef}
                  >
                    <img
                      src={arrowRigt}
                      className=" w-[9px] h-[17px]"
                      alt="right"
                    />
                  </div> */}
                  </div>
                )}
              </DashboardCard>
            </div>
          </div>
          <div className="w-full h-auto flex">
            <DashboardCard
              title={t("dashboard.summary")}
              description={t("dashboard.summaryDesc")}
            >
              {isLoadingSummary ? (
                <LoadingIndicator isPage={false} />
              ) : summary ? (
                <div className="flex w-full flex-row xl:flex-nowrap gap-[19px] flex-wrap">
                  <SummaryCard
                    title={summary.numOfTotalLogs}
                    define={" " + t("dashboard.checked")}
                  />
                  <SummaryCard
                    title={summary.numOfRules}
                    define={" " + t("dashboard.wereStuck")}
                  />
                  <SummaryCard
                    title={summary.numOfUsers}
                    define={" " + t("dashboard.stuckToTheRule")}
                  />
                  <SummaryCard
                    title={summary.numOfDistinctRules}
                    define={" " + t("dashboard.differentRule")}
                  />
                  <SummaryCard
                    title={summary.numOfPopups}
                    define={" " + t("dashboard.wereShown")}
                  />
                  <SummaryCard
                    title={summary.numOfKeywords}
                    define={" " + t("dashboard.wereCaptured")}
                  />
                </div>
              ) : (
                <div>
                  <h1 className="text-md text-center font-semibold text-red">
                    {t("error.noData")}
                  </h1>
                </div>
              )}
            </DashboardCard>
          </div>
          <div className="w-full  h-auto flex">
            <DashboardCard
              title={t("dashboard.lineChartTitle")}
              description={t("dashboard.lineChartDesc")}
            >
              {isLoadingWeekly ? (
                <LoadingIndicator isPage={false} />
              ) : (
                <LineChart data={weekly} />
              )}
            </DashboardCard>
          </div>
          <div className="w-full h-auto flex">
            <DashboardCard
              title={t("dashboard.columChartTitle1")}
              description={t("dashboard.columChartDescr")}
            >
              {isLoadingBar ? (
                <LoadingIndicator isPage={false} />
              ) : (
                <ColumnChart data={bar} />
              )}
            </DashboardCard>
          </div>
          {/* <div className="w-full h-auto flex">
            <DashboardCard
              title={t("dashboard.columnChartTitle2")}
              description={t("dashboard.columnChartDesc2")}
            >
              {isLoadingBar ? (
                <LoadingIndicator isPage={false} />
              ) : (
                <ColumnChart data={bar} />
              )}
            </DashboardCard>
          </div> */}
          <div className="w-full h-auto">
            <HorizontalBarCard
              data={keywords}
              handleSelectCategory={handleSelectCategory}
              isLoading={isLoadingKeyword}
            ></HorizontalBarCard>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
