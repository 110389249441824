import React, { useEffect } from "react";
import PopupMessage from "../Global/MessageForm";
import { useTranslation } from "react-i18next";
import Button from "../Global/Button";
import InfoPopup from "../Global/InfoPopup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import popupMessageService from "../../Services/popupMessage.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomToast from "../Global/CustomToast";
import LoadingIndicator from "../Global/LoadingIndicator";

function NewPopupMessageCreate(props) {
  const { t } = useTranslation();
  const [createdInfo, setCreatedInfo] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [ruleCategory, setRuleCategory] = useState(props.ruleCategory);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function toastError(label) {
    toast.error(label + t("toast"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const navigate = useNavigate();

  const goPopupList = () => {
    navigate("/popup-message-list");
  };

  const handleSubmitPopup = (event) => {
    event.preventDefault();
    if (message === "") {
      toastError(t("popup.content"));
    } else {
      addPopup();
    }
  };
  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  async function addPopup() {
    setIsLoading(true);
    popupMessageService
      .AddPopupMessage(popupName, ruleCategory, message)
      .then((res) => {
        setIsLoading(false);
        if (res.status === false) {
          toastErrorFalse();
        } else {
          if (props.rule) {
            const value = res.data;
            if (props.array.includes(value) === false) {
              props.array.push(value);
            }
            setCreatedInfo(true);
          }
          setCreatedInfo(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toastErrorFalse();
      });
  }

  const createdPopupMessage = () => {
    setCreatedInfo(false);
    setPopupName("");
    setRuleCategory();
    setMessage("");
    if (props.rule) {
      props.setState(false);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (props.rule) {
      setRuleCategory(props.ruleCategory);
    }
  }, [props.rule, props.ruleCategory]);

  if (props.rule && props.visible) {
    return (
      <div className="fixed inset-0 z-10  overflow-y-auto">
        <div className="fixed inset-0 w-full h-full bg-dark opacity-70"></div>
        <CustomToast />
        {isLoading && <LoadingIndicator />}
        <div className="flex items-center px-4 py-8">
          <div className="z-10 w-full max-w-[1015px] mx-auto min-w-min h-auto rounded-[10px] bg-white">
            <div className=" h-[120px] w-full p-10">
              <p className="text-dark text-[32px] font-bold leading-8">
                {t("popup.newCreate")}
              </p>
            </div>
            <form
              className="no-scrollbar overflow-y-auto flex flex-col gap-[40px] pb-[40px]"
              onSubmit={handleSubmitPopup}
            >
              <PopupMessage
                disabled={true}
                input={t("popup.name")}
                value={popupName}
                onChange={(e) => setPopupName(e.target.value)}
                ruleCategory={ruleCategory}
                ruleCategoryOnChange={(e) => setRuleCategory(e.target.value)}
                setData={setMessage}
                for="popupName"
              />
              <div className="flex flex-row items-center justify-center gap-[15px]">
                <Button
                  method={props.close}
                  outline={true}
                  name={t("cancel")}
                />
                {/*      <Button secondaryGreen={true} name={t("draft")} /> */}
                <Button submit={true} green={true} name={t("save")} />
              </div>
            </form>
          </div>
          {createdInfo && (
            <InfoPopup
              visible={createdInfo}
              close={createdPopupMessage}
              content={"“" + popupName + "” " + t("popup.newCreateInfo")}
            />
          )}
        </div>
      </div>
    );
  } else if (props.visible === false) return null;
  else {
    return (
      <div className="px-10 pt-10 w-full min-w-[600px] h-full">
        <CustomToast />
        {isLoading && <LoadingIndicator />}
        <div className="bg-white w-full h-full rounded-t-[10px]">
          <div className=" h-[120px] w-full p-10">
            <p className="text-dark text-[32px] font-bold leading-8">
              {t("popup.newCreate")}
            </p>
          </div>
          <form
            className="no-scrollbar overflow-y-auto flex flex-col gap-[40px] pb-[40px]"
            onSubmit={handleSubmitPopup}
          >
            <PopupMessage
              input={t("popup.name")}
              value={popupName}
              onChange={(e) => setPopupName(e.target.value)}
              ruleCategory={ruleCategory}
              ruleCategoryOnChange={(e) => setRuleCategory(e.target.value)}
              setData={setMessage}
              for="popupName"
            />
            <div className="flex flex-row items-center justify-center gap-[15px]">
              <Button method={goPopupList} outline={true} name={t("cancel")} />
              {/*    <Button secondaryGreen={true} name={t("draft")} /> */}
              <Button submit={true} green={true} name={t("save")} />
            </div>
          </form>
        </div>
        {createdInfo && (
          <InfoPopup
            visible={createdInfo}
            close={createdPopupMessage}
            content={"“" + popupName + "” " + t("popup.newCreateInfo")}
          />
        )}
      </div>
    );
  }
}

export default NewPopupMessageCreate;
