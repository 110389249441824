import logo from "../../images/logo.svg";
import { useState } from "react";
import UserService from "../../Services/user.service";
import Input from "../Global/Input";
import Button from "../Global/Button.jsx";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingIndicator from "../Global/LoadingIndicator";

function LoginForm() {
  const { t } = useTranslation();
  const [mail, setMail] = useState("");
  const [pass, setPass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isReCapthcaOk, setIsReCapthcaOk] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function login() {
    if (true) {
      setIsLoading(true);
      await UserService.login(mail, pass)
        .then((res) => {
          setIsLoading(false);
          if (res.status === false) {
            setErrorMessage(res.message);
          } else {
            window.location.replace("/home");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      setErrorMessage(t("login.reCaptchaError"));
    }
  }

  const checkReCaptcha = (token) => {
    UserService.checkReCaptcha(token).then((res) => {
      if (res === true) {
        setIsReCapthcaOk(true);
      } else {
        setIsReCapthcaOk(false);
      }
    });
  };

  return (
    <div className="flex flex-col max-w-[430px] w-full items-start  gap-[70px] justify-center">
      <img className="w-[360px] h-[60px]" src={logo} alt="NotisecureLogo" />
      <div className="flex flex-col gap-[50px] w-full ">
        <p className="font-bold text-[22px] leading-[22px] text-dark opacity-60">
          {t("login.management")}
        </p>
        {errorMessage === "" ? null : (
          <p className=" text-red font-semibold text-base leading-4 ">
            {errorMessage}
          </p>
        )}
        <div className=" flex flex-col gap-[40px]">
          <Input
            for="username"
            input={t("username")}
            placeholder={t("usernamePlaceholder")}
            onChange={(e) => setMail(e.target.value)}
            type="text"
          />
          <div className="flex flex-col gap-[10px]">
            <Input
              for="password"
              icon="true"
              input={t("password")}
              type="password"
              onChange={(e) => {
                setPass(e.target.value);
              }}
              placeholder="********"
            />
            {/*  <a
              href="/forgetPassword"
              className="flex underline justify-end text-dark text-base leading-4 font-semibold "
            >
              {t("login.forget")}
            </a> */}
          </div>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={(value) => {
              checkReCaptcha(value);
            }}
            onExpired={() => {
              setIsReCapthcaOk(false);
            }}
          />
        </div>
      </div>
      <div className="flex items-center justify-center w-full">
        <Button green={true} method={login} name={t("login.login")} />
      </div>
      {isLoading && <LoadingIndicator size={150} />}
    </div>
  );
}
export default LoginForm;
