import React from "react";
import { useState } from "react";
import IconButton from "../Global/IconButton";
import Switch from "react-ios-switch";
import Popup from "../Global/Popup";
import RuleEditModal from "./Modal/RuleEditModal";
import InfoPopup from "../Global/InfoPopup";
import ShowMoreText from "react-show-more-text2";
import { useTranslation } from "react-i18next";
import ruleService from "../../Services/rule.service";
import DeleteIcon from "../icons/DeleteIcon";
import EditIcon from "../icons/EditIcon";
import CategoryIcon from "../icons/CategoryIcon";
import PopupDelete from "../icons/PopupDelete";
import { toast } from "react-toastify";
import CustomToast from "../Global/CustomToast";
import LoadingIndicator from "../Global/LoadingIndicator";

function RuleCard(props) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(props.isActive);
  const [deletedPopup, setDeletedPopup] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [infoDeletedPopup, setInfoDeletedPopup] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  async function deleteRule() {
    setIsDeleting(true);
    const res = await ruleService.UpdateRule(
      props.rule.name,
      props.rule.categoryId,
      props.rule.reminderPeriod,
      props.rule.reminderCount,
      props.rule.holdingPeriod,
      props.rule.isActive,
      props.rule.isAdvisor,
      props.rule.isEducation,
      props.rule.isAnonym,
      props.rule.regex,
      props.rule.color,
      props.rule.severityScore,
      props.rule.consultantEmailAddress,
      props.rule.educationLink,
      props.rule.keyword.map((item) => parseInt(item.id)),
      props.rule.popupMessage.map((item) => parseInt(item.id)),
      props.rule.teamsMessage.map((item) => parseInt(item.id)),
      props.rule.userGroup.map((item) => parseInt(item.id)),
      props.rule.id,
      1
    );
    setIsDeleting(false);
    if (!res.status) toastErrorFalse();
    else {
      const index = props.rules.indexOf(props.rule);
      props.setRules([
        ...props.rules.slice(0, index),
        ...props.rules.slice(index + 1),
      ]);
      setDeletedPopup(false);
      setInfoDeletedPopup(true);
    }
  }

  async function changeIsActive() {
    setIsDeleting(true);
    props.rule.isActive = !props.rule.isActive;
    const res = await ruleService.UpdateRule(
      props.rule.name,
      props.rule.categoryId,
      props.rule.reminderPeriod,
      props.rule.reminderCount,
      props.rule.holdingPeriod,
      props.rule.isActive,
      props.rule.isAdvisor,
      props.rule.isEducation,
      props.rule.isAnonym,
      props.rule.regex,
      props.rule.color,
      props.rule.severityScore,
      props.rule.consultantEmailAddress,
      props.rule.educationLink,
      props.rule.keyword.map((item) => parseInt(item.id)),
      props.rule.popupMessage.map((item) => parseInt(item.id)),
      props.rule.teamsMessage.map((item) => parseInt(item.id)),
      props.rule.userGroup.map((item) => parseInt(item.id)),
      parseInt(props.rule.id),
      parseInt(0)
    );
    setIsDeleting(false);
    if (!res.status) toastErrorFalse();
  }

  return (
    <>
      <CustomToast />
      <div className="relative h-min min-w-[550px] w-full shadow-[0px_2px_12px_rgba(149,_151,_155,_0.2)] px-[30px] pt-[48px] pb-[30px] rounded-[10px] flex flex-col gap-5 ">
        {isDeleting && <LoadingIndicator />}
        <p className=" font-bold text-dark text-[22px] leading-[22px]">
          {props.title}
        </p>
        <div className="flex lg:flex-row flex-col justify-between">
          <div className="flex flex-col gap-[15px] ">
            <div className=" font-bold text-green text-sm leading-[14px]">
              {t("ruleList.popupFor") + ": "}
              <span className="text-sm leading-[18px] text-dark font-semibold">
                <ShowMoreText
                  lines={1}
                  more={t("showMore")}
                  less={t("showLess")}
                  className="content-css inline-block"
                  anchorClass="underline text-green"
                  expanded={false}
                  width={800}
                  truncatedEndingComponent={"... "}
                >
                  {props.popupMessage}{" "}
                </ShowMoreText>
              </span>
            </div>
            <div className=" font-bold text-green text-sm leading-[14px]">
              {t("ruleList.teamsFor") + ": "}
              <span className="text-sm leading-[18px] text-dark font-semibold">
                <ShowMoreText
                  lines={1}
                  more={t("showMore")}
                  less={t("showLess")}
                  className="content-css inline-block"
                  anchorClass="underline text-green"
                  expanded={false}
                  width={800}
                  truncatedEndingComponent={"... "}
                >
                  {props.teamsMessage}
                </ShowMoreText>
              </span>
            </div>
            <div className=" font-bold text-green text-sm leading-[14px]">
              {t("ruleList.keys") + ": "}
              <span className="text-sm leading-[18px] text-dark font-semibold">
                <ShowMoreText
                  lines={1}
                  more={t("showMore")}
                  less={t("showLess")}
                  className="content-css inline-block"
                  anchorClass="underline text-green"
                  expanded={false}
                  width={800}
                  truncatedEndingComponent={"... "}
                >
                  {props.keywords}
                </ShowMoreText>
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-[20px] items-end ">
            <Switch
              checked={checked}
              handleColor="white"
              name={undefined}
              offColor="black"
              onChange={() => {
                setChecked(!checked);
                changeIsActive();
              }}
              onColor="green"
            />
            <div className="flex flex-row gap-[15px] ">
              <IconButton
                className="bg-black"
                method={() => setDeletedPopup(true)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton edit={true} method={() => setEditModal(!editModal)}>
                <EditIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-[10px] px-[30px] py-[15px] items-center text-green absolute top-0 right-0 bg-[#DEF9E3] w-auto  rounded-bl-[10px] rounded-tr-[10px]">
          <CategoryIcon />
          <p className=" text-sm leading-[18px] font-semibold">
            {props.ruleCategory}
          </p>
        </div>
      </div>
      {deletedPopup && (
        <Popup
          submit={false}
          buttonName={t("delete")}
          method={() => setDeletedPopup(false)}
          methodOK={deleteRule}
          visible={deletedPopup}
          content={"“" + props.title + "” " + t("ruleList.deletePopup")}
        >
          <PopupDelete />
        </Popup>
      )}
      {infoDeletedPopup && (
        <InfoPopup
          visible={infoDeletedPopup}
          close={() => setInfoDeletedPopup(false)}
          content={t("ruleList.deletePopupInfo")}
        />
      )}
      {editModal && (
        <RuleEditModal
          rules={props.rules}
          setRules={props.setRules}
          rule={props.rule}
          setState={setEditModal}
          close={() => setEditModal(false)}
          visible={editModal}
        />
      )}
    </>
  );
}

export default RuleCard;
