import React from "react";
import LogCard from "./LogCard";

const Logs = ({ logs }) => {
  return logs.map((log, key) => (
    <LogCard
      key={key}
      name={log.userMail}
      date={new Date(log.createDate).toLocaleDateString("en-GB")}
      rule={log.ruleName}
      keyword={log.keywords}
    ></LogCard>
  ));
};

export default Logs;
