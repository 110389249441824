import axios from "axios";
import Cookies from 'js-cookie';
const baseDir = process.env.REACT_APP_API_BASE_DIR + "/Keyword";

class KeywordService {
  async AddKeyword(keywordName) {
    const userToken = Cookies.get("userToken");
    /*   const userToken = localStorage.getItem("userToken");*/
    try {
      const res = await axios.post(
        baseDir + "/add",
        {
          name: String(keywordName),
        },
        {
          headers: {
            usertoken: userToken,
          },
        }
      );
      if (res.data.succeeded === false) {
        return { status: false, message: res.data.message };
      } else {
        return { status: true, message: res.data.message, data: res.data.data };
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export default new KeywordService();
