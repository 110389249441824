import React from "react";

function OutlineButton(props) {
  return (
    <button
      onClick={props.method}
      className={
        "h-[40px] w-[230px]  rounded-[5px] shadow border-2 flex flex-row gap-[5px] justify-center items-center " +
        (props.green === true
          ? "border-green text-green  "
          : "border-dark text-dark")
      }
    >
      {props.green === true ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
            stroke="#1E5C2F"
            strokeWidth="1.8"
            strokeMiterlimit="10"
          />
          <path
            d="M9.08398 12H14.9173"
            stroke="#1E5C2F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 9.0835V14.9168"
            stroke="#1E5C2F"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
            stroke="#2A3036"
            strokeWidth="1.8"
            strokeMiterlimit="10"
          />
          <path
            d="M9.08398 12H14.9173"
            stroke="#2A3036"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 9.08301V14.9163"
            stroke="#2A3036"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}

      <p className=" text-sm leading-[14px] font-bold flex-none order-1">
        {props.content}
      </p>
    </button>
  );
}

export default OutlineButton;
