import React from "react";

function CardbyCategory(props) {
  return (
    <div className="flex max-w-[200px] my-2 mx-1 flex-col gap-3 p-[20px] shadow-[0px_2px_12px_rgba(149,_151,_155,_0.5)]  rounded-[10px]">
      <div className="text-dark truncate text-sm leading-[18px] font-semibold">
        {props.title}
      </div>
      <div
        className={
          " text-[32px] font-semibold leading-8 " +
          (props.green ? " text-graphicGreen" : " text-[#F0054B]")
        }
      >
        {props.score}
      </div>
    </div>
  );
}

export default CardbyCategory;
