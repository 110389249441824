import React from "react";
import { useState } from "react";
import IconButton from "../Global/IconButton";
import Switch from "react-ios-switch";
import Popup from "../Global/Popup";
import InfoPopup from "../Global/InfoPopup";
import PopupMessageEdit from "./PopupMessageEdit";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import popupMessageService from "../../Services/popupMessage.service";
import DeleteIcon from "../icons/DeleteIcon";
import EditIcon from "../icons/EditIcon";
import CategoryIcon from "../icons/CategoryIcon";
import PopupDelete from "../icons/PopupDelete";
import { toast } from "react-toastify";
import CustomToast from "../Global/CustomToast";
import LoadingIndicator from "../Global/LoadingIndicator";

function PopupMessageCard(props) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(props.popup.isActive || false);
  const [deletedPopup, setDeletedPopup] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [infoDeletedPopup, setInfoDeletedPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  async function deletePopup() {
    setDeletedPopup(false);
    setIsLoading(true);
    const res = await popupMessageService.UpdatePopupMessage(
      props.popup.name,
      props.popup.categoryId,
      props.popup.message,
      props.popup.isActive,
      props.popup.id,
      1
    );
    setIsLoading(false);
    if (res.status === false) toastErrorFalse();
    else {
      const index = props.popups.indexOf(props.popup);
      props.setPopups([
        ...props.popups.slice(0, index),
        ...props.popups.slice(index + 1),
      ]);
      setInfoDeletedPopup(true);
    }
  }

  function toastError(label) {
    toast.error(label, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  async function changeIsActive() {
    setIsLoading(true);
    const res = await popupMessageService.UpdatePopupMessage(
      props.popup.name,
      props.popup.categoryId,
      props.popup.message,
      !props.popup.isActive,
      props.popup.id,
      0
    );
    if(res.status){
      props.popup.isActive = !props.popup.isActive;
      setChecked(!checked);
    } else {
      toastError(t("errorIsActive"));
    }
    setIsLoading(false);
  }

  return (
    <>
      <div className="relative h-min min-w-[550px] w-full shadow-[0px_2px_12px_rgba(149,_151,_155,_0.2)]  px-[30px] pt-[48px] pb-[30px] rounded-[10px] flex flex-col gap-5  ">
        <CustomToast />
        {isLoading && <LoadingIndicator />}
        <p className=" font-bold text-dark text-[22px] leading-[22px]">
          {props.title}
        </p>
        <div className="flex lg:flex-row flex-col justify-between ">
          <div className="flex max-w-[900px] overflow-y-auto no-scrollbar max-h-[180px]">
            <div className="text-sm leading-[18px] text-dark font-semibold">
              {parse(props.children)}
            </div>
          </div>
          <div className="flex flex-col gap-[20px] items-end justify-end ">
            <Switch
              checked={checked}
              handleColor="white"
              name={undefined}
              offColor="black"
              onChange={() => {
                changeIsActive();
              }}
              onColor="green"
            />
            <div className="flex flex-row gap-[15px] ">
              <IconButton
                className="bg-black"
                method={() => setDeletedPopup(!deletedPopup)}
              >
                <DeleteIcon></DeleteIcon>
              </IconButton>
              <IconButton edit={true} method={() => setEditModal(!editModal)}>
                <EditIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-[10px] px-[30px] py-[15px] items-center text-green absolute top-0 right-0 bg-[#DEF9E3] w-auto  rounded-bl-[10px] rounded-tr-[10px]">
          <CategoryIcon />
          <p className=" text-sm leading-[18px] font-semibold">
            {props.ruleCategory}
          </p>
        </div>{" "}
        {deletedPopup && (
          <Popup
            submit={false}
            buttonName={t("delete")}
            method={() => setDeletedPopup(false)}
            methodOK={deletePopup}
            visible={deletedPopup}
            content={"“" + props.title + "” " + t("popup.deletePopup")}
          >
            <PopupDelete />
          </Popup>
        )}
        {infoDeletedPopup && (
          <InfoPopup
            visible={infoDeletedPopup}
            close={() => setInfoDeletedPopup(false)}
            content={t("popup.deletePopupInfo")}
          />
        )}
        {editModal && (
          <PopupMessageEdit
            popup={props.popup}
            popups={props.popups}
            setPopups={props.setPopups}
            title={t("popup.edit")}
            close={() => setEditModal(false)}
            state={setEditModal}
            visible={editModal}
          />
        )}
      </div>
    </>
  );
}

export default PopupMessageCard;
