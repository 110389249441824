import React, { useEffect, useState } from "react";
import LogCard from "../components/Log/LogCard";
import { useTranslation } from "react-i18next";
import dashboardService from "../Services/dashboard.service";
import IconButton from "../components/Global/IconButton";
import SearchIcon from "../components/icons/SearchIcon";
import SearchSection from "../components/Global/SearchSection";
import Logs from "../components/Log/Logs";
import Pagination from "../components/Log/Pagination";
import LoadingIndicator from "../components/Global/LoadingIndicator";
import { toast } from "react-toastify";
import CustomToast from "../components/Global/CustomToast";

function Log() {
  const { t } = useTranslation();
  const [logs, setLogs] = React.useState([]);
  const [rawLogs, setRawLogs] = React.useState([]);
  const [showSearchSection, setShowSearchSection] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const [filteredResults, setFilteredResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [logPerPage] = useState(15);
  const indexOfLastPost = currentPage * logPerPage;
  const indexOfFirstPost = indexOfLastPost - logPerPage;
  const currentPosts = logs.slice(indexOfFirstPost, indexOfLastPost);
  const [ascending, setAscending] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  const searchItems = (searchValue) => {
    setSearchText(searchValue);

    if (searchText !== "" || searchText.length > 0) {
      const filteredData = rawLogs.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setLogs(filteredData);
    } else {
      setLogs(rawLogs);
    }
  };
  const sortLogsByDate = () => {
    var sorted = logs.slice().sort((a, b) => {
      const dateA = new Date(a.createDate);
      const dateB = new Date(b.createDate);

      if (ascending) {
        setAscending(!ascending);
        return dateA - dateB;
      } else {
        setAscending(!ascending);
        return dateB - dateA;
      }
    });
    setLogs(sorted);
    setRawLogs(sorted);
  };

  useEffect(() => {
    setIsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const fetchData = async () => {
      try {
        const response = await dashboardService.GetAll(signal);
        if (response.status) {
          let sorted = response.data.sort((p1, p2) =>
            p1.createDate < p2.createDate
              ? 1
              : p1.createDate > p2.createDate
              ? -1
              : 0
          );
          setLogs(sorted);
          setRawLogs(sorted);
          setIsLoading(false);
        } else {
          console.log(response.message);
          setIsLoading(false);
        }
      } catch (err) {
        if (err.name === "AbortError" || err.code === "ERR_CANCELED") {
        } else {
          setIsLoading(false);
          toastErrorFalse();
        }
      }
    };
    fetchData();
    return () => controller.abort();
  }, []);

  return (
    <div className="px-10 pt-10 w-full min-w-min h-full">
      <div className="bg-white w-full h-full rounded-t-[10px]">
        <div className="w-full h-[120px] p-10 justify-between flex">
          <p className="text-dark text-[32px] font-bold leading-8">
            {t("log.log")}
          </p>
          <CustomToast />
          <IconButton
            changeColor={showSearchSection}
            method={() => setShowSearchSection(!showSearchSection)}
          >
            <SearchIcon active={showSearchSection} />
          </IconButton>
        </div>
        <SearchSection
          onChange={(e) => searchItems(e.target.value)}
          visible={showSearchSection}
        ></SearchSection>
        {/* <div className="flex flex-col px-10 gap-[10px] text-dark font-semibold text-base leading-4">
                <div className="flex flex-row gap-1">
                    <p>{t("account.date")}</p>
                    <p>20.08.2020 - 20.08.2022</p>
                </div>
                <div className="flex flex-row gap-1">
                    <p>{t("account.userNumbers")}</p>
                    <p>200</p>
                </div>
                </div> */}
        {isLoading === true ? (
          <LoadingIndicator isPage={false} />
        ) : (
          <div className="px-10 pb-10  w-full pt-1">
            <div className="flex flex-col border border-secondaryGray rounded-[10px] min-w-[900px] w-full h-full">
              <div className="text-dark font-bold text-sm leading-[14px] h-[76px] w-full flex flex-row items-center py-8  pl-[34px] ] pr-[36px] border-b border-b-secondaryGray">
                <p className="w-1/4 text-start">{t("username")}</p>
                <button onClick={sortLogsByDate} className="w-1/4 text-center">
                  {t("date")}
                </button>
                <p className="w-1/4 text-center">{t("log.violatedRule")}</p>
                <p className="w-1/4 text-center">{t("log.violatedWords")}</p>
              </div>
              <div className="overflow-y-scroll no-scrollbar w-full flex flex-col">
                {
                  <>
                    <Logs logs={currentPosts} />
                    <Pagination
                      logPerPage={logPerPage}
                      totalLog={logs.length}
                      paginate={paginate}
                    />
                  </>
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Log;
