import React from "react";

function Logout() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="201"
      height="120"
      viewBox="0 0 201 120"
      fill="none"
    >
      <g clipPath="url(#clip0_2037_12236)">
        <path
          opacity="0.5"
          d="M149.672 118.733C147.278 116.707 145.824 113.505 146.205 110.389C146.586 107.272 148.948 104.407 152.012 103.745C155.076 103.083 158.569 104.915 159.5 107.914C160.012 102.133 160.602 96.1099 163.67 91.187C166.448 86.7295 171.259 83.54 176.474 82.9694C181.688 82.3988 187.178 84.5539 190.431 88.677C193.683 92.8001 194.479 98.7943 192.201 103.528C190.523 107.015 187.432 109.61 184.193 111.722C173.727 118.486 161.175 121.237 148.845 119.468L149.672 118.733Z"
          fill="#DEF9E3"
        />
        <path
          d="M185.274 84.6735C180.971 88.1843 177.024 92.1108 173.489 96.3964C167.957 103.08 163.481 110.574 160.216 118.617C159.977 119.204 160.928 119.459 161.164 118.879C166.506 105.808 175.035 94.2869 185.969 85.3702C186.46 84.9696 185.761 84.2762 185.274 84.6735Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M66.6983 118.183C69.9866 115.399 71.984 111.002 71.4602 106.721C70.9364 102.44 67.6921 98.5051 63.4837 97.5961C59.2754 96.6871 54.4782 99.2034 53.2 103.322C52.4965 95.3818 51.6859 87.1093 47.4721 80.3477C43.6566 74.2253 37.0481 69.8445 29.886 69.0608C22.7239 68.2771 15.1831 71.2371 10.7163 76.9002C6.24957 82.5633 5.15591 90.7963 8.2843 97.298C10.5889 102.088 14.8347 105.652 19.2833 108.552C33.4759 117.803 51.0817 121.662 67.834 119.192"
          fill="#DEF9E3"
        />
        <path
          d="M17.7981 71.4014C23.7078 76.2235 29.1292 81.6167 33.9841 87.5028C38.8398 93.383 43.1147 99.7211 46.7487 106.428C48.78 110.19 50.6047 114.061 52.2148 118.023C52.5431 118.829 51.2368 119.179 50.9122 118.382C48.0604 111.398 44.5321 104.711 40.3781 98.4158C36.2442 92.1438 31.5127 86.2883 26.2502 80.9316C23.2736 77.9033 20.1332 75.0412 16.8429 72.3583C16.1685 71.8082 17.1293 70.8558 17.7981 71.4014L17.7981 71.4014Z"
          fill="white"
        />
        <path
          d="M140.731 119.755H62.2773V39.1148C62.2773 21.958 76.2104 8 93.3365 8H109.672C126.798 8 140.731 21.958 140.731 39.1148V119.755Z"
          fill="#F2F2F2"
        />
        <path
          d="M124.396 119.755H62.2774V39.1149C62.2603 32.5408 64.3383 26.1329 68.2089 20.8241C68.3516 20.6293 68.4939 20.437 68.6407 20.2468C70.5129 17.7869 72.7409 15.6205 75.2511 13.8191C75.3929 13.7158 75.5352 13.6142 75.6797 13.5126C77.7802 12.0565 80.0523 10.8661 82.4444 9.96857C82.5865 9.91463 82.7311 9.86059 82.8757 9.80875C85.0392 9.03532 87.2818 8.50551 89.5622 8.22913C89.7036 8.20978 89.8488 8.19464 89.9939 8.1795C92.2162 7.94147 94.4575 7.94147 96.6798 8.1795C96.8245 8.19464 96.9697 8.20978 97.1127 8.22934C99.3926 8.50574 101.635 9.03548 103.798 9.80876C103.942 9.8606 104.087 9.91464 104.229 9.96868C106.592 10.8549 108.837 12.0275 110.915 13.4604C111.06 13.5597 111.204 13.6613 111.347 13.7629C112.754 14.769 114.075 15.89 115.298 17.1142C116.279 18.0962 117.192 19.143 118.033 20.2474C118.179 20.437 118.322 20.6293 118.464 20.8238C122.335 26.1327 124.413 32.5407 124.396 39.1149V119.755Z"
          fill="#CCCCCC"
        />
        <path
          d="M119.345 83.6873C121.245 83.6873 122.784 82.1449 122.784 80.2421C122.784 78.3394 121.245 76.7969 119.345 76.7969C117.446 76.7969 115.906 78.3394 115.906 80.2421C115.906 82.1449 117.446 83.6873 119.345 83.6873Z"
          fill="#2A3036"
        />
        <path
          d="M134.305 117.34H131.67L130.416 107.157L134.305 107.157L134.305 117.34Z"
          fill="#FFB8B8"
        />
        <path
          d="M129.788 116.585H134.87V119.791H126.588C126.588 119.37 126.671 118.953 126.831 118.564C126.992 118.175 127.228 117.822 127.525 117.524C127.822 117.226 128.175 116.99 128.563 116.829C128.951 116.668 129.368 116.585 129.788 116.585Z"
          fill="#2F2E41"
        />
        <path
          d="M147.416 117.34H144.781L143.527 107.157L147.417 107.157L147.416 117.34Z"
          fill="#FFB8B8"
        />
        <path
          d="M142.899 116.585H147.981V119.791H139.699C139.699 119.37 139.782 118.953 139.943 118.564C140.104 118.175 140.339 117.822 140.636 117.524C140.934 117.226 141.286 116.99 141.675 116.829C142.063 116.668 142.479 116.585 142.899 116.585Z"
          fill="#2F2E41"
        />
        <path
          d="M121.086 80.9247C120.896 80.6448 120.77 80.3268 120.717 79.9929C120.663 79.659 120.683 79.3174 120.776 78.9922C120.868 78.6669 121.031 78.366 121.252 78.1104C121.473 77.8549 121.747 77.651 122.056 77.5132L131.013 52.7793L135.421 55.1891L125.193 78.8257C125.39 79.3599 125.384 79.9478 125.177 80.478C124.97 81.0083 124.577 81.4441 124.07 81.7028C123.564 81.9615 122.981 82.0252 122.431 81.8818C121.881 81.7384 121.402 81.3978 121.086 80.9247Z"
          fill="#FFB8B8"
        />
        <path
          d="M146.071 85.5722C145.778 85.404 145.525 85.1735 145.331 84.897C145.137 84.6204 145.005 84.3046 144.946 83.9717C144.887 83.6388 144.901 83.2969 144.988 82.9701C145.075 82.6433 145.232 82.3396 145.449 82.0803L142.674 55.916L147.69 56.1456L148.843 81.8816C149.254 82.2752 149.506 82.8061 149.552 83.3736C149.598 83.941 149.435 84.5057 149.093 84.9606C148.752 85.4156 148.255 85.7291 147.698 85.8418C147.141 85.9545 146.562 85.8586 146.071 85.5722Z"
          fill="#FFB8B8"
        />
        <path
          d="M138.068 48.1979C140.984 48.1979 143.347 45.8301 143.347 42.9093C143.347 39.9884 140.984 37.6206 138.068 37.6206C135.153 37.6206 132.789 39.9884 132.789 42.9093C132.789 45.8301 135.153 48.1979 138.068 48.1979Z"
          fill="#FFB8B8"
        />
        <path
          d="M146.037 79.6217H130.121L130.14 79.4976C130.169 79.3122 132.977 60.885 130.906 54.8564C130.698 54.264 130.716 53.6155 130.957 53.0358C131.198 52.456 131.644 51.986 132.21 51.7162H132.21C135.171 50.3197 140.853 48.6002 145.649 52.7733C146.354 53.3975 146.905 54.1776 147.258 55.052C147.611 55.9263 147.756 56.8709 147.682 57.8111L146.037 79.6217Z"
          fill="#F8A085"
        />
        <path
          d="M133.177 63.6082L126.012 62.1519L129.37 54.1784C129.571 53.4061 130.07 52.7452 130.757 52.3407C131.444 51.9361 132.262 51.821 133.034 52.0205C133.805 52.22 134.466 52.7178 134.872 53.4048C135.277 54.0918 135.394 54.9119 135.197 55.6851L133.177 63.6082Z"
          fill="#F8A085"
        />
        <path
          d="M142.355 66.3122L141.924 56.4198C141.598 54.5602 142.66 52.8022 144.294 52.5149C145.929 52.2286 147.525 53.5183 147.854 55.3916L149.473 64.6351L142.355 66.3122Z"
          fill="#F8A085"
        />
        <path
          d="M145.507 78.4375C148.068 88.2083 148.348 100.631 147.657 114.182L144.218 113.751L137.984 87.9119L134.545 114.182L130.676 113.966C129.52 99.7485 128.395 87.5426 130.246 79.5141L145.507 78.4375Z"
          fill="#2A3036"
        />
        <path
          d="M141.545 46.9968C140.56 48.0478 138.731 47.4836 138.603 46.0478C138.593 45.9364 138.594 45.8242 138.605 45.7129C138.671 45.0769 139.038 44.4995 138.95 43.828C138.93 43.6609 138.868 43.5016 138.77 43.3653C137.985 42.3125 136.143 43.8362 135.402 42.8831C134.948 42.2987 135.482 41.3786 135.133 40.7254C134.673 39.8633 133.311 40.2886 132.456 39.8165C131.506 39.2912 131.563 37.83 132.188 36.9413C132.951 35.8574 134.29 35.279 135.611 35.1957C136.932 35.1123 138.245 35.4702 139.478 35.9518C140.88 36.499 142.27 37.2553 143.132 38.49C144.181 39.9915 144.282 42.0101 143.758 43.7659C143.439 44.8339 142.349 46.1386 141.545 46.9968Z"
          fill="#2F2E41"
        />
        <path
          d="M187.048 119.894H28.3567C28.289 119.893 28.2241 119.866 28.1763 119.818C28.1284 119.77 28.1016 119.705 28.1016 119.637C28.1016 119.569 28.1284 119.504 28.1763 119.456C28.2241 119.408 28.289 119.381 28.3567 119.381H187.048C187.115 119.381 187.18 119.408 187.228 119.456C187.276 119.504 187.303 119.569 187.303 119.637C187.303 119.705 187.276 119.77 187.228 119.818C187.18 119.866 187.115 119.893 187.048 119.894Z"
          fill="#3F3D56"
        />
        <path
          d="M107.308 96.3916H79.3659C78.9979 96.3936 78.6441 96.2493 78.3822 95.9903C78.1203 95.7314 77.9716 95.3789 77.9688 95.0103V32.5289C77.9716 32.1602 78.1203 31.8077 78.3822 31.5488C78.6441 31.2898 78.9979 31.1455 79.3659 31.1475H107.308C107.676 31.1455 108.03 31.2898 108.292 31.5488C108.554 31.8077 108.703 32.1602 108.706 32.5289V95.0103C108.703 95.3789 108.554 95.7314 108.292 95.9903C108.03 96.2493 107.676 96.3936 107.308 96.3916Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M107.308 96.3921H79.3659C78.9979 96.3941 78.6441 96.2498 78.3822 95.9908C78.1203 95.7319 77.9716 95.3794 77.9688 95.0107V32.5294C77.9716 32.1607 78.1203 31.8082 78.3822 31.5493C78.6441 31.2903 78.9979 31.146 79.3659 31.148H107.308C107.676 31.146 108.03 31.2903 108.292 31.5493C108.554 31.8082 108.703 32.1607 108.706 32.5294V95.0107C108.703 95.3794 108.554 95.7319 108.292 95.9908C108.03 96.2498 107.676 96.3941 107.308 96.3921Z"
          fill="#DEF9E3"
        />
        <path
          d="M108.706 51.3647H77.9688V51.7954H108.706V51.3647Z"
          fill="#1E5C2F"
        />
        <path
          d="M108.813 75.9355H78.0762V76.3662H108.813V75.9355Z"
          fill="#1E5C2F"
        />
        <path
          d="M88.0705 31.1475H87.6406V96.6069H88.0705V31.1475Z"
          fill="#1E5C2F"
        />
        <path
          d="M98.8186 31.1475H98.3887V96.6069H98.8186V31.1475Z"
          fill="#1E5C2F"
        />
      </g>
      <defs>
        <clipPath id="clip0_2037_12236">
          <rect
            width="187"
            height="112"
            fill="white"
            transform="translate(6.5 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logout;
