import { useEffect, useState } from "react";
import AddIcon from "../icons/AddIcon";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import InfoPopup from "../Global/InfoPopup";
import userGroupService from "../../Services/userGroup.service";

const UserGroupMultipleDropdown = (props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(props.selected || {});
  const [showmore, setShowMore] = useState(false);
  const [syncstatus, setSyncstatus] = useState("");
  const [domainId, setDomainId] = useState(0);
  const [options, setOptions] = useState(props.options || []);

  const handleChange = (event) => {
    const value = event.value;

    setSelected(value);
    if (props.array.some((m) => m.id === value.id) === false) {
      props.array.push(value);
    }
  };
  useEffect(() => {
    let userGroupList = [];
    let groups = [];

    userGroupService
      .UserGroupGetAll()
      .then((response) => {
        if (response.status) {
          userGroupList = response.data;
        } else {
          console.log(response.message);
        }
      })
      .then(() => {
        userGroupService
          .GetDomainDetailByTenant()
          .then((response) => {
            if (response.status) {
              setOptions(response.data[0].group);
              groups = response.data[0].group;
              setDomainId(response.data[0].id);
            } else {
              console.log(response.message);
            }
          })
          .then(() => {
            userGroupList.forEach((ug) => {
              for (let index = 0; index < ug.group.length; index++) {
                ug.group[index] = groups.filter(
                  (op) => op.id === ug.group[index]
                )[0];
              }
            });
            setOptions(userGroupList);
          });
      });
  }, []);

  async function syncDomain() {
    setSyncstatus("syncing");
    const res = await userGroupService.SyncDomainGroup(domainId);
    if (!res.status) {
      console.log(res.message);
      setSyncstatus("fail");
    } else {
      userGroupService.GetDomainDetailByTenant().then((response) => {
        if (response.status) {
          setOptions(response.data[0].group);
          setDomainId(response.data[0].id);
          setSyncstatus("success");
        } else {
          console.log(response);
          setSyncstatus("fail");
        }
      });
    }
  }
  return (
    <>
      <div className="flex flex-col gap-[15px]">
        <div className="flex flex-row justify-between">
          <label
            htmlFor={props.for}
            className="text-base font-bold leading-4 text-dark"
          >
            {props.title}
          </label>
          <div
            onClick={() => {
              syncDomain();
            }}
            className="flex"
          >
            <span className="mr-2">{syncstatus}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={25}
            >
              <path d="M12,2C6.477,2,2,6.477,2,12c0,5.523,4.477,10,10,10s10-4.477,10-10C22,6.477,17.523,2,12,2z M12,18 c-1.655,0-3.138-0.69-4.223-1.777L6,18v-5h5l-1.814,1.814C9.907,15.541,10.896,16,12,16c1.474,0,2.75-0.81,3.444-2h2.206 C16.823,16.327,14.606,18,12,18z M18,11h-5l1.814-1.814C14.093,8.459,13.104,8,12,8c-1.474,0-2.75,0.81-3.444,2H6.35 C7.176,7.673,9.394,6,12,6c1.655,0,3.138,0.69,4.223,1.777L18,6V11z" />
            </svg>
          </div>
        </div>

        {/*      <Select
          showSearch
          onChange={handleChange}
          placeholder={t("pleaseChoose")}
        >
          {props.options.map((option, key) => (
            <Select.Option key={key} value={JSON.stringify(option)}>
              {option.displayName}
            </Select.Option>
          ))}
        </Select> */}
        <Select
          isSearchable={true}
          onChange={handleChange}
          placeholder={t("pleaseChoose")}
          options={props.options.map((option) => ({
            value: option, // veya option'in belirli bir özelliği
            label: option.displayName, // veya option'in görüntülenmesini istediğiniz özelliği
          }))}
        ></Select>

        <div
          className={
            "grid content-center gap-x-5 gap-y-[15px] w-full " +
            (props.userGroup === true ? " grid-cols-3" : " grid-cols-4")
          }
        >
          {props.array.slice(0, 9).map((item, key) => (
            <div
              className={
                "flex text-dark  text-sm items-center p-[10.5px] leading-[18px] font-semibold h-[30px] rounded-[3px] w-[200px] text-center" +
                (props.secondaryGreen === true
                  ? "  underline bg-secondaryGreen"
                  : " bg-secondaryGray")
              }
              key={key}
            >
              <p className="w-full truncate">
                {props.userGroup === true ? item.displayName : item.name}
              </p>
              <div
                onClick={() => {
                  const index = props.array.indexOf(item);

                  props.setarray([
                    ...props.array.slice(0, index),
                    ...props.array.slice(index + 1),
                  ]);
                }}
                className="flex items-center pl-2 h-full justify-end cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                >
                  <path
                    d="M6.05794 5.00014L9.28294 1.78264C9.42417 1.64141 9.50351 1.44987 9.50351 1.25014C9.50351 1.05041 9.42417 0.858867 9.28294 0.717639C9.14171 0.576411 8.95017 0.49707 8.75044 0.49707C8.55072 0.49707 8.35917 0.576411 8.21794 0.717639L5.00044 3.94264L1.78294 0.717639C1.64171 0.576411 1.45017 0.49707 1.25044 0.49707C1.05072 0.49707 0.85917 0.576411 0.717942 0.717639C0.576715 0.858867 0.497374 1.05041 0.497374 1.25014C0.497374 1.44987 0.576715 1.64141 0.717942 1.78264L3.94294 5.00014L0.717942 8.21764C0.647646 8.28736 0.591851 8.37031 0.553774 8.46171C0.515698 8.5531 0.496094 8.65113 0.496094 8.75014C0.496094 8.84915 0.515698 8.94718 0.553774 9.03857C0.591851 9.12997 0.647646 9.21292 0.717942 9.28264C0.787665 9.35293 0.870615 9.40873 0.96201 9.44681C1.0534 9.48489 1.15143 9.50449 1.25044 9.50449C1.34945 9.50449 1.44748 9.48489 1.53888 9.44681C1.63027 9.40873 1.71322 9.35293 1.78294 9.28264L5.00044 6.05764L8.21794 9.28264C8.28767 9.35293 8.37062 9.40873 8.46201 9.44681C8.5534 9.48489 8.65143 9.50449 8.75044 9.50449C8.84945 9.50449 8.94748 9.48489 9.03888 9.44681C9.13027 9.40873 9.21322 9.35293 9.28294 9.28264C9.35324 9.21292 9.40903 9.12997 9.44711 9.03857C9.48519 8.94718 9.50479 8.84915 9.50479 8.75014C9.50479 8.65113 9.48519 8.5531 9.44711 8.46171C9.40903 8.37031 9.35324 8.28736 9.28294 8.21764L6.05794 5.00014Z"
                    fill="#2A3036"
                  />
                </svg>
              </div>
            </div>
          ))}
          {props.array.length > 9 ? (
            <div
              onClick={() => setShowMore(true)}
              className=" cursor-pointer flex text-white bg-green text-sm items-center p-[10.5px] leading-[18px] font-semibold h-[30px] rounded-[3px] w-[200px] text-center"
            >
              <p className="w-full truncate">{t("showMore")}</p>
            </div>
          ) : null}
        </div>
      </div>
      <InfoPopup
        visible={showmore}
        more={true}
        moreTitle={t("userGroups.member")}
        close={() => setShowMore(false)}
      >
        {props.array.map((item, key) => (
          <div
            className={
              "flex text-dark  text-sm items-center p-[10.5px] leading-[18px] font-semibold h-[30px] rounded-[3px] w-[206px] text-center" +
              (props.secondaryGreen === true
                ? "  underline bg-secondaryGreen"
                : " bg-secondaryGray")
            }
            key={key}
          >
            <p className="w-full truncate">
              {props.userGroup === true ? item.displayName : item.name}
            </p>
            <div
              onClick={() => {
                const index = props.array.indexOf(item);

                props.setarray([
                  ...props.array.slice(0, index),
                  ...props.array.slice(index + 1),
                ]);
              }}
              className="flex items-center pl-2 h-full justify-end cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  d="M6.05794 5.00014L9.28294 1.78264C9.42417 1.64141 9.50351 1.44987 9.50351 1.25014C9.50351 1.05041 9.42417 0.858867 9.28294 0.717639C9.14171 0.576411 8.95017 0.49707 8.75044 0.49707C8.55072 0.49707 8.35917 0.576411 8.21794 0.717639L5.00044 3.94264L1.78294 0.717639C1.64171 0.576411 1.45017 0.49707 1.25044 0.49707C1.05072 0.49707 0.85917 0.576411 0.717942 0.717639C0.576715 0.858867 0.497374 1.05041 0.497374 1.25014C0.497374 1.44987 0.576715 1.64141 0.717942 1.78264L3.94294 5.00014L0.717942 8.21764C0.647646 8.28736 0.591851 8.37031 0.553774 8.46171C0.515698 8.5531 0.496094 8.65113 0.496094 8.75014C0.496094 8.84915 0.515698 8.94718 0.553774 9.03857C0.591851 9.12997 0.647646 9.21292 0.717942 9.28264C0.787665 9.35293 0.870615 9.40873 0.96201 9.44681C1.0534 9.48489 1.15143 9.50449 1.25044 9.50449C1.34945 9.50449 1.44748 9.48489 1.53888 9.44681C1.63027 9.40873 1.71322 9.35293 1.78294 9.28264L5.00044 6.05764L8.21794 9.28264C8.28767 9.35293 8.37062 9.40873 8.46201 9.44681C8.5534 9.48489 8.65143 9.50449 8.75044 9.50449C8.84945 9.50449 8.94748 9.48489 9.03888 9.44681C9.13027 9.40873 9.21322 9.35293 9.28294 9.28264C9.35324 9.21292 9.40903 9.12997 9.44711 9.03857C9.48519 8.94718 9.50479 8.84915 9.50479 8.75014C9.50479 8.65113 9.48519 8.5531 9.44711 8.46171C9.40903 8.37031 9.35324 8.28736 9.28294 8.21764L6.05794 5.00014Z"
                  fill="#2A3036"
                />
              </svg>
            </div>
          </div>
        ))}
      </InfoPopup>
    </>
  );
};
export default UserGroupMultipleDropdown;
