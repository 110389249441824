import React from "react";
import Input from "../Global/Input";
import Button from "../Global/Button";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ruleCategoryService from "../../Services/ruleCategory.service";
import CustomToast from "../Global/CustomToast";
import { toast } from "react-toastify";
import InfoPopup from "../Global/InfoPopup";
import FilterCheckbox from "../RuleList/Modal/FilterCheckbox";
import LoadingIndicator from "../Global/LoadingIndicator";

function RuleCategoryEdit(props) {
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const [createdInfo, setCreatedInfo] = useState(false);
  const [ruleCategoryName, setRuleCategoryName] = useState(
    props.ruleCategory.name || ""
  );
  const [ruleDescription, setRuleDescription] = useState(
    props.ruleCategory.description || ""
  );
  const [isLogging, setIsLogging] = useState(
    props.ruleCategory.isLogActive || false
  );
  const [severityScore, setSeverityScore] = useState(
    props.ruleCategory.severityScore || 3
  );

  function toastError(label) {
    toast.error(label, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const handleSubmitUpdateCategory = (event) => {
    event.preventDefault();
    updateCategory();
  };

  const createdPopupMessage = () => {
    setCreatedInfo(false);
    props.setState(false);
  };

  async function updateCategory() {
    setIsUpdating(true);
    const res = await ruleCategoryService.UpdateRuleCategory(
      ruleCategoryName,
      ruleDescription,
      props.ruleCategory.isActive,
      isLogging,
      severityScore,
      props.ruleCategory.id,
      0
    );
    setIsUpdating(false);
    if (!res.status) toastError(t("errorInfo"));
    else {
      const arrayupdated = props.categories.map((item) =>
        item.id === props.ruleCategory.id ? { ...item, ...res.data } : item
      );
      props.setCategories(arrayupdated);
      setCreatedInfo(true);
    }
  }

  if (props.visible === false) return null;
  return (
    <>
      {" "}
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full bg-dark opacity-70"></div>
        <div className="flex items-center min-h-screen px-4 py-8">
          <CustomToast />
          {isUpdating && <LoadingIndicator />}
          <form
            className="z-10 flex flex-col w-full max-w-[815px]   mx-auto justify-center gap-[20px] items-center min-h-min min-w-min bg-white rounded-[10px] px-[80px] py-[40px]"
            onSubmit={handleSubmitUpdateCategory}
          >
            <div className="flex flex-col gap-7 w-full">
              <Input
                input={t("sidebar.ruleCategory")}
                value={ruleCategoryName}
                onChange={(e) => setRuleCategoryName(e.target.value)}
                for="ruleCategory"
              ></Input>
              <div className="flex flex-col gap-[10px]">
                <label
                  htmlFor="ruleDescription"
                  className=" text-base font-bold leading-4 text-dark"
                >
                  {t("ruleCategory.description")}
                </label>
                <label className="relative block">
                  <textarea
                    type="text"
                    id="ruleDescription"
                    value={ruleDescription}
                    onChange={(e) => setRuleDescription(e.target.value)}
                    className=" border min-h-[50px] border-darkGray text-dark rounded-[5px] text-sm leading-[18px] font-semibold height-[50px] w-full px-4 py-[15px] "
                    required
                  />
                </label>
              </div>
              <FilterCheckbox
                checked={isLogging}
                name={t("ruleList.logging")}
                onChange={() => setIsLogging(!isLogging)}
              />
              <div className="flex flex-col gap-[10px] w-full ">
                <div className="flex flex-row gap-3">
                  {" "}
                  <label className=" text-base font-bold leading-4 text-dark">
                    {t("ruleList.categorySeverityScore")}
                  </label>
                  <div className="group relative">
                    <svg
                      className="fill-current h-5 w-5 text-dark mr-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                    <span className="absolute w-[250px] bottom-0 left-6 scale-0 transition-all rounded bg-gray-800 bg-opacity-90 p-2 text-xs text-white group-hover:scale-100">
                      {t("ruleList.categoryScoreDesc")}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col space-y-2 p-2 w-80">
                  <input
                    className="accent-green"
                    type="range"
                    value={severityScore}
                    min="1"
                    max="5"
                    step="1"
                    onChange={(e) => {
                      setSeverityScore(e.target.value);
                    }}
                  />
                  <div className="flex justify-between w-full px-[10px]">
                    <li className="flex justify-center ">1</li>
                    <li className="flex justify-center ">2</li>
                    <li className="flex justify-center ">3</li>
                    <li className="flex justify-center ">4</li>
                    <li className="flex justify-center ">5</li>
                  </div>
                  <div className="flex justify-between w-full text-xs font-semibold text-darkGray">
                    <div className="w-1/5 flex justify-start">
                      {t("ruleList.tooLow")}
                    </div>
                    <div className="w-1/5 flex justify-center">
                      {t("ruleList.low")}
                    </div>
                    <div className="w-1/5 flex justify-center">
                      {t("ruleList.medium")}
                    </div>
                    <div className="w-1/5 flex justify-center">
                      {t("ruleList.high")}
                    </div>
                    <div className="w-1/5 flex justify-end">
                      {t("ruleList.tooHigh")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-[15px]">
              <Button method={props.close} outline={true} name={t("cancel")} />
              <Button green={true} submit={true} name={t("saveChanges")} />
            </div>
          </form>
        </div>
      </div>{" "}
      {createdInfo && (
        <InfoPopup
          visible={createdInfo}
          close={createdPopupMessage}
          content={"“" + ruleCategoryName + "” " + t("teams.editInfo")}
        />
      )}
    </>
  );
}

export default RuleCategoryEdit;
