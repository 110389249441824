import React, { useEffect } from "react";
import { useState } from "react";
import IconButton from "../Global/IconButton";
import Popup from "../Global/Popup";
import Input from "../Global/Input";
import InfoPopup from "../Global/InfoPopup";
import { useTranslation } from "react-i18next";
import userGroupService from "../../Services/userGroup.service";
import UserGroupMultipleDropdown from "../UserGroup/UserGroupMultipleDropdown";
import EditIcon from "../icons/EditIcon";
import DeleteIcon from "../icons/DeleteIcon";
import PopupDelete from "../icons/PopupDelete";
import { toast } from "react-toastify";
import CustomToast from "../Global/CustomToast";
import ShowMoreText from "react-show-more-text2";
import LoadingIndicator from "../Global/LoadingIndicator";
import UserGroupEdit from "./UserGroupEdit";

function UserGroupCard(props) {
  const { t } = useTranslation();
  const [deletedPopup, setDeletedPopup] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [infoEditedPopup, setInfoEditedPopup] = useState(false);
  const [infoDeletedPopup, setInfoDeletedPopup] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const r = (members) => {
    let l = [];
    JSON.parse(members).forEach((member) => {
      l.push(member.displayName + " - ");
    });
    return l;
  };

  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  async function deleteUserGroup() {
    setDeletedPopup(false);
    setIsUpdating(true);
    const res = await userGroupService.UpdateGroup(
      props.userGroup.id,
      props.userGroup.name,
      props.userGroup.group.map((g) => g.id),
      props.userGroup?.excludedEmails,
      parseInt(1)
    );
    setIsUpdating(false);
    if (!res.status) toastErrorFalse();
    else {
      const index = props.userGroups.indexOf(props.userGroup);
      props.setUserGroups([
        ...props.userGroups.slice(0, index),
        ...props.userGroups.slice(index + 1),
      ]);
      /*   sessionStorage.setItem(
        "UserGroups",
        JSON.stringify([
          ...props.userGroups.slice(0, index),
          ...props.userGroups.slice(index),
        ])
      ); */
      const arrayupdated = props.userGroups.filter(
        (item) => item.id !== props.userGroup.id
      );
      props.setUserGroups(arrayupdated);
    }
  }

  function toastError(label) {
    toast.error(label + t("toast"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  return (
    <>
      <div className="relative h-min min-w-[550px] w-full shadow-[0px_2px_12px_rgba(149,_151,_155,_0.2)] p-[30px] rounded-[10px] flex flex-col gap-5 ">
        <CustomToast /> {isUpdating && <LoadingIndicator />}
        <p className=" font-bold text-dark text-[22px] leading-[22px]">
          {props.userGroup.name}
        </p>
        <div className="flex lg:flex-row flex-col justify-between">
          <div className="font-bold text-green text-sm leading-[14px]">
            {t("userGroups.member") + ": "}
            <span className="text-sm leading-[18px] text-dark font-semibold">
              <ShowMoreText
                /* Default options */
                lines={1}
                more={t("showMore")}
                less={t("showLess")}
                className="content-css inline-block"
                anchorClass="underline text-green"
                expanded={false}
                width={800}
                truncatedEndingComponent={"... "}
              >
                {props.members.map((g) => g.displayName + ", ")}
              </ShowMoreText>{" "}
            </span>
          </div>

          <div className="flex flex-row gap-[15px] justify-end ">
            <IconButton
              className="bg-black"
              method={() => setDeletedPopup(!deletedPopup)}
            >
              <DeleteIcon></DeleteIcon>
            </IconButton>
            <IconButton
              edit={true}
              method={() => {
                setEditModal(!editModal);
              }}
            >
              <EditIcon></EditIcon>
            </IconButton>
          </div>
        </div>
      </div>
      {deletedPopup && (
        <Popup
          submit={false}
          buttonName={t("delete")}
          method={() => setDeletedPopup(false)}
          methodOK={deleteUserGroup}
          visible={deletedPopup}
          content={
            <div>
              {props.userGroup.name + " " + t("userGroups.deletePopup")}
            </div>
          }
        >
          <PopupDelete />
        </Popup>
      )}
      {infoDeletedPopup && (
        <InfoPopup
          visible={infoDeletedPopup}
          close={() => setInfoDeletedPopup(false)}
          content={t("userGroups.deletePopupInfo")}
        />
      )}
      {infoEditedPopup && (
        <InfoPopup
          visible={infoEditedPopup}
          close={() => setInfoEditedPopup(false)}
          content={props.userGroup.name + " " + t("userGroups.editInfo")}
        />
      )}
      {editModal && (
        <UserGroupEdit
          visible={editModal}
          setEditModal={setEditModal}
          close={() => setEditModal(false)}
          infoEditedPopup={infoEditedPopup}
          setInfoEditedPopup={setInfoEditedPopup}
          userGroup={props.userGroup}
          userGroups={props.userGroups}
          setUserGroups={props.setUserGroups}
        />
      )}
    </>
  );
}

export default UserGroupCard;
