import React from "react";

function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.33398 7.33301V10.6663M2.66732 3.99967H13.334L12.2807 13.4797C12.2445 13.8059 12.0894 14.1074 11.8448 14.3263C11.6003 14.5453 11.2836 14.6664 10.9553 14.6663H5.04598C4.71774 14.6664 4.40103 14.5453 4.15648 14.3263C3.91194 14.1074 3.75676 13.8059 3.72065 13.4797L2.66732 3.99967ZM4.89732 2.09767C5.00515 1.86899 5.17579 1.67567 5.38932 1.54028C5.60285 1.40489 5.85048 1.333 6.10332 1.33301H9.89798C10.1509 1.33288 10.3987 1.40471 10.6124 1.5401C10.826 1.6755 10.9968 1.86888 11.1047 2.09767L12.0007 3.99967H4.00065L4.89732 2.09767V2.09767ZM1.33398 3.99967H14.6673H1.33398ZM6.66732 7.33301V10.6663V7.33301Z"
        stroke="#2A3036"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeleteIcon;
