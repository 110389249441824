import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import Input from "./Input";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import ruleCategoryService from "../../Services/ruleCategory.service";
import DecoupledDocumentEditor from "@ckeditor/ckeditor5-build-decoupled-document";

function MessageForm(props) {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    ruleCategoryService.RuleCategoryGetAll().then((response) => {
      if (response.status) {
        setCategories(response.data);
      } else {
      }
    });
  }, []);
  return (
    <>
      <div className="flex flex-col px-[40px] gap-[40px]">
        <Input
          input={props.input}
          for={props.inputFor}
          value={props.value}
          onChange={props.onChange}
        />
        <div className="flex flex-col gap-[10px]">
          <label
            htmlFor="ruleCategory"
            className="text-base font-bold leading-4 text-dark"
          >
            {t("sidebar.ruleCategory")}
          </label>
          <select
            required
            id="ruleCategory"
            className=" border rounded-[5px] h-[50px] border-darkGray text-dark text-sm leading-[18px] block w-full px-[15px] "
            value={props.ruleCategory}
            onChange={props.ruleCategoryOnChange}
          >
            <option value="" disabled selected hidden>
              {t("pleaseChoose")}
            </option>
            {categories.map((value, key) => (
              <option key={key} value={value.id}>
                {value.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col gap-[10px]">
          <label className="text-base font-bold leading-4 text-dark">
            {t("popup.content")}
          </label>
          <div style={{ border: "1px solid #c4c4c4", borderRadius: "4px" }}>
            <CKEditor
              editor={DecoupledDocumentEditor}
              data={props.data} //if edit popupmessage get data
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "400px",
                    editor.editing.view.document.getRoot()
                  );
                }, editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement()));
              }}
              onChange={(event, editor) => {
                props.setData(editor.getData());
              }}
              config={{
                toolbar: {
                  items: [
                    "fontfamily",
                    "fontsize",
                    "fontColor",
                    "fontBackgroundColor",
                    "|",
                    "bold",
                    "italic",
                    "underline",
                    "|",
                    "alignment",
                    "link",
                    "blockQuote",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "outdent",
                    "indent",
                    "insertTable",
                    "|",
                    "undo",
                    "redo",
                  ],
                },
              }}
            />
          </div>
          {}
        </div>
      </div>
    </>
  );
}
export default MessageForm;
