import React from "react";
import ReactApexChart from "react-apexcharts";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { useTranslation } from "react-i18next";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

function LineChart(data) {
  const { t } = useTranslation();
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
          },
          mode: "x",
        },
      },
    },
  };

  return (
    <div className=" w-full h-full">
      <div className="h-auto w-full ">
        {data.data == null ? (
          <h1 className=" text-md text-center font-semibold text-red">
            {t("error.noData")}
          </h1>
        ) : (
          <Line height={300} options={options} data={data.data} />
        )}
      </div>
    </div>
  );
}

export default LineChart;
