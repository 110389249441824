import React, { useEffect, useState } from "react";
import Popup from "../Global/Popup";
import Input from "../Global/Input";
import UserGroupMultipleDropdown from "./UserGroupMultipleDropdown";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import userGroupService from "../../Services/userGroup.service";
import LoadingIndicator from "../Global/LoadingIndicator";
import AddIcon from "../icons/AddIcon";
import MultipleDropdown from "../RuleList/MultipleDropdown";

function UserGroupEdit(props) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [array1, setarray1] = useState([...props.userGroup.group] || []);

  const [excludedEmail, setExcludedEmail] = useState("");

  const [excludedEmails, setExcludedEmails] = useState(
    [...props.userGroup.excludedEmails.map((email) => ({ name: email }))] || []
  );

  const addToExcludedEmails = () => {
    if (
      excludedEmail &&
      !excludedEmails.some((emailObj) => emailObj.name === excludedEmail)
    ) {
      let excludedObject = { name: excludedEmail };
      excludedEmails.push(excludedObject);

      setExcludedEmail(""); // Clear input after adding
    }
  };

  /*   const removeFromExcludedEmails = (item) => {
    setExcludedEmails(excludedEmails.filter((email) => email !== item));
  };
 */
  const [userGroupName, setUserGroupName] = useState(
    props.userGroup.name || ""
  );
  const [options, setOptions] = useState([]);

  function toastErrorFalse() {
    toast.error(t("errorInfo"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  function toastError(label) {
    toast.error(label + t("toast"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  const { t } = useTranslation();
  const handleSubmitUpdateUserGroup = (event) => {
    event.preventDefault();
    if (array1.length === 0 || array1 === null) {
      toastError(t("userGroups.memberAdd"));
    } else {
      updateUserGroup();
    }
  };

  useEffect(() => {
    userGroupService.GetDomainDetailByTenant().then((response) => {
      if (response.status) {
        setOptions(response.data[0].group);
      } else {
        console.log(response.message);
      }
    });
  }, []);
  async function updateUserGroup() {
    const excludedEmailsArray = excludedEmails.map((item) => item.name);
    setIsUpdating(true);
    const res = await userGroupService.UpdateGroup(
      props.userGroup.id,
      userGroupName,
      array1.map((g) => g.id),
      excludedEmailsArray,
      parseInt(0)
    );
    setIsUpdating(false);
    if (res.status === false) toastErrorFalse();
    else {
      const arrayupdated = props.userGroups.map((item) =>
        item.id === props.userGroup.id
          ? { ...item, ...res.data, group: array1 }
          : item
      );
      props.setUserGroups(arrayupdated);
      setExcludedEmail("");
      props.setEditModal(false);
      props.setInfoEditedPopup(true);
    }
  }

  if (props.visible === false) return null;
  return (
    <>
      <Popup
        visible={props.visible}
        method={() => {
          props.setEditModal(false);
        }}
        methodOK={handleSubmitUpdateUserGroup}
        buttonName={t("saveChanges")}
      >
        {isUpdating && <LoadingIndicator />}
        <div className="flex flex-col gap-7 w-full">
          <Input
            input={t("userGroups.groupName")}
            value={userGroupName}
            onChange={(e) => setUserGroupName(e.target.value)}
            for="userGroupName"
          />
          <UserGroupMultipleDropdown
            title={t("userGroups.memberAdd")}
            for="groupMembers"
            options={options}
            array={array1}
            userGroup={true}
            setarray={setarray1}
          />

          <MultipleDropdown
            ruleKeyName={excludedEmail}
            setRuleKeyName={setExcludedEmail}
            addKeyword={addToExcludedEmails}
            placeholder={t("userGroups.excludePlaceholder")}
            title={t("userGroups.exclude")}
            moreTitle={t("userGroups.exclude")}
            keyword={true}
            for="excludeEmails"
            array={excludedEmails}
            setarray={setExcludedEmails}
            add={true}
            exclude={true}
          />
          {/*           <div className="flex flex-col gap-[10px] w-full">
            <label
              htmlFor="ruleKeys"
              className=" text-base font-bold leading-4 text-dark"
            >
              {t("ruleList.keys")}
            </label>
            <label className="relative block">
              <input
                type="text"
                id="ruleKeys"
                className=" border min-h-[50px] border-darkGray text-dark rounded-[5px] text-sm leading-[18px] font-semibold height-[50px] w-full px-4 py-[15px] "
                autoComplete="on"
                value={excludedEmail}
                onChange={(e) => excludedEmail(e.target.value)}
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                <div onClick={addToExcludedEmails}>
                  <AddIcon />
                </div>
              </span>
            </label>
          </div> */}

          {/*  <div className="grid content-center gap-x-5 gap-y-[15px] w-full grid-cols-3">
            {excludedEmails.map((email, index) => (
              <div
                key={index}
                className="flex text-dark text-sm items-center p-[10.5px] leading-[18px] font-semibold h-[30px] rounded-[3px] w-[200px] text-center bg-secondaryGray"
              >
                <p className="w-full truncate">{email}</p>
                <div
                  onClick={() => removeFromExcludedEmails(email)}
                  className="flex items-center pl-2 h-full justify-end cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <path
                      d="M6.05794 5.00014L9.28294 1.78264C9.42417 1.64141 9.50351 1.44987 9.50351 1.25014C9.50351 1.05041 9.42417 0.858867 9.28294 0.717639C9.14171 0.576411 8.95017 0.49707 8.75044 0.49707C8.55072 0.49707 8.35917 0.576411 8.21794 0.717639L5.00044 3.94264L1.78294 0.717639C1.64171 0.576411 1.45017 0.49707 1.25044 0.49707C1.05072 0.49707 0.85917 0.576411 0.717942 0.717639C0.576715 0.858867 0.497374 1.05041 0.497374 1.25014C0.497374 1.44987 0.576715 1.64141 0.717942 1.78264L3.94294 5.00014L0.717942 8.21764C0.647646 8.28736 0.591851 8.37031 0.553774 8.46171C0.515698 8.5531 0.496094 8.65113 0.496094 8.75014C0.496094 8.84915 0.515698 8.94718 0.553774 9.03857C0.591851 9.12997 0.647646 9.21292 0.717942 9.28264C0.787665 9.35293 0.870615 9.40873 0.96201 9.44681C1.0534 9.48489 1.15143 9.50449 1.25044 9.50449C1.34945 9.50449 1.44748 9.48489 1.53888 9.44681C1.63027 9.40873 1.71322 9.35293 1.78294 9.28264L5.00044 6.05764L8.21794 9.28264C8.28767 9.35293 8.37062 9.40873 8.46201 9.44681C8.5534 9.48489 8.65143 9.50449 8.75044 9.50449C8.84945 9.50449 8.94748 9.48489 9.03888 9.44681C9.13027 9.40873 9.21322 9.35293 9.28294 9.28264C9.35324 9.21292 9.40903 9.12997 9.44711 9.03857C9.48519 8.94718 9.50479 8.84915 9.50479 8.75014C9.50479 8.65113 9.48519 8.5531 9.44711 8.46171C9.40903 8.37031 9.35324 8.28736 9.28294 8.21764L6.05794 5.00014Z"
                      fill="#2A3036"
                    />
                  </svg>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </Popup>
    </>
  );
}

export default UserGroupEdit;
