import React from "react";

function TeamsIcon({ active }) {
  const data = active ? filled : outline;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      {data}
    </svg>
  );
}

const outline = (
  <g>
    <path
      d="M19.9206 11.9702C22.1463 11.9702 23.9506 10.1659 23.9506 7.94016C23.9506 5.71445 22.1463 3.91016 19.9206 3.91016C17.6949 3.91016 15.8906 5.71445 15.8906 7.94016C15.8906 10.1659 17.6949 11.9702 19.9206 11.9702Z"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.22 15.9996H16.43C16.2048 15.044 15.6637 14.1923 14.8941 13.5825C14.1246 12.9727 13.1718 12.6405 12.19 12.6396H6.37C5.211 12.6396 4.09948 13.1001 3.27994 13.9196C2.46041 14.7391 2 15.8507 2 17.0096C2 18.1686 2.46041 19.2802 3.27994 20.0997C4.09948 20.9192 5.211 21.3796 6.37 21.3796H9.84C9.84 19.9528 10.4068 18.5844 11.4158 17.5754C12.4247 16.5665 13.7931 15.9996 15.22 15.9996V15.9996Z"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.27916 9.72963C9.85753 9.73161 10.4235 9.56178 10.9052 9.24169C11.3869 8.9216 11.7627 8.46566 11.985 7.9317C12.2072 7.39773 12.2659 6.80979 12.1535 6.24243C12.0412 5.67508 11.7629 5.15387 11.3539 4.7449C10.9449 4.33593 10.4237 4.05762 9.85636 3.94527C9.289 3.83292 8.70106 3.89159 8.1671 4.11384C7.63313 4.33608 7.17719 4.71189 6.8571 5.19362C6.53701 5.67534 6.36718 6.24126 6.36916 6.81963C6.36916 7.59141 6.67575 8.33158 7.22148 8.87731C7.76721 9.42304 8.50738 9.72963 9.27916 9.72963V9.72963Z"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.6198 16H15.2198C12.2486 16 9.83984 18.4087 9.83984 21.38V22.72C9.83984 25.6913 12.2486 28.1 15.2198 28.1H24.6198C27.5911 28.1 29.9998 25.6913 29.9998 22.72V21.38C29.9998 18.4087 27.5911 16 24.6198 16Z"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);

const filled = (
  <g>
    <path
      d="M19.9206 11.9702C22.1463 11.9702 23.9506 10.1659 23.9506 7.94016C23.9506 5.71445 22.1463 3.91016 19.9206 3.91016C17.6949 3.91016 15.8906 5.71445 15.8906 7.94016C15.8906 10.1659 17.6949 11.9702 19.9206 11.9702Z"
      fill="#2A3036"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.22 15.9996H16.43C16.2048 15.044 15.6637 14.1923 14.8941 13.5825C14.1246 12.9727 13.1718 12.6405 12.19 12.6396H6.37C5.211 12.6396 4.09948 13.1001 3.27994 13.9196C2.46041 14.7391 2 15.8507 2 17.0096C2 18.1686 2.46041 19.2802 3.27994 20.0997C4.09948 20.9192 5.211 21.3796 6.37 21.3796H9.84C9.84 19.9528 10.4068 18.5844 11.4158 17.5754C12.4247 16.5665 13.7931 15.9996 15.22 15.9996Z"
      fill="#2A3036"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.27916 9.72963C9.85753 9.73161 10.4235 9.56178 10.9052 9.24169C11.3869 8.9216 11.7627 8.46566 11.985 7.9317C12.2072 7.39773 12.2659 6.80979 12.1535 6.24243C12.0412 5.67508 11.7629 5.15387 11.3539 4.7449C10.9449 4.33593 10.4237 4.05762 9.85636 3.94527C9.289 3.83292 8.70106 3.89159 8.1671 4.11384C7.63313 4.33608 7.17719 4.71189 6.8571 5.19362C6.53701 5.67534 6.36718 6.24126 6.36916 6.81963C6.36916 7.59141 6.67575 8.33158 7.22148 8.87731C7.76721 9.42304 8.50738 9.72963 9.27916 9.72963Z"
      fill="#2A3036"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.6198 16H15.2198C12.2486 16 9.83984 18.4087 9.83984 21.38V22.72C9.83984 25.6913 12.2486 28.1 15.2198 28.1H24.6198C27.5911 28.1 29.9998 25.6913 29.9998 22.72V21.38C29.9998 18.4087 27.5911 16 24.6198 16Z"
      fill="#2A3036"
      stroke="#2A3036"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);

export default TeamsIcon;
