import React from "react";
import AccountInfoCard from "../components/AccountInfo/AccountInfoCard";
import NewUserInfo from "../components/AccountInfo/NewUserInfo";
import OutlineButton from "../components/Global/OutlineButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
function AccountInfo() {
  const { t } = useTranslation();
  const [newUser, setNewUser] = useState(false);
  return (
    <div className="px-10 pt-10 w-full min-w-min h-full">
      <div className="bg-white w-full h-full rounded-t-[10px]">
        <div className="w-full h-[120px] p-10  ">
          <p className="text-dark text-[32px] font-bold leading-8">
            {t("account.title")}
          </p>
        </div>
        <div className="flex flex-col px-10 gap-[10px] text-dark font-semibold text-base leading-4">
          <div className="flex flex-row gap-1">
            <p>{t("account.date")}</p>
            <p>20.08.2020 - 20.08.2022</p>
          </div>
          <div className="flex flex-row gap-1">
            <p>{t("account.userNumbers")}</p>
            <p>200</p>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center mt-[49px] px-10 w-full">
          <p className="text-dark text-[22px] leading-[22px] font-bold">
            {t("account.allUsers")}
          </p>
          <OutlineButton
            method={() => {
              setNewUser(true);
            }}
            green={true}
            content={t("account.addUser")}
          ></OutlineButton>
        </div>
        <div className="h-screen px-10 mt-[29px] pb-10  w-full pt-1">
          <div className="flex flex-col border border-secondaryGray rounded-[10px] min-w-[900px] w-full h-full">
            <div className="text-dark font-bold text-sm leading-[14px] h-[76px] w-full flex flex-row items-center   pl-[34px] ] pr-[216px] border-b border-b-secondaryGray">
              <p className="w-1/4 text-start">{t("account.customerName")}</p>
              <p className="w-1/4 text-center">{t("password")}</p>
              <p className="w-1/4 text-center">{t("email")}</p>
              <p className="w-1/4 text-center">{t("phone")}</p>
            </div>
            <div className="overflow-y-scroll no-scrollbar w-full flex flex-col">
              <AccountInfoCard
                name="Beril Kıpırtı"
                password="*******"
                email="deneme@gmail.com"
                phoneNumber="+095555555555"
              ></AccountInfoCard>
              <AccountInfoCard
                name="Beril Kıpırtı"
                password="*******"
                email="deneme@gmail.com"
                phoneNumber="+095555555555"
              ></AccountInfoCard>
              <AccountInfoCard
                name="Beril Kıpırtı"
                password="*******"
                email="deneme@gmail.com"
                phoneNumber="+095555555555"
              ></AccountInfoCard>
              <AccountInfoCard
                name="Beril Kıpırtı"
                password="*******"
                email="deneme@gmail.com"
                phoneNumber="+095555555555"
              ></AccountInfoCard>
              <AccountInfoCard
                name="Beril Kıpırtı"
                password="*******"
                email="deneme@gmail.com"
                phoneNumber="+095555555555"
              ></AccountInfoCard>
              <AccountInfoCard
                name="Beril Kıpırtı"
                password="*******"
                email="deneme@gmail.com"
                phoneNumber="+095555555555"
              ></AccountInfoCard>
              <AccountInfoCard
                name="Beril Kıpırtı"
                password="*******"
                email="deneme@gmail.com"
                phoneNumber="+095555555555"
              ></AccountInfoCard>
              <AccountInfoCard
                name="Beril Kıpırtı"
                password="*******"
                email="deneme@gmail.com"
                phoneNumber="+095555555555"
              ></AccountInfoCard>
              <AccountInfoCard
                name="Beril Kıpırtı"
                password="*******"
                email="deneme@gmail.com"
                phoneNumber="+095555555555"
              ></AccountInfoCard>
              <AccountInfoCard
                name="Beril Kıpırtı"
                password="*******"
                email="deneme@gmail.com"
                phoneNumber="+095555555555"
              ></AccountInfoCard>
              <AccountInfoCard
                name="Beril Kıpırtı"
                password="*******"
                email="deneme@gmail.com"
                phoneNumber="+095555555555"
              ></AccountInfoCard>
              <AccountInfoCard
                name="Beril Kıpırtı"
                password="*******"
                email="deneme@gmail.com"
                phoneNumber="+095555555555"
              ></AccountInfoCard>
              <AccountInfoCard
                name="Beril Kıpırtı"
                password="*******"
                email="deneme@gmail.com"
                phoneNumber="+095555555555"
              ></AccountInfoCard>
            </div>
          </div>
        </div>
      </div>
      <NewUserInfo
        visible={newUser}
        method={() => {
          setNewUser(false);
        }}
        methodOK={() => {
          setNewUser(false);
        }}
      ></NewUserInfo>
    </div>
  );
}

export default AccountInfo;
