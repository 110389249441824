import React from "react";
import homeRule from "../images/homeRule.svg";
import homePopup from "../images/homePopup.svg";
import homeTeams from "../images/homeTeams.svg";
import Card from "../components/Home/Card";
import { useTranslation } from "react-i18next";
function Home() {
  const { t } = useTranslation();
  return (
    <div className="p-10 grid grid-rows-3 grid-cols-1 xl:grid-cols-2 xl:grid-rows-3 gap-5 w-full max-w-[1600px] h-full">
      <div className="xl:col-span-2 xl:row-span-1">
        <Card
          href="/rule-list/add"
          title={t("home.ruleCreate")}
          content={t("home.ruleInfo")}
        >
          <img
            src={homeRule}
            className="w-[266px] h-[206px]"
            alt="ruleCreate"
          />
        </Card>
      </div>
      <div className="xl:row-start-2 xl:row-span-2">
        <Card
          href="/popup-message-list/add"
          title={t("home.popupCreate")}
          img={homePopup}
          content={t("home.popupInfo")}
        >
          <img
            className="w-[318px] h-[186px]"
            src={homePopup}
            alt="popupCreate"
          />
        </Card>
      </div>
      <div className="xl:row-start-2 xl:row-span-2">
        <Card
          href="/teams-contents/add"
          title={t("home.teamsCreate")}
          content={t("home.teamsInfo")}
        >
          <img
            className="w-[174px] h-[219px]"
            src={homeTeams}
            alt="teamsCreate"
          />
        </Card>
      </div>
    </div>
  );
}

export default Home;
